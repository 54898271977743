export const subjectOptions = [
    { id: 1, value: 'Не работает корпоративный сайт' },
    { id: 2, value: 'Не заходит на российский сайт' },
    { id: 3, value: 'Нужно продлить домен' },
    { id: 4, value: 'Нужно продлить хостинг' },
    { id: 5, value: 'Обновить информацию на сайте' },
    { id: 6, value: 'Не заходит на сайт' },
    { id: 7, value: 'Не работают некоторые сайты' },
    { id: 8, value: 'Проблемы с сайтами' },
    { id: 9, value: 'Не открывается сайт' },
    { id: 10, value: 'Не загружается сайт' },
    { id: 11, value: 'Не работает телефония у всех' },
    { id: 12, value: 'Не работают телефоны' },
    { id: 13, value: 'Установить и подключить телефон' },
    { id: 14, value: 'Настроить телефонию' },
    { id: 15, value: 'Не проходит звонок' },
    { id: 16, value: 'Не работает телефон' },
    { id: 17, value: 'Проблемы с телефонами' },
    { id: 18, value: 'Не слышно абонента' },
    { id: 19, value: 'Не доходит звонок зарубеж' },
    { id: 20, value: 'При наборе номера не проходит звонок' },
    { id: 21, value: 'Подключить телефон новому сотруднику' },
    { id: 22, value: 'Мне не доходят звонки' },
    { id: 23, value: 'Добавить в очередь звонков сотрудника' },
    { id: 24, value: 'Перенастроить маршрутизацию звонков' },
    { id: 25, value: 'Прошу предоставить КП (коммерческое предложение) на новый ноутбук' },
    { id: 26, value: 'Прошу предоставить КП (коммерческое предложение) на новый моноблок' },
    { id: 27, value: 'Прошу предоставить КП (коммерческое предложение) на новый системный блок' },
    { id: 28, value: 'Прошу предоставить КП (коммерческое предложение) на новый принтер' },
    { id: 29, value: 'Прошу предоставить КП (коммерческое предложение) на новый принтер и сканер' },
    { id: 30, value: 'Прошу предоставить АКТ сверки' },
    { id: 31, value: 'Прошу предоставить счет' },
    { id: 32, value: 'Прошу предоставить КП на антивирус' },
    { id: 33, value: 'Прошу предоставить на камеры видеонаблюдения' },
    { id: 34, value: 'Прошу предоставить КП на почтовый сервис' },
    { id: 35, value: 'Прошу предоставить КП на клавиатуру' },
    { id: 36, value: 'Прошу предоставить КП на мышь' },
    { id: 37, value: 'Не работают камеры' },
    { id: 38, value: 'Настроить просмотр камер (видеонаблюдения) на телефоне' },
    { id: 39, value: 'Не работает сканер штрихкодов' },
    { id: 40, value: 'Не работает ТСД' },
    { id: 41, value: 'Не работает мышь' },
    { id: 42, value: 'Не работает клавиатура' },
    { id: 43, value: 'Не работают наушники' },
    { id: 44, value: 'Не работает камера на ноутбуке' },
    { id: 45, value: 'Не печатает принтер этикеток' },
    { id: 46, value: 'Не могу распечатать чеки' },
    { id: 47, value: 'Заправить картриджи' },
    { id: 48, value: 'Плохо печатает принтер' },
    { id: 49, value: 'Бледно печатает принтер' },
    { id: 50, value: 'Принтер бледно печатает' },
    { id: 51, value: 'Заправка картриджей' },
    { id: 52, value: 'Принтер не захватывает бумагу' },
    { id: 53, value: 'Сканер сканирует с полосами' },
    { id: 54, value: 'Не печатает принтер' },
    { id: 55, value: 'Не могу зайти в 1С' },
    { id: 56, value: 'Не заходит в 1С' },
    { id: 57, value: 'Не работает 1С у всех' },
    { id: 58, value: 'Настроить новый ключ ЭЦП в 1С' },
    { id: 59, value: 'Настроить обмен с ИС ЭСФ' },
    { id: 60, value: 'Мы перевыпустили ключи ЭЦП, нам нужно настроить их в 1С' },
    { id: 61, value: 'Не могу отправить ЭСФ' },
    { id: 62, value: 'У нас сменился первый руководитель, нам нужно перенастроить ключи в 1С' },
    { id: 63, value: 'Заблокировать пользователя на сервер' },
    { id: 64, value: 'Создать новую учетную запись для доступа к серверу' },
    { id: 65, value: 'Настроить VPN' },
    { id: 66, value: 'Настроить доступ к серверу для работы вне офиса' },
    { id: 67, value: 'У нас сменился первый руководитель, нам нужно перенастроить ключи в ИС ЭСФ' },
    { id: 68, value: 'Обновить СОНО и формы налоговой отчетности' },
    { id: 69, value: 'Установить СОНО и формы налоговой отчетности' },
    { id: 70, value: 'Не заходит в кабинет налогоплательщика' },
    { id: 71, value: 'Не заходит в StatGov' },
    { id: 72, value: 'Обновить NCALayer' },
    { id: 73, value: 'Установить NCALayer' },
    { id: 74, value: 'Создать почту новому сотруднику' },
    { id: 75, value: 'Не могу зайти в почту' },
    { id: 76, value: 'Не открывается Outlook' },
    { id: 77, value: 'Не могу отправить письмо' },
    { id: 78, value: 'Не отправляются письма' },
    { id: 79, value: 'Письма не доходят до клиента' },
    { id: 80, value: 'Пришло странное письмо' },
    { id: 81, value: 'Мне нужен пароль от моей почты' },
    { id: 82, value: 'Создать новую группу рассылок' },
    { id: 83, value: 'Проблема с почтой' },
    { id: 84, value: 'Не могу зайти в общую папку' },
    { id: 85, value: 'Настроить общую папку новому сотруднику' },
    { id: 86, value: 'Закрыть доступы к общей папке для сотрудника' },
    { id: 87, value: 'Нет доступа к определнной папке на сервере' },
    { id: 88, value: 'Тормозит ПК' },
    { id: 89, value: 'Тормозит ноутбук' },
    { id: 90, value: 'Тормозит Office' },
    { id: 91, value: 'Переустановить ОС (операционную систему)' },
    { id: 92, value: 'Забрать новый ноутбук и установить операционную систему' },
    { id: 93, value: 'Установить Asista' },
    { id: 94, value: 'Установить антивирус' },
    { id: 95, value: 'Настроить новое рабочее место сотруднику' },
    { id: 96, value: 'Не работает ZOOM' },
    { id: 97, value: 'Не работает офис (Microsoft Office)' },
    { id: 98, value: 'Windows просит активацию' },
    { id: 99, value: 'Офис (Microsoft Office) просит активацию' },
    { id: 100, value: 'Установить 3Dmax' },
    { id: 101, value: 'Установить Autocad' },
    { id: 102, value: 'Установить Photoshop' },
    { id: 103, value: 'Установить приложение' },
    { id: 104, value: 'Проблема при работе в Microsoft Office' },
    { id: 105, value: 'Забыли пароль от компьютера / ноутбука' },
    { id: 106, value: 'Зависает Microsoft Office' },
    { id: 107, value: 'Установить редактор PDF' },
    { id: 108, value: 'Проблема с ПО' },
    { id: 109, value: 'Настроить на телефоне приложение' },
    { id: 111, value: 'Не сканирует' },
    { id: 112, value: 'Подключить принтер' },
    { id: 113, value: 'Не могу распечатать с двух сторон' },
    { id: 114, value: 'Некорректно распечатываются документы' },
    { id: 115, value: 'Не работает интернет у отдела' },
    { id: 116, value: 'Не работает интернет у коллеги' },
    { id: 117, value: 'Не работает Wi-Fi' },
    { id: 118, value: 'Подскажите пароль от нашего Wi-Fi' },
    { id: 119, value: 'Не работает интернет' },
]