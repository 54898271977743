import './AttachedFile.css'
import AddedFileIcon from "../../../../../assets/icons/AddedFile.svg"

export function convertFileSize(sizeInKB) {
    const units = ['B','KB', 'MB', 'GB', 'TB'];
    let size = sizeInKB;
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }
    size = Math.round(size * 100) / 100;
    return `${size} ${units[unitIndex]}`;
}

export function AttachedFile (props) {
    
    return (
        <>
            <a download className='AttachedFile' href={props.url}>
                <div className="AttachedFile__content_name">{props.name}</div>
                <div className="FileBlock__size">{props.extension} {convertFileSize(props.size)}</div>
            </a>
        </>
    );
}