import React from 'react';
import './StatisticBlock.css';

const StatisticBlock = (props) => {
  return (
    <div className='StatisticBlock__container'>
      <span className='StatisticBlock__Value notranslate'>{props.value}</span>
      <div className='StatisticBlock__Labels__Container'>
        <p className='StatisticBlock__Label'>{props.label}</p>
      </div>
    </div>
  );
};

export default StatisticBlock;
