import React, {useState, useEffect} from 'react';
import './BIRegions.css';
import {changeRegion, setActivePage} from "../../../../Store/ApplicationsReducer/applicationsActions";
import {useDispatch, useSelector} from "react-redux";
import TurquoiseButton from '../../../SearchResultsComponents/UI/TurquoiseButton/TurquoiseButton';
import { getRegionFilters, setBiActiveFilters } from '../../../../Store/BIReducer/biActions';

const BIRegions = (props) => {
    const dispatch = useDispatch();
    const biRegions = useSelector(state=>state.bi.regionfilters)
    const [regionFilter, setRegionFilter] = useState("");
    const biActiveFilters = useSelector(state=>state.bi.activeFilters)
    const [inputState, setInputState] = useState({});

    const statusActiveFilters = useSelector(state => state.applications.data.filter.region);
    useEffect(() => {
        let obj = {}
        for(let i = 0; i < biRegions?.length; i ++){
            for(let j = 0; j < biActiveFilters?.region_uuid?.length; j++){
                if(biActiveFilters?.region_uuid[j]===biRegions[i].uuid){
                    obj[biRegions[i].name_rus]=biRegions[i].uuid
                }
            }
                
        }
        setInputState(obj);
    }, [biRegions, biActiveFilters]);
    
    const showDepartmentSearchResults = (event) => {
        event.preventDefault();
        let arr = [];
        Object.keys(inputState).forEach(el => {
            if (inputState[el]) {
                arr.push(inputState[el]);
            }
            dispatch(setBiActiveFilters({...biActiveFilters, region_uuid: arr}))
            props.close();
        });
    }

    const showFiltered = (event) => {
        setRegionFilter(event.target.value);
    }

    const inputChange = (event, uuid) => {
        const name = event.target.name;
        if (!event.target.checked) {
            setInputState(prevState => {
                return {...prevState, [name]: ""}
            });
        } else {
            setInputState(prevState => {
                return {...prevState, [name]: uuid}
            });
        }
    }
    let allRegions = null;
    if (biRegions.length) {
        let selectedRegions = biRegions?.filter(el => el.name_rus.toLowerCase().includes(regionFilter.toLowerCase()))
        allRegions = selectedRegions.map((el, i) => {
            return (
                <label key={i} className="BIRegions__label">
                    <input checked={inputState[el.name_rus]} name={el.name_rus} className="BIRegions__input"
                           onChange={(event) => {
                               inputChange(event, el.uuid)
                           }} type="checkbox"/>
                    <div className="BIRegions__label--icon"/>
                    <p className="BIRegions__department">{el.name_rus}</p>
                </label>
            )
        });
    }

    return (
        <>
            <div onClick={props.close} className="BIRegions__bg"/>
            <div className="BIRegions">
                <div className="BIRegions__header">
                    <p className="BIRegions__header--text">Показывать только:</p>
                </div>
                <div className="BIRegions__content">
                    <input className="BIRegions--search" value={regionFilter} type="search"
                           placeholder="Поиск по филиалам" onChange={(e) => showFiltered(e)}/>
                    <form className="BIRegions__form" onSubmit={(event) => {
                        showDepartmentSearchResults(event)
                    }}>
                        {allRegions}
                        <TurquoiseButton
                            name="Применить фильтры"
                        />
                    </form>
                </div>
            </div>
        </>
    )
}


export default BIRegions;
