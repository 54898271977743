import { Collapse } from 'antd';
import "./PatchNotes.css"
import { useEffect, useState } from 'react';
import { initialPatchNotes } from './patchNotes_items';
import { useDispatch } from 'react-redux';
import { setPatchesRead } from '../../Store/GeneralReducer/generalActions';


const PatchNoteHeader = (props) => {
    const patchStatus = {
        read: 'PatchNoteHeader__read',
        unread: 'PatchNoteHeader__unread'
    }
    return (
        <div className={`PatchNoteHeader ${patchStatus[props.status]}`}>
            <div className='PatchNoteHeader__date'>
                {props.date}
            </div>
            <div className='PatchNoteHeader__title'>
                {props.title}
            </div>
        </div>
    )
}
const PatchNoteBody = (props) => {
    const patchStatus = {
        read: 'PatchNoteBody__read',
        unread: 'PatchNoteBody__unread'
    }
    const createMarkup = (htmlString) => {
        return { __html: htmlString };
    };
    return (
        <div 
            className={`PatchNoteBody ${patchStatus[props.status]}`}
            dangerouslySetInnerHTML={createMarkup(props.htmlContent)}
        />
    );
}
export default function PatchNotes ()  {
    const dispatch = useDispatch()
    const [patchNotes, setPatchNotes] = useState(initialPatchNotes);
    const [openedPatchNotes, setOpenedPatchNotes] = useState([]);
    const [readCount, setReadCount] = useState(0);
    const markAllAsRead = () => {
        const updatedNotes = patchNotes.map(note => ({ ...note, status: 'read' }));
        setPatchNotes(updatedNotes);

        const statusToSave = {};
        updatedNotes.forEach(note => {
            statusToSave[note.key] = 'read';
        });
        localStorage.setItem('patchNotesStatus', JSON.stringify(statusToSave));
        localStorage.setItem('patchNotesReadCount', JSON.stringify(updatedNotes.length));
        dispatch(setPatchesRead(updatedNotes.length))
    };
    const handleNoteClick = (key) => {
        setPatchNotes(prevNotes => {
            const updatedNotes = prevNotes.map(note => ({
                ...note,
                status: note.key === key ? 'read' : note.status
            }));
            
            // Save updated status to localStorage
            const statusToSave = {};
            let newReadCount = 0;
            updatedNotes.forEach(note => {
                statusToSave[note.key] = note.status;
                if (note.status === 'read') newReadCount++;
            });
            localStorage.setItem('patchNotesStatus', JSON.stringify(statusToSave));
            setReadCount(newReadCount);
            localStorage.setItem('patchNotesReadCount', JSON.stringify(newReadCount));
            dispatch(setPatchesRead(newReadCount))
            return updatedNotes;
        });
    };
    useEffect(() => {
        const savedStatus = JSON.parse(localStorage.getItem('patchNotesStatus')) || {};
        const savedReadCount = JSON.parse(localStorage.getItem('patchNotesReadCount')) || 0;

        setPatchNotes(prevNotes => prevNotes.map(note => ({
            ...note,
            status: savedStatus[note.key] || 'unread'
        })));
        setReadCount(savedReadCount);
        dispatch(setPatchesRead(savedReadCount))
    }, []);

    useEffect(() => {
        for(let i = 0; i< openedPatchNotes.length; i++){
            handleNoteClick(openedPatchNotes[i])
        }
    }, [openedPatchNotes]);

    
    const items = patchNotes.map(note => ({
        key: note.key,
        label: <PatchNoteHeader status={note.status} title={note.title} date={note.date} />,
        children: <PatchNoteBody status={note.status} htmlContent={note.htmlContent} />,
        showArrow: false,
    }));

    
    return (
        <div className='PatchNotes__wrapper'>
            <button className='PatchNote__readall' onClick={markAllAsRead}>Прочитать все</button>
            <div className='PatchNotes'>
                <Collapse onChange={e => setOpenedPatchNotes(e)} rootClassName='PatchNote__collapse' bordered={false} style={{padding: 0}} ghost items={items} />
            </div>
            
            
        </div>
    )
}