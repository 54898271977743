import {createRef} from "react";
import FAQHeader from "../FaqHeader/FAQHeader";
import FaqBody from "./FaqBody";
import './FAQBodyWrapper.css';

const FAQBodyWrapper = () => {
    const trackbarRef = createRef();

    const positionTrackHandler = (event) => {
        const {scrollTop} = event.target;
        let newScroll = scrollTop / 25;
        // trackbarRef.current.style.top = newScroll + '%';
    }

    return (
        <div className='FAQ-body_block'>
            {/* <div className='body_inner' onScroll={positionTrackHandler}> */}
            <div className='body_inner'>
                <div className='FAQ-header_block'>
                    <FAQHeader/>
                </div>
                <div>
                    <FaqBody/>
                </div>
            </div>
            {/* <div className='trackbar'>
                <div className='trackbar-thumb' ref={trackbarRef}/>
            </div> */}
        </div>
    )
}

export default FAQBodyWrapper;