import React from "react";
import { useState } from "react";
import { LogoSidebar } from "../../../constants/UI/LogoSidebar";
import LayoutFAQ from "../../FAQPageComponents/LayoutFAQ/LayoutFAQ";

export function FaqSidebar() {
  const [show, setShow] = useState(false);
  const [hideClass, setHideClass] = useState("");
  const faqShowHandler = () => {
    setHideClass("FAQ-container_inner show");
    setShow(true);
  };

  const faqHideHandler = () => {
    setHideClass("FAQ-container_inner");
    setTimeout(() => {
      setShow(false);
    }, 250);
  };
  return (
    <>
      <div className="sidebar-faq">
        <LogoSidebar />
        <div className="faq-logo" onClick={faqShowHandler}>
          <div className="faq-icon">{'?>'}</div>
          <div className="faq">FAQ</div>
        </div>
      </div>
      {show ? (
        <>
          <div className="FAQ-container" onClick={faqHideHandler} />
          <div>
            <LayoutFAQ
              class={hideClass}
              show={setShow}
              hideClass={setHideClass}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default FaqSidebar;
