export const SET_CHAT_ID = "SET_CHAT_ID";
export const SET_CHAT_INFO = "SET_CHAT_INFO";
export const SET_CHAT_USER_INFO = "SET_CHAT_USER_INFO";
export const SET_CHAT_MESSAGES = "SET_CHAT_MESSAGES"
export const SET_CHAT_FILES = "SET_CHAT_FILES"
export const SET_CHAT_SELECTED_PHOTO = "SET_CHAT_SELECTED_PHOTO"
export const SET_TEMP_MESSAGE = "SET_TEMP_MESSAGE"
export const SET_APPLICATION_EXISTS = "SET_APPLICATION_EXISTS"
export const SET_CHAT_APPLICATION_TIME = "SET_CHAT_APPLICATION_TIME"
export const SET_NOTIFICATIONS_WORKING = "SET_NOTIFICATIONS_WORKING"
