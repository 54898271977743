import React from "react";
import LayoutUndefinedID from "../../Components/UndefinedIDComponents/LayoutUndefinedID/LayoutUndefinedID";
import './UndefinedIDPage.css'
import { FooterInfo } from "../../constants/Contact/FooterInfo/FooterInfo";
import { ITSGContact } from "../../constants/Contact/ITSGContact/ITSGContact";
import FaqSidebar from "../../Components/ApplicationPageComponents/FaqSidebar/FaqSidebar";
import Elipsis from '../../assets/icons/icon-ellipse.svg';

const UndefinedIDPage = () =>{
    const center = (
        <div className="UndefinedIDAlert">
            <div className="Red_undefined">ID недействителен или неверен</div>
            <div className="AlertContainer">
                <img className="AlertIcon" src={Elipsis} />
                <div className="AlertInfo">
                    <h3 className="AlertInfo_title">Упс, мы не смогли определить ваш ID</h3>
                    <p className="AlertInfo_text">
                        Скорее всего ваша копия ASISTA еще не настроена,<br/>
                        <a href="https://wa.me/77473901112">Оставьте заявку в чат-бот Whatsapp</a><br/>
                        или позвоните на <span href="+77073901112">+7 707 390 11 12</span>, и<br/>
                        мы что-нибудь придумаем
                    </p>
                </div>
            </div>
        </div>
    )

    let bottom = (
        <>
            <FooterInfo/>
            <ITSGContact/>
        </>
    )
    return(
        <div className="UndefinedID"> 
            <FaqSidebar />
            <LayoutUndefinedID
                style = {{"boxSizing": "border-box"}}
                center = {center}
                bottom = {bottom}
            />
        </div>
    )
}

export default UndefinedIDPage;