import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBiActiveFilters } from "../../../../Store/BIReducer/biActions";
import "./BIFilters.css"
import { changeNumber } from "../../../../Store/ApplicationsReducer/applicationsActions";
const BIFilters = (props) => {
    const biActiveFilters = useSelector(state=>state.bi.activeFilters)
    const dispatch = useDispatch()
    const company = useSelector(state=>state.company)
    return(
        <div className="BIFilters">
            <div className="BIFilters__title">Фильтры: </div>
            {
                biActiveFilters?.employee_uuid? <div className="BIFilters__filtername">Сотрудник</div>:null
            }
            {    
                biActiveFilters?.region_uuid? <div className="BIFilters__filtername">Филиал</div>:null
            }
            {
                biActiveFilters?.department_uuid? <div className="BIFilters__filtername">Отдел</div>:null
            } 
            
            {
                biActiveFilters?
                    <div className="BIFilters__deleteFilters" onClick={()=>{
                        dispatch(setBiActiveFilters({}));
                        dispatch(changeNumber(""))
                    }    
                    }>Сбросить все фильтры</div>
                :null
            }
        </div>
    )
} 

export default BIFilters