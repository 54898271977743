import StatisticBlock from '../StatisticBlock/StatisticBlock';
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  CartesianGrid,
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import ReactDOMServer from 'react-dom/server';
import { ReactComponent as Like } from '../../../assets/icons/like-active.svg';
import { ReactComponent as Dislike } from '../../../assets/icons/icon-dislike__active.svg';
import { ReactComponent as Comment } from '../../../assets/icons/ic-comment.svg';
import './DirectorsCabinetStatistic.css';
import { PureComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBiData, getBiDepartmentFilters, getBIFilters, getBiWorkerFilters, getRegionFilters, setBiActiveFilters, setBiDataError, setBiDate } from '../../../Store/BIReducer/biActions';
import LongestRequest from './LongestRequest/LongestRequest';
import MiniTable from './MiniTable/MiniTable';
import BIRegions from './BIRegions/BIRegions';
import BIWorker from './BIWorker/BIWorker';
import BIDepartment from './BIDepartment/BIDepartment';
import BIFilters from './BIFilters/BIFilters';
import WhiteButton from '../WhiteButton/WhiteButton';
import ModalWindow from '../../UI/ModalWindow/ModalWindow';
import FormBlockTitle from '../../UI/FormBlock/FormBlockTitle/FormBlockTitle';
import FormBlockSubtitle from '../../UI/FormBlock/FormBlockSubtitle/FormBlockSubtitle';
import FullApplicationInfo from '../../SearchResultsComponents/FullApplicationInfo/FullApplicationInfo';
import FullApplication from './FullApplication/FullApplication';

class CustomizedLabel extends PureComponent {
  render() {
	const { x, y, stroke, value } = this.props;

	return (
	  <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
		{value}
	  </text>
	);
  }
}
const DirectorsCabinetStatistic = () => {
	const applications = useSelector(state => state.applications.applications);
	const reportTemplateRef = useRef(null);
	const biLoader = useSelector(state=>state.bi.biLoader);
	const biError = useSelector(state=>state.bi.biDataError)
	const biState = useSelector(state=>state.bi)
  	const dispatch = useDispatch();
  	const biData = useSelector(state=>state.bi.data)
  	const biDate = useSelector(state=>state.bi.date)
  	const biActiveFilters = useSelector(state=>state.bi.activeFilters)
	const company = useSelector(state=>state.company)
  	const [bar, setBar] = useState()
  	const [maxReqs, setMaxReqs] = useState(0)
  	const [minReqs, setMinReqs] = useState()
  	const dislikeColor = '#D9999B';
  	const likeColor = '#89D389';
  	const dislikeBarChartColor = '#C53036';
  	const likeBarChartColor = '#3CC13B';
  	const lineChartColor = '#4FC6E0';
	const [indexForModal, setIndexForModal] = useState();
  	const COLORS = [likeColor, dislikeColor];
	const userHash = useSelector(state => state.applications.data.hash);
  	const pieChartDummyData = [
		{ name: 'Likes', value: biData?.applications?.ratings?.like },
		{ name: 'Dislikes', value: biData?.applications?.ratings?.dislike}, 
  	];
  	const [isDepartmentModal, setIsDepartmentModal] = useState(false);
    const [isWorkerModal, setIsWorkerModal] = useState(false);
    const [isRegionModal, setIsRegionModal] = useState(false);
    const [isFullInfoModal, setIsFullInfoModal] = useState(false);
    const isFilterStatus = useSelector(state => state.bi.activeFilters?.region_uuid?.length > 0);
    const isFilterDepartment = useSelector(state => state.bi.activeFilters?.department_uuid?.length > 0 > 0);
    const isFilterWorker = useSelector(state => state.bi.activeFilters?.employee_uuid);
    const toggleRegionModal = () => {
        setIsRegionModal(!isRegionModal);
        setIsDepartmentModal(false);
        setIsWorkerModal(false);

    }
    const toggleDepartmentModal = () => {
        setIsDepartmentModal(!isDepartmentModal);

        setIsWorkerModal(false);
        setIsRegionModal(false);
    }
    const toggleWorkerModal = () => {
        setIsWorkerModal(!isWorkerModal);

        setIsDepartmentModal(false);
        setIsRegionModal(false);
    }
	const seeFullApplicationInfo = (index) => {
        setIndexForModal(index)
        setIsFullInfoModal(!isFullInfoModal);
        setIsRegionModal(false);

        setIsDepartmentModal(false);
        setIsWorkerModal(false);
    }
	const downloadPdfDocument = () => {
		const input = document.getElementById("BI_layout");
        const worker = html2pdf().set({
			margin: 5,
			filename: "Статистика_BI.pdf",
			jsPDF: { 
				
				unit: "px",
				format: [1300, 1300],	
				orientation: 'p' 
			}
		}).from(input).toImg().save();
    }
	useEffect(()=>{
		let data = {
			company: "itsupport",
			company_uuid: company.companyData.company_uuid,
			date: biDate,
	  	}
		
        dispatch(getBIFilters({data: data, hash: userHash}))

    }, [dispatch, biDate, company.companyData.company_uuid])
	
  	useEffect(()=>{
		if(company.companyData.company_uuid){
			let data = {
				company: "itsupport",
					  date: biDate,
					  department: 5,
				company_uuid: company.companyData.company_uuid,
				filter: biActiveFilters
				}
			dispatch(getBiData({data: data, hash: userHash}))
		}
  	}, [dispatch, biDate, company.companyData.company_uuid, biActiveFilters])

	
	useEffect(()=>{
		if(biData){
			let bars=[];
			let max=biData?.applications?.intervals[0].count
			let min=biData?.applications?.intervals[0].count
			for(let i=0; i<biData?.applications?.intervals?.length; i++){
				if(max<biData?.applications?.intervals[i].count){
					max=biData?.applications?.intervals[i].count
				}
				if(min>biData?.applications?.intervals[i].count){
					min=biData?.applications?.intervals[i].count
				}
				let copy = biData?.applications?.intervals[i]
				bars.push({
					name: copy.date,
					dislikes: -copy.dislikes,
					likes: copy.likes
					
				})
			}
			setMaxReqs(max)
			setMinReqs(min)
			setBar(bars)
		}
		
	}, [biData])
  	return (
		<>
			{!!biLoader ? <div> <div className='Hoc_loaderBG' /><div className='Hoc_loader' /></div> : null}
			
			<div ref={reportTemplateRef} id="BI">
				<div className='BI__filter'>
        	        <div className="ResultsTableWorker__filtersBlock">
        	            <div className="ResultsTableWorker__filterWithIcon ResultsTableWorker__filter--status">
        	                <p className="ResultsTableWorker__filterText">Филиал</p>
        	                {isRegionModal ? <BIRegions close={toggleRegionModal} /> : null}
        	                <div onClick={toggleRegionModal} className={`ResultsTableWorker__filterIcon ${isFilterStatus ? "ResultsTableWorker__filterIcon--active" : null}`} />
        	            </div>
        	            <div className="ResultsTableWorker__filterWithIcon ResultsTableWorker__filter--worker">
        	                <p className="ResultsTableWorker__filterText">Сотрудник</p>
        	                {isWorkerModal ? <BIWorker close={toggleWorkerModal} /> : null}
        	                <div onClick={toggleWorkerModal} className={`ResultsTableWorker__filterIcon ${isFilterWorker ? "ResultsTableWorker__filterIcon--active" : null}`} />
        	            </div> 
        	            <div className="ResultsTableWorker__filterWithIcon ResultsTableWorker__filter--department">
        	                <p className="ResultsTableWorker__filterText">Отдел</p>
        	                {isDepartmentModal ? <BIDepartment close={toggleDepartmentModal} /> : null}
        	                <div onClick={toggleDepartmentModal} className={`ResultsTableWorker__filterIcon ${isFilterDepartment ? "ResultsTableWorker__filterIcon--active" : null}`} />
        	            </div> 
        	        </div>
        	    </div>
				{!biError?
				<>
				<div className='DirectorsCabinet__Statistic__Container'>
				  	<StatisticBlock
				  	  	value={biData?.applications?.total}
				  	  	label='сформированных заявок'
				  	/>
				  	<StatisticBlock
				  	  	value={biData?.applications?.hours}
				  	  	label='часов трудозатрат'
				  	/>
				  	<StatisticBlock
				  	  	value={biData?.applications?.average_time}
				  	  	label='среднее время выполнения задачи в часах'
				  	/>
				  	<StatisticBlock
				  	  	value={biData?.applications?.ratings?.average?biData?.applications?.ratings?.average + "%": 0}
				  	  	label='положительных отзывов о работе'
				  	/>

				  	<div className='DirectorsCabinet__StatisticForPeriod'>
				  	  	<div className='StatisticForPeriod__TextInformation'>
				  			<p className='StatisticForPeriod__Header'>
				  		  		Статистика заявок за период
				  			</p>
				  			<div className='StatisticForPeriod_RequestsMaxMin'>
				  			  <p>заявок max – {maxReqs}</p>
				  			  <p>заявок min – {minReqs}</p>
				  			</div>
				  	   		<div className='StatisticForPeriod__RequestsAverage'>
				  	   		  	<p className='RequestsAverage__Header'>{(maxReqs+minReqs)/2 | 0}</p>
				  	   		  	<div className='RequestsAverage__LabelPercent'>
				  	   		  	  	<span className='RequestsAverage__Label'>
				  	   		  	  	  	заявки
				  	   		  	  	  	<p>среднее за период</p>
				  	   		  	  	</span>
				  	   		  	</div>
				  	   		</div>
				  	  	</div>
				  	  	<div className='StatisticForPeriod__Graphic'>
				  			<ResponsiveContainer width='85%' height={140}>
				  		  	<LineChart data={biData?.applications?.intervals}>
				  			<CartesianGrid
				  			  	// stroke='#fff'
				  			  	className='LineChart__CartesianGrid'
				  			  	color='#fff'
				  			/>
				  			<Line
				  			  	type='monotone'
				  			  	dataKey='count'
				  			  	label={<CustomizedLabel />}
				  			  	stroke={lineChartColor}
				  			  	dot={false}
				  			/>
				  			<XAxis
				  				dataKey="date"
				  				padding={{
				  				  left: 12,
				  				  right: 12
				  				}}
				  				style={{
				  				  fontSize: "0.7rem",
				  				  fontFamily: "Source Sans Pro"
				  				}}
				  				/>
				  				<YAxis
				  				  	style={{
				  				  	  	fontSize: "0.7rem",
				  				  	  	fontFamily: "Source Sans Pro"
				  				  	}}
				  			/>

				  		  </LineChart>
				  		</ResponsiveContainer>
				  		<div className='StatisticForPeriod__Graphic__Grid__Container' />
				  	  </div>
				  	</div>
								
				  	<div className='DirectorsCabinet__DepartmentRequests'>
				  	  	<p className='DepartmentRequests__Header'>Заявок по отделам:</p>
				  	  	<div className='DepartmentRequests__Requests'>
				  	  	  	{
				  	  	  	  	biData?.applications?.by_department?.map(department => {
				  	  	  	  	  	return(
				  	  	  	  	  	  	<div key={department.uuid} className='Request__Container'>
				  	  	  	  	  	  	  	<span>{department.department_rus}</span>
				  	  	  	  	  	  	  	<span className='Request__Value notranslate'>{department.applications}</span>
				  	  	  	  	  	  	</div>
				  	  	  	  	  	)
				  	  	  	  	})
				  	  	  	}

				  	  	</div>
				  	</div>
						
				  	<div className='DirectorsCabinet__JobReviews'>
				  	  	<div className='JobReviewsTotal__Container'>
				  	  	  	<span className='JobReviewsTotal__Value'>{biData?.applications?.ratings?.total}</span>
				  	  	  	<span className='JobReviewsTotal__Label'>Всего отзывов о работе</span>
				  	  	</div>
				  	  	<div className='JobReviewsDiagrams__Container'>
				  			<div className='JobReviewsDiagrams__Pie'>
				  		  		<PieChart
				  					margin={{ top: -9, left: -9, right: -9, bottom: -9 }}
				  					width={75}
				  					height={75}>
				  						<Pie data={pieChartDummyData} stroke='none'>
				  						  {pieChartDummyData.map((entry, index) => (
				  							<Cell
				  							  key={`cell-${index}`}
				  							  fill={COLORS[index % COLORS.length]}
				  							/>
				  						  ))}
				  						</Pie>
				  		  		</PieChart>
				  		</div>
				  		<div className='JobReviewsDiagrams__LikesDislikes'>
				  		  	<div className='JobReviewsDiagrams__ReviewsAmount__Container'>
				  		  	  	<p className='LikesDislikes__Amount'>{biData?.applications?.ratings?.like}</p>
				  		  	  	<p className='LikesDislikes__Amount Dislikes__Amount'>{biData?.applications?.ratings?.dislike}</p>
				  		  	</div>
				  		  	<div className='JobReviewsDiagrams__ReviewsIcons__Container'>
				  		  	  	<Like className='LikesDislikes' />
				  		  	  	<Dislike className='LikesDislikes' />
				  		  	</div>
				  		</div>
				  		<div className='JobReviewsDiagrams__BarChart__Container'>
				  		  	<BarChart
				  		  	  	width={135}
				  		  	  	height={75}
				  		  	  	data={bar}
				  		  	  	stackOffset='sign'>
				  		  	  	<Bar
				  		  	  	  	dataKey='likes'
				  		  	  	  	barSize={5}
				  		  	  	  	fill={likeBarChartColor}
				  		  	  	  	stackId='stack'
				  		  	  	/>
				  		  	  	<Bar
				  		  	  	  	dataKey='dislikes'
				  		  	  	  	barSize={5}
				  		  	  	  	fill={dislikeBarChartColor}
				  		  	  	  	stackId='stack'
				  		  	  	/>
				  		  	</BarChart>
				  		</div>
				  	  </div>
				  	  	<div className='JobReviews__BadGood__Container'>
				  	  	  	<p className='GoodReviews'> <span className='notranslate'>{biData?.applications?.ratings?.max?.likes?.likes || 0}</span>  (max) положительных : {biData?.applications?.ratings?.max?.likes?.department_rus || ''}</p>
				  	  	  	<span className='JobReviews__BadGood__GreyLine' />
				  	  	  	<p className='BadReviews'> <span className='notranslate'>{biData?.applications?.ratings?.max?.dislikes?.dislikes || 0} </span> (max) отрицательных: {biData?.applications?.ratings?.max?.dislikes?.department_rus || ''}</p>
				  	  	</div>
				  	</div>
										
				  	<LongestRequest
						onClick={()=>setIsFullInfoModal(true)}
						most_labor_costs = {biData?.applications?.longest?.labor_costs}
						date = {biData?.applications?.longest?.finished_at}
						reqStatus={biData?.applications?.longest?.status?.name_rus}
						employeeName={biData?.applications?.longest?.employee?.name}
						departamentName={biData?.applications?.longest?.departament?.name_rus}
						workerName={biData?.applications?.longest?.implementer?.name_rus}
						workerIt={biData?.applications?.longest?.implementer?.it}
						outcome={biData?.applications?.longest?.outcome}
						reqSubject={biData?.applications?.longest?.subject}
						rating_value={biData?.applications?.longest?.rating_value}
						reqComent={biData?.applications?.longest?.rating_comment}
					/>

										
										
				</div>
				<MiniTable
					tableTitle="пользователи с наибольшим количествои заявок"
					subtitle_1="пользователь"
					subtitle_2="заявок"
					subtitle_3="лайков"
					subtitle_4="дизлайков"
					subtitle_5="комментариев"
					subtitle_6="трудозатраты"
					subtitle_7="ID пользователя"
					dataArr={biData?.applications?.employees}
				/>
				
				</>
				:<div className='BI_ERROR'>
					<div className='BI_ERROR__title'>{biError}</div>	
					<div className='BI_ERROR__text'>Попробуйте сбросить фильтры или выбрать другой период</div>
				</div>}
				<BIFilters/>
				{
					!biError?
					<div className='BI__stats_control'>
					<div className='BI__downloadStats'>
						<WhiteButton
							name="Скачать статистику"
							clicked={downloadPdfDocument}
						/>
					</div>
										
					</div>
					:
					null
				}
				
				
			</div>
			{isFullInfoModal ?
                <>
                    <div className="FullInfoModalBg" onClick={() => {seeFullApplicationInfo(indexForModal)}} />
                    <FullApplication
                        clickToClose={() => {seeFullApplicationInfo(indexForModal)}}
                        application={applications[indexForModal]}
                        commentMessage={applications[indexForModal] ? applications[indexForModal].rating_comment : ""}
						statusColor = "#3cc131"
						date={biData.applications.longest?.finished_at}
						status={biData.applications.longest?.status?.name_rus}
						ApplicationId={biData.applications.longest?.number}
						specialistPhoto={biData.applications.longest?.implementer?.photo}
						specialistName={biData.applications.longest?.implementer?.name_rus}
						specialistId={biData.applications.longest?.implementer?.it}
						workerName={biData.applications.longest?.employee?.name}
						// workerId={biData.applications.longest.implementer.it}
						department={biData.applications.longest?.departament?.name_rus}
						subject={biData.applications.longest?.subject}
						applicationInfo
						result={biData.applications.longest?.outcome}
						fileImage={biData.applications.longest?.images?.length>0?biData.applications.longest?.images:null}
                    />
                </>:
                null}
		</>
		
  	);
};

export default DirectorsCabinetStatistic;
