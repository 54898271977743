import { useState } from "react"
import "./FileBlock.css"

export default function FileBlock(props){
    const [deleteFile, setDeleteFile] = useState(false)
    return(
        <div className={`FileBlock ${deleteFile? "FileBlock__removing": ""}`}>
            <button className="FileBlock__delete_button" type="button" 
            onClick={()=>{
                props.onClickDeleteHandler()
                setDeleteFile(true)
            }
            }
            ></button>
            <div className="FileBlock__name">{props.filename}</div>
            <div className="FileBlock__size">{props.size}</div>
        </div>
    )
}