import "./ChatName.css"

export default function ChatName(props){
    return(
        <div className="ChatName__wrapper">
            <div className="ChatName__name">
                {props.name}
            </div>
            <div className="ChatName__time">
                {props.time}
            </div>
        </div>
    )
}