import React from "react";
import './FormBlockTitle.css'
import Hint from "../../Hint/Hint";

const FormBlockTitle = (props) =>{
    return (
        <h2 className="FormBlock_title">
            {props.children} 
        
        {props.hint? <Hint 
            hint={props.hint}
            setHintShow = {props.setHintShow}
            setHintHidden={props.setHintHidden}
            hintState={props.hintState}
        />: null}</h2>
    )

}

export default FormBlockTitle;