import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import {push} from 'connected-react-router'

const RestoreLastSession = () => {
  const dispatch = useDispatch();
  const id = JSON.parse(localStorage.getItem('state'))?.applications?.data.clientId;
  const idBlacklist = ['111-01-01111'];

  useEffect(()=>{
    if(id && !idBlacklist.includes(id)) dispatch(push(`/application/${id}/newticket`));
    else dispatch(push(`/undefined`));
  }, [])

    return(<></>)
}

export default RestoreLastSession