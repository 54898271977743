import React from "react";
import './FormBlockSubtitle.css'
import Hint from "../../Hint/Hint";

const FormBlockSubtitle = (props) =>{
    return (
        <h2 className="FormBlock_subtitle">
            {props.children} 
        
        {props.hint? <Hint 
            hint={props.hint}
            setHintShow = {props.setHintShow}
            setHintHidden={props.setHintHidden}
            hintState={props.hintState}
        />: null}</h2>
    )

}

export default FormBlockSubtitle;