import React from "react";
import './HrModalWindow.css'
import TextLogo from "../../UI/Logos/TextLogo/TextLogo";
import ModalWindow from "../../UI/ModalWindow/ModalWindow";
import ItsupportTextLogo from '../../../assets/logos/itsupport_textlogo.svg'
import FunctionCard from "./FunctionCard/FunctionCard";
import { CONFIG } from "../../../constants/backendUrl";

const HrModalWindow = (props) => {
    return(
        <ModalWindow 
            bgOnClick = {props.bgOnClick}
            modalStatus = {props.modalStatus}
            contentWidth = '100%'
        >
            <div className="HrModalWindow">
                <div className="HrModalWindow__header">
                    <div className="HrModalWindow__header_logo">
                        <TextLogo textLogo={ItsupportTextLogo}/>
                    </div> 
                </div>
                <h3 className="HrModalWindow__title">Выберите событие для передачи доступов</h3>
                <div className="HrModalWindow__functions">
                    <FunctionCard 
                        title="Новый сотрудник"
                        text="Открытие доступов для нового сотрудника"
                        colorClass="FunctionCard__green"
                        onClick={()=>{window.location.assign(`${CONFIG['appDomain']}/${props.id}/access/open`)}} 
                    />
                    <FunctionCard 
                        title="Перевод сотрудника"
                        text="Смена рабочего места или перевод сотрудника"
                        colorClass="FunctionCard__yellow"
                        onClick={()=>{window.location.assign(`${CONFIG['appDomain']}/${props.id}/access/change`)}} 
                    />
                    <FunctionCard 
                        title="Увольнение сотрудника"
                        text="Закрытие доступов при увольнении сотрудника"
                        colorClass="FunctionCard__dark"
                        onClick={()=>{window.location.assign(`${CONFIG['appDomain']}/${props.id}/access/close`)}} 
                    />
                </div>
                <div className="HrModalWindow__phone">+7 707 390 11 12</div>
                <div className="HrModalWindow__phone_description">служба поддержки пользователей</div>
            </div>
        </ModalWindow>
    )
}

export default HrModalWindow;