
import update from 'immutability-helper';
import { SET_BI_ACTIVE_FILTERS, SET_BI_DATA, SET_BI_DATA_ERROR, SET_BI_DATE, SET_BI_DEPARTMENT_FILTERS, SET_BI_LOADER, SET_BI_REGION_FILTERS, SET_BI_WORKER_FILTERS, SET_INTEGRATOR, SET_INTEGRATOR_LOADER } from './biActionTypes';

const initialState = {
    data: {},
    date: {
        type:"month",
    },
    regionfilters: {},
    integrator: {
        uuid: "",
        name: "",
        logo_module: null,
        logo_sidebar: null,
    },
    workerfilters: {},
    departmentfilters:{},
    activeFilters: {},
    biDataError: "",
    biLoader: false,
    integratorLoader: false,
};

const biReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INTEGRATOR:
            return({...state, integrator: action.value})
        case SET_INTEGRATOR_LOADER:
            return({...state, integratorLoader: action.value})
        case SET_BI_DATA:
            return ({...state, data: action.value})
        case SET_BI_DATE:
            return ({...state, date: action.value})
        case SET_BI_REGION_FILTERS:
            return ({...state, regionfilters: action.value})
        case SET_BI_WORKER_FILTERS:
            return ({...state, workerfilters: action.value})
        case SET_BI_DEPARTMENT_FILTERS:
            return ({...state, departmentfilters: action.value})
        case SET_BI_ACTIVE_FILTERS:
            return ({...state, activeFilters: action.value})
        case SET_BI_DATA_ERROR:
            return ({...state, biDataError: action.value})
        case SET_BI_LOADER:
            return ({...state, biLoader: action.value})
        default:
            return state;
    }
};

export default biReducer;
