import React, {useState} from 'react';
import BlueButton from '../../ApplicationPageComponents/BlueButton/BlueButton';
import './AccessApplicationDetails.css';
import Chat from '../../ApplicationPageComponents/Chat/Chat';

const AccessAccessApplicationDetails = (props) => {

    const [messageClassToggle, setMessageClassToggle] = useState("messageClassToggleHidden");
    const [messageClassButtonText, setMessageClassButtonText] = useState('показать подробнее');

    const [resultClassToggle, setResultClassToggle] = useState("messageClassToggleHidden");
    const [resultClassButtonText, setResultClassButtonText] = useState('показать подробнее');

    let jobDone = props.jobDone;
    let isCanceled = props.isCanceled;

    const department = props.department;
    const subject = props.subject;

    // const message = props.message.split("\n").join("<br />")
    const message = props.message?.replace(/[\n]/g, "<br>")?.split("\n")
    const details = message.map((el, i) => {
        return el.length ? <p key={i} dangerouslySetInnerHTML={{__html: `${el}`}}
                              className={`AccessApplicationDetails__text ${el.includes("Комментарий") ? "AccessApplicationDetails__content__text--commentDate" : ""}`}/> : null
    });
    // const message = props.message.split("\n").join("<br />")
    const result = props.result;


    const textShowToggle = () => {
        if (messageClassToggle === 'messageClassToggleHidden') {
            setMessageClassToggle('messageClassToggleOpen');
            setMessageClassButtonText('скрыть текст');
        } else {
            setMessageClassToggle('messageClassToggleHidden');
            setMessageClassButtonText('показать подробнее');
        }
    }
    const resultShowToggle = () => {
        if (resultClassToggle === 'messageClassToggleHidden') {
            setResultClassToggle('messageClassToggleOpen');
            setResultClassButtonText('скрыть текст');
        } else {
            setResultClassToggle('messageClassToggleHidden');
            setResultClassButtonText('показать подробнее');
        }
    }

    let centerComponent;

    centerComponent = (
        <div className="AccessApplicationDetails">
            <h2 className="AccessApplicationDetails__title">Детали заявки {props.idInTitle}</h2>
            <div className="AccessApplicationDetails__head">
                <div className="AccessApplicationDetails__head--sides">
                    <p className="AccessApplicationDetails__text--title AccessApplicationDetails__text--title--height">Ответственный
                        отдел</p>
                    <p className="AccessApplicationDetails__text">{department}</p>
                </div>
                <div className="AccessApplicationDetails__head--sides">
                    <p className="AccessApplicationDetails__text--title AccessApplicationDetails__text--title--height">Тема</p>
                    <p className="AccessApplicationDetails__text">{subject}</p>
                </div>
            </div>
            <p className="AccessApplicationDetails__text--title">Подробности</p>
                {
                    props.children
                }
            <div className={props.message.length > 100 ? messageClassToggle : "messageClassToggleHidden--empty"}>
                {/*<div dangerouslySetInnerHTML={{__html: `<p className="AccessApplicationDetails__text">${message}<p>`}} className="AccessApplicationDetails__text--block" />*/}
                {details}
                
                {props.showDetailsButton ?
                    <div onClick={textShowToggle} className="AccessApplicationDetails__message--button"><span
                        className="AccessApplicationDetails__text">... </span>{messageClassButtonText}</div> : null}
            </div>
            <>
                {result?<p className="AccessApplicationDetails__text--title">Результат</p>:null}
                <div className={result.trim().length > 100 ? resultClassToggle : "messageClassToggleHidden--empty"}>
                    <p className="AccessApplicationDetails__text">{result}</p>
                    {props.showResultButton ?
                        <div onClick={resultShowToggle} className="AccessApplicationDetails__message--button"><span
                            className="AccessApplicationDetails__text">... </span>{resultClassButtonText}</div> : null}
                </div>
                
            </>
            
            {isCanceled ?
                <>
                    <p className="AccessApplicationDetails__text--title AccessApplicationDetails__text--title--high">Причины отмены
                        заявки</p>
                    <div className={result.trim().length > 100 ? resultClassToggle : "messageClassToggleHidden--empty"}>
                        <p className="AccessApplicationDetails__text">{result}</p>
                        {props.showResultButton ?
                            <div onClick={resultShowToggle} className="AccessApplicationDetails__message--button"><span
                                className="AccessApplicationDetails__text">... </span>{resultClassButtonText}</div> : null}
                    </div>
                </>
                :
                jobDone?null:
                <>
                    <textarea value={props.oneComment}
                              placeholder="Если вы хотите дополнить заявку, напишите комментарий"
                              onChange={props.onChangeComment} className="AccessApplicationDetails__textarea"/>
                    <div className="AccessApplicationDetails__btn">
                        <BlueButton
                            name="Отправить сообщение"
                            clicked={props.submitComment}
                        />
                    </div>
                </>
            }
        </div>
    );

    return (
        <>
            {props.showChat?
            <Chat showChat={props.showChat}/>:
            centerComponent}
        </>
    )
}


export default AccessAccessApplicationDetails;