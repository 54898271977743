
import { io } from "socket.io-client";
import { CONFIG } from "../constants/backendUrl";
import store from "../Store/configStore";
const wsURL = CONFIG.chatApi;
export const ws = io(`${wsURL}/chat`,{
    autoConnect: false,
    auth(cb) {
      cb({ token: `Guest:${store.getState().applications.data.clientId}:${store.getState().applications.currentApplicationHash}` });
    },
    transports: ["websocket"],
});
