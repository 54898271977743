import React, {useState} from 'react';
import './RedButton.css'


const RedButton = (props) => {

    return (
        <>
            <button className="RedButton" disabled={props.disabled} onClick={props.disabled ? null : props.onClickHandler} >{props.name}
            </button>
            
        </>
    )
}

export default RedButton;