import React, { useEffect } from 'react';
import Logo from '../Logo/Logo';
import WhiteButton from '../WhiteButton/WhiteButton';
import './LayoutApplicationPage.css';
import { openBlankByClassName } from '../../../utils/openBlank';
import { Conference } from '../../../assets/icons/video-call_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createVks } from '../../../Store/VksReducer/vksActions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { copyToClipboard, selectAllOnDoubleClick } from '../../../utils/functions/textFunctions';
import VksReadyModal from '../../VksInviteComponents/VksReadyModal/VksReadyModal';
import FullApplicationInfo from '../../SearchResultsComponents/FullApplicationInfo/FullApplicationInfo';

import FullApplicationInfoOld from '../../SearchResultsComponents/FullApplicationInfoOld/FullApplicationInfoOld';
import { setShowReturnApplicationModal } from '../../../Store/GeneralReducer/generalActions';
const LayoutApplicationPage = (props) => {
    const dispatch = useDispatch()
    const {hasVks, vksLink, vksParts} = useSelector(state=>state.vks)
    const {lastApplicationFullData, lastApplication, returningApplicationData, returningApplicationId} = useSelector(state=>state.applications)
    const {showReturnApplication} = useSelector(state=>state.general)
    const currentURL = window.location.protocol + '//' + window.location.host;
    const createVksHandler = () => {
        if(hasVks){
            const MySwal = withReactContent(Swal)
            MySwal.fire({
                title: 'Видеоконференции на портале Asista',
                html: 
                    <VksReadyModal currentURL={currentURL} vksParts={vksParts} vksLink={vksLink} id={props.id}/>,
                showConfirmButton: false,
                showCloseButton: false
            })
        }else{
            const MySwal = withReactContent(Swal)
            MySwal.fire({
                title: 'Видеоконференции на портале Asista',
                html: 
                    <div> 
                        Для одного клиента-компании мы создаем комнату для проведения внутренних конференций.
                        Вы можете отправить ссылку на нее всем участникам конференции и в указанное время решать рабочие вопросы.
                        Нажмите «Создать видеоконференцию», и вы автоматически попадете в комнату, созданную специально для вашей компании. 
                        <br/>
                        <br/>*Для абонентов IT Support Group данный сервис является бесплатным. Мы не используем персональные данные, озвученные в ходе видеоконференции.
                        <div className='Modal__buttons'>
                            <button type="button" onClick={()=>dispatch(createVks(props.id))} className="VksInviteModule__blue_button" >
                                Создать комнату
                            </button>
                            <button type="button" onClick={()=>Swal.close()} className="VksInviteModule__form_submit">
                                Отменить
                            </button>  
                        </div>
                    </div> ,
                showConfirmButton: false,
                showCloseButton: false
            })
            
        }
    }
    return (
        <div className="LayoutApplicationPage" id="Layout">
            <div className="LayoutApplicationPage_sidebar">
                <Logo
                    logoLink={props.createNewApplication}
                />
                <div className="LayoutApplicationPage__prev-menu">{props.left}</div>
                <div className={"WhiteButton--heightStabilizer"}/>
                {
                props.id==='111-01-01111'? null:
                <>
                    <WhiteButton
                        name="Создать новую заявку"
                        clicked={props.createNewApplication}
                        hide={props.hideButton}
                    />
                    <button className="create-conferenceBtn" onClick={createVksHandler}>
                        <div className={`vks__icon ${hasVks?`vks__icon_active`:``}`}></div>
                        {hasVks?"Войти в видеоконференцию": "Создать видеоконференцию"}
                    </button>
                    <WhiteButton
                        name={"Посмотреть историю моих заявок"}
                        clicked={props.goToApplicationHistory}
                    />
                </>
                }
                
                <div className="WhiteButton-socialContent">
                    <p>Мы в социальных сетях</p>
                    <div className="social-instagram"  onClick={openBlankByClassName}   ></div>
                    <div className="social-meta"       onClick={openBlankByClassName}   ></div>
                    <div className="social-linkedin"   onClick={openBlankByClassName}   ></div>
                    <div className="social-youtube"    onClick={openBlankByClassName}   ></div>
                </div>
            </div>
            <div className="LayoutApplicationPage__main">
                <div className="LayoutApplicationPage__top">{props.top}</div>
                <div className="LayoutApplicationPage__center">{props.center}</div>
                <div className="LayoutApplicationPage__bottom">{props.bottom}</div>
            </div>
            {showReturnApplication && (returningApplicationData) ?
                <>
                    <div className="FullInfoModalBg" onClick={() => {dispatch(setShowReturnApplicationModal(false))}} />
                        <FullApplicationInfoOld
                            applicationId = {returningApplicationId}
                            clickToClose={()=>dispatch(setShowReturnApplicationModal(false))}
                            application={returningApplicationData}
                            first={true}
                            last={true}
                            commentMessage={returningApplicationData ? returningApplicationData.rating_comment : ""}
                        />
                </>:
                null}
        </div>
    )
}

export default LayoutApplicationPage;
