import React, { useEffect } from "react";
import './FaqSidebar.css'
import IT_SUPPORT from "../../../assets/images/itsupport-sidebar.svg"
import IT_SUPPORT_LOGO from "../../../assets/images/itsg_logo.svg"
import VENTRA from '../../../assets/images/ventra.svg'
import VENTRA_LOGO from '../../../assets/images/ventra-logo.svg'
import DropDownText from "./DropDownText/DropDownText";
import { useDispatch, useSelector } from "react-redux";
import { sendFaqFeedback, setFaqOpen, setFeedback } from "../../../Store/GeneralReducer/generalActions";


const FaqSidebar = (props) => {
    const faqQuestionsState = props.openState
    const faqQuestions = props.questions; //useSelector(state=>state.general.faq.questions)
    const faqFeedback = props.faqFeedback;
    const dispatch = useDispatch();

    const { integrator, integratorLoader } = useSelector(state => state.bi)

    const getIntegratorLogo = (name) => {
        switch(name){
            case 'ventra':
                return {
                    nameLogo: VENTRA,
                    logo: VENTRA_LOGO,
                }
            default:
                return {
                    nameLogo: IT_SUPPORT,
                    logo: IT_SUPPORT_LOGO,
                }
        }
    }

    const name = getIntegratorLogo(integrator?.name).nameLogo
    const logoName = getIntegratorLogo(integrator?.name).logo
    
    return(
        <div>
            <div onClick={props.goBackOnClick} className={`FaqSidebar__background ${props.showFaq? "FaqSidebar__background__active":"FaqSidebar__background__unactive"}`}></div>
            <div className={`FaqSidebar__wrapper ${props.showFaq? "FaqSidebar__active":"FaqSidebar__unactive"}`}>
                <div className="FaqSidebar__leftbar"></div>
                <div className="FaqSidebar__body_wrapper">
                <div className="FaqSidebar__body">
                    <h3 className="FaqSidebar__title">Типовые ИТ вопросы, которые вы можете решить самостоятельно!</h3>
                    <p className="FaqSidebar__info">Вы можете не ждать ответа наших ИТ специалистов, а попробовать разобраться самостоятельно, мы собрали самые простые и частые вопросы, решение которых займет у вас от одной до пяти минут. Если ничего не сработало, пожалуйста обратитесь к специалистам IT Support Group.</p>
                    <div className="FaqSidebar__questions">
                        <div className="FaqSidebar__questions_short">
                            {faqQuestions?.map(question => {
                                if(question.type === "short"){
                                    let index = question.name;
                                    return (
                                        <div className="FaqSidebar__shortQuestion"
                                            key = {question.name}
                                        >
                                            <DropDownText 
                                                value = {({...faqQuestionsState, [index]: !faqQuestionsState[index]})}
                                                showDropDown = {faqQuestionsState[index]}
                                                title={question.title}
                                                text={question.body}
                                                faqUseful={faqFeedback[question.name+"_useful"]}
                                                feedbackButtonOnClickYes={async ()=>{
                                                    dispatch(sendFaqFeedback({name: question.name, useful: true}))
                                                    dispatch(setFeedback({...faqFeedback, [question.name+"_useful"]: true}))
                                                    
                                                }}
                                                feedbackButtonOnClickNo={async ()=>{
                                                    dispatch(sendFaqFeedback({name: question.name, useful: true}))
                                                    dispatch(setFeedback({...faqFeedback, [question.name+"_useful"]: false}))
                                                    
                                                }}
                                            />
                                        </div>
                                    )
                                }else if(question.type === "long"){
                                    let index = question.name;
                                    return (
                                        <div className="FaqSidebar__longQuestion"
                                            key = {question.name}
                                        >
                                            <DropDownText 
                                                value = {({...faqQuestionsState, [index]: !faqQuestionsState[index]})}
                                                showDropDown = {faqQuestionsState[index]}
                                                title={question.title}
                                                text={question.body}
                                                faqUseful={faqFeedback[question.name+"_useful"]}
                                                feedbackButtonOnClickYes={async ()=>{
                                                    dispatch(sendFaqFeedback({name: question.name, useful: true}))
                                                    dispatch(setFeedback({...faqFeedback, [question.name+"_useful"]: true}))
                                                    
                                                }}
                                                feedbackButtonOnClickNo={async ()=>{
                                                    dispatch(sendFaqFeedback({name: question.name, useful: true}))
                                                    dispatch(setFeedback({...faqFeedback, [question.name+"_useful"]: false}))
                                                    
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                else{
                                    return null
                                }

                            })}
                        </div>
                    </div>

                </div>
                </div>
                        
                <div className="FaqSidebar__control ">
                    {
                        props.showFaq?
                        <div className="FaqSidebar__go_back" onClick={props.goBackOnClick}>
                            <span className="FaqSidebar__go_back_icon">&lt;</span>
                            <span className="FaqSidebar__go_back_span">НАЗАД</span>
                        </div>
                        :
                            <div className="FaqSidebar__go_back" onClick={props.showFaqOnClick}>
                                <span className="FaqSidebar__go_back_icon">?&gt;</span>
                                <span className="FaqSidebar__go_back_span FaqSidebar__FAQ notranslate">FAQ</span>
                            </div>
                    }

                    <div className="FaqSidebar__itsupport">
                        {
                            !integratorLoader ?
                            <>
                                <img src={name} alt="logo"/>
                                <img className = "FaqSidebar__small_logo" src={logoName} alt="logo"/>
                            </>
                            :   
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default FaqSidebar;