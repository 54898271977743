import React, { useState } from "react";
import { useDispatch } from "react-redux";
import './ApplicationSpecialist.css'
import { leaveTheRate } from "../../../Store/ApplicationsReducer/applicationsActions";
import WhiteButton from "../../ApplicationPageComponents/WhiteButton/WhiteButton"
import {ReactComponent as CommentIcon} from "../../../assets/icons/comment_icon.svg";
import ReviewPopUp from "../../ApplicationPageComponents/ReviewPopUp/ReviewPopUp";

const ApplicationSpecialist = (props) => {
    const dispatch = useDispatch()
    const currentApplication = props.currentApplication;
    const status = currentApplication?.status;
    const photo = currentApplication? `data:image/jpg;base64, ${currentApplication.image}` : null;
    const name = currentApplication?.responsible? currentApplication?.responsible: null;
    const popUpWithBackground = (
        <div
            onClick={({target}) => {
                (target?.childNodes?.[0]?.className === 'ReviewPopUp__Container' ||
                    target?.className === 'PopUp__Background') &&
                props.setReviewFormFalse();
            }}
            className='PopUp__Background'>
            <ReviewPopUp name={name} photo={photo} like={props.isLike} dislike={props.isDisLike}
                isLikeHandler={props.isLikeHandler}
                isDisLikeHandler={props.isDisLikeHandler}
                clientName={props.clientName} currentApplication={currentApplication}
                id={props.clientID} clicked={()=>props.applyCommentToSpecialist()}
                commentValue={props.commentValue}
                onChangeComment={props.onChangeComment}
                commentButtonVissibility={props.commentButtonVissibility}
            />
        </div>
    );
    
    return (
        !props.newApplication?
        <div className="ApplicationSpecialist__wrapper">
            <div className="ApplicationSpecialist">
                <div className="flex">
                    <div className={`ApplicationSpecialist__color_bar ${status==="В работе" ? 'backColor-orange' : ''}${status==="Завершено" ? 'backColor-green' : ''} ${status==="Отменено" ? 'backColor-grey' : ''}${status==="Пауза" ? 'backColor-aqua' : ''}`}></div>
                    <div className="ApplicationSpecialist__content">
                        <h2
                            className={`ApplicationSpecialist__status 
                            ${status==="В работе" ? 'color-orange' : ''} 
                            ${status==="Завершено" ? 'color-green' : ''} 
                            ${status==="Отменено" ? 'color-grey' : ''} 
                            ${status==="Пауза" ? 'color-aqua' : ''}`}
                        >
                            {status === "В работе" ? 'Заявка в работе' : ''}
                            {status==="Завершено" ? 'Заявка закрыта' : ''}
                            {status==="Отменено" ? 'Заявка отменена' : ''}
                            {status==="Пауза" ? 'Заявка на паузе' : ''}
                        </h2>
                        <div className="ApplicationSpecialist__imagewithphoto">
                            <img
                                className='ApplicationSpecialist__photo'
                                src={photo}
                                alt={props.name}
                            />

                            <div className='ApplicationSpecialist__info'>
                                {status==="Пауза" || status==="В работе" ? <p className='SpecialistOnTheWay-text'>Скоро с вами свяжется</p>: null}
                                <p className='ApplicationSpecialist__info_name'>{name}</p>
                                <div className='ApplicationSpecialist__info_contact'>
                                    <p className='ApplicationSpecialist__info_department'>IT {currentApplication?.phonenumber}</p>
                                    <p className='ApplicationSpecialist__info_phone'>+7 727 390 1112</p>
                                </div>
                            </div>
                        </div>


                        {(status==="Отменено" || status==="Завершено")?
                        <div className='ratingBlock'>
                            <p className='prompt'>оцените мою работу</p>
                            <div className='options'>
                                {props.isLike ? <button
                                    className={`likeBtn likeBtn-active`}
                                    onClick={props.isLikeHandler}
                                    disabled={props.isLike || props.isDisLike? true: false}
                                    /> : null
                                    
                                }
                                {props.isDisLike ? <button
                                    className={`dislikeBtn dislikeBtn-active`}
                                    onClick={props.isDisLikeHandler}
                                    disabled={props.isLike || props.isDisLike? true: false}
                                    /> : null
                                    }
                                {props.currentApplication?.comment ?
                                    <>
                                        <CommentIcon  className='cursor-pointer' onClick={props.togglePopUp}/>
                                        <div className={`comment-block ${props.isHoverReview ? 'show-modal' : 'hide-modal'}`}>
                                            <div className={`comment-modal_block ${props.isHoverReview ? 'show-block' : 'hide-block'}`}>
                                                <div className='comment-modal_text-block'>
                                                    <p>{props.currentApplication.commentstr}</p>
                                                </div>
                                            </div>
                                            <div className={`comment-block-bg ${props.isHoverReview ? 'show-modal' : 'hide-modal'}`}/>
                                        </div>
                                    </>
                                    : null}
                                {!props.isLike && !props.isDisLike ? <>
                                    <div
                                        className={`likeBtn`}
                                        onClick={props.isLikeHandler}/>
                                    <div
                                        className={`dislikeBtn`}
                                        onClick={props.isDisLikeHandler}/>
                                </> : null
                                }
                            </div>
                            {props.currentApplication?.comment?null:
                            <WhiteButton
                                className='leaveOpinion'
                                name={'Оставить отзыв'}
                                clicked={props.togglePopUp}
                            />}

                            {props.reviewForm && popUpWithBackground}
                        </div>:null}
                    </div>
                    </div>
                    
                </div>
            
            </div>:
            <div className='StatusNew'>
                <div className='StatusNew-warn'>
                    <h2 className='StatusNew__title'>Ура, мы получили вашу заявку!</h2>
                    <p className='StatusNew_text'>
                        Сейчас Asista ищет подходящего айтишника из нашей команды, это
                        займет максимум 15 минут.
                    </p>
                </div>
                {props.needTimer? 
                    <pre className='StatusNew__timeBlock notranslate'>
                        <span className='StatusNew__time StatusNew__time--numbers'>
                            {props.minutes}
                        </span>
                        <span className='StatusNew__time'>&#58;</span>
                        <span className='StatusNew__time StatusNew__time--numbers'>
                            {props.seconds}
                        </span>
                    </pre>
                :null
                }
        </div>
    )
}

export default ApplicationSpecialist;