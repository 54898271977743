/*
    "asista" = Основной модуль
    "cabinet-company" = Лич.каб сотрудника
    "cabinet-user" = Лич.каб Директора
*/

export const sendYMAnalytics = (name, params) => params?
    window.ym("86267189", "reachGoal", name, params): 
    window.ym("86267189", "reachGoal", name);

// export const createMetrikaStatus = () => {
//   localStorage.setItem("ym_date", Date.now());
// };

// export const checkMetrikaStatus = () => {
//   const status = localStorage.getItem("ym_date");
//   const limit = +status + 86400000
  
//   if (limit > Date.now()) {
//     return false;
//   }
//   return true;
// };
