import React from "react";
import './Hint.css'
const Hint = (props) =>{
    return (
        <span className="Hint" onClick={props.hintOnClick} onMouseEnter={props.setHintShow} onMouseLeave={props.setHintHidden}>
            <span>?</span>
            <div 
                className={`Hint__content ${props.hintState?"Hint__content_active": ""}`}>
                {props.hint}
            </div>
            
        </span>
        
    )
}

export default Hint;