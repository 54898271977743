import React, { useEffect } from "react";
import { useState } from "react";
import "./FormInput.css"

const FormInput = (props)=>{

    const [type, setType] = useState(props.type);
    const [showPass, setShowPass] = useState(false)
    const [alreadySelected, setAlreadySelected] = useState(false)

    const toggleShowPass = () => setShowPass(!showPass)

    useEffect(()=>{
        if(props.type==="password"){
            !showPass?setType("password"):setType("text")
        }
        
    }, [showPass, props.type])

    const searchedValues = props?.searchList?.filter(item => item.value.toLowerCase().includes(props.value.toLowerCase())) || []

    useEffect(() => {
        if(searchedValues.find(item => item.value === props.value)) setAlreadySelected(true)
        else setAlreadySelected(false)
    },[props.value])

    return (
        <div className="FormInput_group">
            <input 
                required={props.required}
                name={props.name} 
                id ={props.name} 
                value={props.value} 
                onChange={props.onChangeHandler} 
                className = "FormInput" 
                autoComplete={props.autoComplete}
                type={type? type: 'text'} 
                placeholder={props.placeholder? props.placeholder : " "}
            />
            {
                (searchedValues.length && props.value && !alreadySelected) ?
                <div className="FormInput_SearchBlock">
                    {searchedValues.map((item, index) => (
                        <div 
                            key={item.id}
                            className="FormInput_SearchItem"
                            onClick={()=>props.onChangeHandler({target:{name: 'problem',value: item.value}})}
                          >
                            {item.value}
                        </div>
                    ))}
                </div>: null
            }
            {props.type==="password"? 
                <div onClick={toggleShowPass} className={`FormInput__password_icon ${showPass?"FormInput__password_icon_show": "FormInput__password_icon_hidden"}`}></div>
                : null
            }
            {props.label? <label htmlFor={props.name} className="FormInput_label">{props.label}</label>: null}
        </div>
    )
}

export default FormInput;