import axios from "../../axiosApi";
import WithLoader from "../../hoc/WithLoader/WithLoader";
import './OpenAccessPage.css';
import {useDispatch, useSelector} from "react-redux";
import { useEffect, useState } from "react";
import LayoutApplicationPage from "../../Components/ApplicationPageComponents/LayoutApplicationPage/LayoutApplicationPage";
import {LogoSidebar} from "../../constants/UI/LogoSidebar";
import {push} from "connected-react-router";
import PreviousApplicationMenu from "../../Components/ApplicationPageComponents/PreviousApplicationMenu/PreviousApplicationMenu";
import {createAccessOpenTask, setApplicationBackInProgress} from "../../Store/ApplicationsReducer/applicationsActions";
import FunctionBlock from "../../Components/UI/FunctionBlock/FunctionBlock";
import OpenAccessForm from "../../Components/OpenAccessPageComponent/OpenAccessForm/OpenAccessForm";
import FormBlock from "../../Components/UI/FormBlock/FormBlock";
import FormInput from "../../Components/UI/Form/FormInput/FormInput";
import FormSelect from "../../Components/UI/Form/FormSelect/FormSelect";
import RadioInput from "../../Components/UI/Form/RadioInput/RadioInput";
import FormBlockTitle from "../../Components/UI/FormBlock/FormBlockTitle/FormBlockTitle";
import Checkbox from "../../Components/UI/Form/Checkbox/Checkbox";
import InputSelect from "../../Components/UI/Form/InputSelect/InputSelect";
import IconCheckbox from "../../Components/UI/Form/IconCheckbox/IconCheckbox";
import WordIcon from '../../assets/icons/mc_word-icon.svg'
import ExcelIcon from '../../assets/icons/mc_excel-icon.svg'
import OutlookIcon from '../../assets/icons/mc_outlook-icon.svg'
import PPIcon from '../../assets/icons/mc_powerpoint-icon.svg'
import TeamsIcon from '../../assets/icons/mc_teams_icon.svg'
import AccessIcon from '../../assets/icons/mc_access-icon.svg'
import VisioIcon from '../../assets/icons/mc_visto-icon.svg'
import ProjectIcon from '../../assets/icons/mc_project-icon.svg'
import AcrobatIcon from '../../assets/icons/adobe_icon.svg'
import ChromeIcon from '../../assets/icons/chrome_icon.svg'
import SkypeIcon from '../../assets/icons/skype_icon.svg'
import WinrarIcon from '../../assets/icons/winrar_icon.svg'
import FormCommentInput from "../../Components/UI/Form/FormCommentInput/FormCommentInput";
import AddInput from "../../Components/OpenAccessPageComponent/AddInput/AddInput";
import Hint from "../../Components/UI/Hint/Hint";
import BlueButton from '../../Components/ApplicationPageComponents/BlueButton/BlueButton'
import { getClientName, saveId, getLastApplication} from "../../Store/ApplicationsReducer/applicationsActions";
import HrModalWindow from "../../Components/OpenAccessPageComponent/HRModalWindow/HrModlaWindow";
import DateInput from "../../Components/UI/Form/DateInput/DateInput";
import RedButton from "../../Components/UI/RedButton/RedButton";
import WhiteButton from "../../Components/ApplicationPageComponents/WhiteButton/WhiteButton";

const OpenAccessPage = (props) => {
    const lastApplication = useSelector(state => state.applications.lastApplication);
    let leftSide;
    const id = props.match.params.id;
    const [hrModal, setHrModal] = useState(false);
    const dispatch = useDispatch();
    const [hintState, setHintState] = useState({
        emailGroup: false,
        createServerAccount: false,
        remoteAccess: false,
        foldersAccess: false
    })
    const [showIcons, setShowIcons] = useState(false)
    const [isExpiredDate, setIsExpiredDate] = useState(false);
    const dateExpirationHandler = (date) => {
        let currMonth = new Date().getMonth() + 1;
        let currDay = new Date().getUTCDate();
        let applicationDateMonth = Number(date.slice(3, 5));
        let applicationDateDay = Number(date.slice(0, 3));
        let diff =
            (currMonth - applicationDateMonth) * 31 + currDay - applicationDateDay;
        if (Math.abs(diff) >= 31) {
            setIsExpiredDate(true);
        }
    };

    const [calendar, setCalendar] = useState(false)
    const [openAccessState, setOpenAccessState] = useState(
        {
            applicationType: "openAccess",
            senderId: id,
            user: {
                fullname: "",
                phone: "",
                job: {
                  title: "",
                  dateEmployment: new Date()
                }
              },
            device: {
                id: "",
                status: ""
              },
            email: {
                value: "",
                createNew: "",
                groups: ""
              },
            access: {
                server: {
                  createAccount: false,
                  remoteAccess: false,
                  folders: [
                    {
                      name: "",
                      access: "Редактор"
                    }
                  ]
                },
                buh_1c: {
                  iconColor: "",
                  path: ""
                },
                corpPhone: {
                  access: "",
                  number: "",
                  needDevice: false
                }
              },
            software: {
                msoffice: {
                    version: "",
                    apps: [],
                  another: ""
                },
                antivirus: {
                  fromList: "",
                  otherName: ""
                },
                goverment: {
                  fromList: [],
                  otherName: ""
                },
                bank: "",
                utilites: {
                  fromList: [],
                  otherName: ""
                }
                },
            comment: ""
        }
    )
    useEffect(()=>{
        if(openAccessState.software.msoffice.version==="Нет необходимости" || openAccessState.software.msoffice.version===""){
            setOpenAccessState({...openAccessState}, openAccessState.software.msoffice.apps=[],)
        }else if(openAccessState.software.msoffice.version!=="Нет необходимости"){
            setOpenAccessState({...openAccessState}, openAccessState.software.msoffice.apps=[
                "Microsoft Word",
                "Microsoft Excel",
                "Microsoft Outlook",  ],)
        }
    }, [openAccessState.software.msoffice.version])
    const normalDate = new Date(openAccessState.user.job.dateEmployment).toLocaleDateString()
    const clientName = useSelector(state=>state.applications.clientName)
    useEffect(() => {
        dispatch(getClientName(id));
        dispatch(getLastApplication(id));
        dispatch(saveId(id));
        if (lastApplication?.date !== null && lastApplication?.date)
            dateExpirationHandler(lastApplication.date);

    }, [dispatch, id]);


    const msOfficeCheckHandler = (appName) =>{
        setOpenAccessState({...openAccessState}, 
            openAccessState.software.msoffice.apps.includes(appName)?
            openAccessState.software.msoffice.apps.splice(openAccessState.software.msoffice.apps.indexOf(appName), 1):
            openAccessState.software.msoffice.apps.push(appName)) 
    }

    const govermentCheckHandler = (appName) =>{
        setOpenAccessState({...openAccessState}, 
            openAccessState.software.goverment.fromList.includes(appName)?
            openAccessState.software.goverment.fromList.splice(openAccessState.software.goverment.fromList.indexOf(appName), 1):
            openAccessState.software.goverment.fromList.push(appName))
    }
    const moreAppsCheckHandler = (appName) =>{
        setOpenAccessState({...openAccessState}, 
            openAccessState.software.utilites.fromList.includes(appName)?
            openAccessState.software.utilites.fromList.splice(openAccessState.software.utilites.fromList.indexOf(appName), 1):
            openAccessState.software.utilites.fromList.push(appName))
    }
    const submitHandler = async (event) => {
        event.preventDefault();
        const data = {...openAccessState};
        data.user.job.dateEmployment = data.user.job.dateEmployment.toLocaleDateString();
        for (let i = 0; i<openAccessState.access.server.folders.length; i++){
            if(!openAccessState.access.server.folders[i].name){
                openAccessState.access.server.folders.splice(i, 1)
            }
        }

        await dispatch(createAccessOpenTask(data, id))
    }
    
    
    const isBackInProgressHandler = () => {
        dispatch(setApplicationBackInProgress(lastApplication.ref));
    }

    if (lastApplication && !!lastApplication.result) {
        leftSide = (
            <>
                <PreviousApplicationMenu
                    title= "Предыдущая заявка"
                    name={lastApplication.topic}
                    date={lastApplication.date}
                    description=""
                    buttonName="Вернуть в работу"
                    clicked={isBackInProgressHandler}
                />
                {
                clientName.access?.includes('employers')?
                <WhiteButton
                    name="Прием или увольнение сотрудника"
                    clicked={()=>{setHrModal(true)}}
                />:null
                }
            </>
        )
    }

    const center =(
        <>
            <OpenAccessForm
                onSubmit={submitHandler}
                title = "Запрос на открытие доступов новому пользователю"
            >
                


                <FormBlock title="Данные нового пользователя">
                    <FormInput 
                        required
                        label = "ФИО нового пользователя *" 
                        name="fullname" 
                        value = {openAccessState.user.fullname}
                        onChangeHandler = {(e)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.user.fullname=e.target.value)
                        }}
                    />
                    <FormInput 
                        required
                        label = "Контактный телефон *" 
                        name="phone" 
                        type='tel'
                        value = {openAccessState.user.phone}
                        onChangeHandler = {(e)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.user.phone=e.target.value)
                        }}
                    />
                    <FormInput 
                        label = "Должность" 
                        name="job" 
                        value = {openAccessState.user.job.title}
                        onChangeHandler = {(e)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.user.job.title=e.target.value)
                        }}
                    />
                    <DateInput
                        valueToLocaleDateString = {normalDate}
                        label = "Дата выхода на работу ДД/ММ/ГГГГ" 
                        showCalendar = {calendar}
                        onClick = {()=>setCalendar(true)}
                        value={openAccessState.user.job.dateEmployment}
                        onChangeHandler={(date)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.user.job.dateEmployment = date)
                            setCalendar(false)
                        
                        }}
                        
                    />
                </FormBlock>
                <FormBlock title="Данные оборудования">
                    <FormSelect 
                        options = {[
                            {value: 'new', label: "Новое оборудование"},
                            {value: 'old', label: "Старое оборудование"}
                        ]}
                        placeholder='Оборудование'
                        onChangeHandler={(e)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.device.status=e.value)
                        }}
                    />
                    {
                        openAccessState.device.status==='old'?
                        <FormInput
                            label = "ID оборудования" 
                            name="id" 
                            value={openAccessState.device.id}
                            onChangeHandler={(e)=>{
                                setOpenAccessState({...openAccessState}, openAccessState.device.id=e.target.value)
                            }}
                        />:null
                    }
                        
                </FormBlock>

                <FormBlock title="Учетная запись почты">
                    <FormInput
                        name = 'email'
                        placeholder = "i.familiya@domain.com"
                        value={(openAccessState.email.value)}
                        onChangeHandler={(e) => {
                            setOpenAccessState({...openAccessState}, openAccessState.email.value = e.target.value) 
                        }}
                    />
                    {
                        (openAccessState.email.value)?
                            <div className="horizontal_radio">
                                <RadioInput
                                    required={openAccessState.email?.value ? true : false}
                                    label='Почту надо создать'
                                    radioGroup = 'needEmail'
                                    isCheckedState={(openAccessState.email.createNew && typeof(openAccessState.email.createNew)==="boolean")}
                                    onChangeHandler = {
                                        ()=>{
                                            setOpenAccessState({...openAccessState}, openAccessState.email.createNew=true)
                                        }
                                    }
                                />
                                <RadioInput
                                    required={openAccessState.email?true:false}
                                    isCheckedState={(!openAccessState.email.createNew && typeof(openAccessState.email.createNew)==="boolean")}
                                    onChangeHandler = {
                                        ()=>{
                                            setOpenAccessState({...openAccessState}, openAccessState.email.createNew=false)
                                        }
                                    }
                                    label='Почта уже существует'
                                    radioGroup = 'needEmail'
                                />
                            </div>:null
                    }
                    
                    {
                        openAccessState.email.createNew?
                        <>
                            <FormBlockTitle 
                                hint='Укажите почтовую группу, в которую необходимо добавить новый почтовый ящик'
                                setHintShow = {()=>{setHintState({...hintState, emailGroup: true})}}
                                setHintHidden={()=>{setHintState({...hintState, emailGroup: false})}}
                                hintState={hintState.emailGroup}
                            >Группа e-mail рассылки</FormBlockTitle>   
                            <FormInput
                                value = {openAccessState.email.groups}
                                onChangeHandler = {(e)=>{
                                    setOpenAccessState({...openAccessState}, openAccessState.email.groups=e.target.value)
                                }}
                                name = 'emailGroup'
                                placeholder = "all@, info@, buhgalteria@"
                            /> 
                        </>:null
                    }
                    
                </FormBlock>
                
                <FormBlock title = "Доступ к серверу">
                    <div className="horizontal__flex">
                        <Checkbox 
                            isCheckedState = {openAccessState.access.server.createAccount}
                            onChangeHandler = {
                                ()=>{
                                    setOpenAccessState({...openAccessState}, openAccessState.access.server.createAccount=!openAccessState.access.server.createAccount)
                                }
                            }
                            label = 'Создать учетную запись на сервере '
                        />
                        <Hint
                            hint='Поставьте галочку, если необходимо создать учетную запись для доступа к удаленному рабочему столу на сервере (RDP/RemoteApp)'
                            setHintShow = {()=>{setHintState({...hintState, createServerAccount: true})}}
                            setHintHidden={()=>{setHintState({...hintState, createServerAccount: false})}}
                            hintState={hintState.createServerAccount}
                        />
                    </div>

                        <Checkbox
                            isCheckedState = {openAccessState.access.server.remoteAccess}
                            onChangeHandler = {
                                ()=>{
                                    setOpenAccessState({...openAccessState}, openAccessState.access.server.remoteAccess=!openAccessState.access.server.remoteAccess)
                                }
                            }
                            label='Выдать внешний доступ к серверу (VPN)'
                        />   
                    
                    <FormBlockTitle
                        hint='По возможности укажите полный путь до нужной папки, например: «server\share\buh2022»'
                        setHintShow = {()=>{setHintState({...hintState, foldersAccess: true})}}
                        setHintHidden={()=>{setHintState({...hintState, foldersAccess: false})}}
                        hintState={hintState.foldersAccess}
                    >Доступ к общим папкам</FormBlockTitle>
                    {openAccessState.access.server.folders.map((folder, index)=>{
                        return(
                            <InputSelect
                                key = {`folder_${index}_access`}
                                name = {`folder_${index}_access`}
                                label= "Название папки"
                                inputValue = {folder.name}
                                inputOnChangeHandler = {(e)=>{
                                    setOpenAccessState({...openAccessState}, openAccessState.access.server.folders[index].name = e.target.value)
                                }}
                                selectValue = {{value: 'Редактор', label: "Редактор"}}
                                selectOnChangeHandler = {(e)=>{
                                    setOpenAccessState({...openAccessState}, openAccessState.access.server.folders[index].access = e.value)
                                }}
                                options = {[
                                    {value: 'Редактор', label: "Редактор"},
                                    {value: 'Просмотр', label: "Просмотр"}
                                ]}
                            />
                        )
                        
                    })
                    }
                    
                    <AddInput 
                        onClickHandler={()=>{
                            setOpenAccessState({...openAccessState}, openAccessState.access.server.folders.push({
                                name: "",
                                access: "Редактор"
                            }))
                        }}
                    />
                </FormBlock>
                <FormBlock title="Доступ к 1С ">
                    <div className="horizontal_radio">
                        <RadioInput
                            isCheckedState={openAccessState.access.buh_1c.iconColor==='yellow'}
                            onChangeHandler = {
                                ()=>{
                                    setOpenAccessState({...openAccessState}, openAccessState.access.buh_1c.iconColor='yellow')
                                }
                            }
                            label='Желтый значок'
                            radioGroup = '1cIcon'
                        />
                        <RadioInput
                            isCheckedState={openAccessState.access.buh_1c.iconColor==='blue'}
                            onChangeHandler = {
                            ()=>{
                                setOpenAccessState({...openAccessState}, openAccessState.access.buh_1c.iconColor='blue')
                            }
                        }
                            label='Синий значок'
                            radioGroup = '1cIcon'
                        />
                    </div>
                    <FormInput
                        value = {openAccessState.access.buh_1c.path}
                        onChangeHandler = {(e)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.access.buh_1c.path = e.target.value)
                        }}
                        name = '1cPath'
                        label = "Где находится 1С?"
                    /> 
                </FormBlock>
                <FormBlock title="Доступ к корпоративной телефонии ">
                    <div className="horizontal_radio">
                        <RadioInput
                            isCheckedState={(openAccessState.access.corpPhone.access && typeof(openAccessState.access.corpPhone.access)==="boolean")}
                            onChangeHandler = {
                                ()=>{
                                    setOpenAccessState({...openAccessState}, openAccessState.access.corpPhone.access=true)
                                }
                            }
                            label='Необходимо'
                            radioGroup = 'corpPhonesAccess'
                        />
                        <RadioInput
                            isCheckedState={(!openAccessState.access.corpPhone.access && typeof(openAccessState.access.corpPhone.access)==="boolean")}
                            onChangeHandler = {
                                ()=>{
                                    setOpenAccessState({...openAccessState}, openAccessState.access.corpPhone.access=false)
                                }
                            }
                            label='Нет необходимости'
                            radioGroup = 'corpPhonesAccess'
                        />
                    </div>
                    {openAccessState.access.corpPhone.access?
                        <>
                            <FormBlockTitle>Присвоить внутренний номер</FormBlockTitle>
                            <FormInput
                                value = {openAccessState.access.corpPhone.number}
                                onChangeHandler = {(e)=>{setOpenAccessState({...openAccessState}, openAccessState.access.corpPhone.number=e.target.value)}}
                                name = 'inlinePhone'
                                placeholder = "123"
                            /> 
                            <Checkbox
                                isCheckedState = {openAccessState.access.corpPhone.needDevice}
                                 onChangeHandler = {
                                    ()=>{
                                        setOpenAccessState({...openAccessState}, openAccessState.access.corpPhone.needDevice = !openAccessState.access.corpPhone.needDevice)
                                    }
                                }
                                label='Установить новый телефонный аппарат'
                            />
                        </>: null
                    }
                                        
                </FormBlock>

                <FormBlock title="Microsoft Office">
                    <FormSelect
                        placeholder="Выберите версию"
                        onChangeHandler={(e)=>{
                            setShowIcons(true)
                            setOpenAccessState({...openAccessState}, openAccessState.software.msoffice.version =e.value)
                        }}
                        value = {{value: 'Нет необходимости', label: "Выберите версию"}}
                        options={
                            [
                                {value: 'Microsoft Office 360', label: "Microsoft Office 360"},
                                {value: 'Office 2019', label: "Office 2019"},
                                {value: 'Office 2016', label: "Office 2016"},
                                {value: 'Office 2013', label: "Office 2013"},
                                {value: 'Office 2011', label: "Office 2011"},
                                {value: 'Я не знаю', label: "Я не знаю"},
                                {value: 'Нет необходимости', label: "Нет необходимости"}
                            ]
                        }
                    />

                    {(showIcons && openAccessState.software.msoffice.version!=="Нет необходимости")?
                        <div className="flex_wrap">
                            <IconCheckbox
                                icon={WordIcon}
                                label='Microsoft Word'
                                isCheckedState = {openAccessState.software.msoffice.apps.includes('Microsoft Word')}
                                onChangeHandler = {()=>{msOfficeCheckHandler('Microsoft Word')}}
                            />
                            <IconCheckbox
                                icon={ExcelIcon}
                                label='Microsoft Excel'
                                isCheckedState = {openAccessState.software.msoffice.apps.includes('Microsoft Excel')}
                                onChangeHandler = {()=>{msOfficeCheckHandler('Microsoft Excel')}}
                            />
                            <IconCheckbox
                                icon={OutlookIcon}
                                label='Microsoft Outlook'
                                isCheckedState = {openAccessState.software.msoffice.apps.includes('Microsoft Outlook')}
                                onChangeHandler = {()=>{msOfficeCheckHandler('Microsoft Outlook')}}
                            />
                            <IconCheckbox
                                icon={PPIcon}
                                label='Microsoft PowerPoint'
                                isCheckedState = {openAccessState.software.msoffice.apps.includes('Microsoft PowerPoint')}
                                onChangeHandler = {()=>{msOfficeCheckHandler('Microsoft PowerPoint')}}
                            />
                            <IconCheckbox
                                icon={TeamsIcon}
                                label='Microsoft Teams'
                                isCheckedState = {openAccessState.software.msoffice.apps.includes('Microsoft Teams')}
                                onChangeHandler = {()=>{msOfficeCheckHandler('Microsoft Teams')}}
                            />
                            <IconCheckbox
                                icon={AccessIcon}
                                label='Microsoft Access'
                                isCheckedState = {openAccessState.software.msoffice.apps.includes('Microsoft Access')}
                                onChangeHandler = {()=>{msOfficeCheckHandler('Microsoft Access')}}
                            />
                            <IconCheckbox
                                icon={VisioIcon}
                                label='Microsoft Visio'
                                isCheckedState = {openAccessState.software.msoffice.apps.includes('Microsoft Visio')}
                                onChangeHandler = {()=>{msOfficeCheckHandler('Microsoft Visio')}}
                            />
                            <IconCheckbox
                                icon={ProjectIcon}
                                label='Microsoft Project'
                                isCheckedState = {openAccessState.software.msoffice.apps.includes('Microsoft Project')}
                                onChangeHandler = {()=>{msOfficeCheckHandler('Microsoft Project')}}
                            />
                        </div>:null
                    }
                    {(showIcons && openAccessState.software.msoffice.version!=="Нет необходимости")?
                        <FormInput
                            value = {openAccessState.software.msoffice.another}
                            onChangeHandler = {(e)=>{
                                setOpenAccessState({...openAccessState}, openAccessState.software.msoffice.another=e.target.value)
                            }}
                            label= 'Дополнительно'
                            name='anotherOffice'
                        />:null
                    }
                </FormBlock>

                <FormBlock title="Антивирус">
                    <FormSelect
                        placeholder="Выбрать из списка"
                        onChangeHandler={(e)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.software.antivirus.fromList =e.value)
                        }}
                        options={
                            [
                                {value: 'Kaspersky', label: "Kaspersky"},
                                {value: 'Avast', label: "Avast"},
                                {value: 'Dr.Web', label: "Dr.Web"},
                                {value: 'Eset', label: "Eset"},
                                {value: 'Другое', label: "Другое"}
                            ]
                        }
                    />
                    {openAccessState.software.antivirus.fromList==='Другое'?
                    <FormInput
                        value = {openAccessState.software.antivirus.otherName}
                        onChangeHandler = {(e)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.software.antivirus.otherName=e.target.value)
                        }}
                        label= 'Дополнительно'
                        name='anotherAtnivirus'
                    /> : null
                }
                        
                </FormBlock>
                <FormBlock title="Налоговое ПО и госпорталы">
                    <div className="horizontal__checkboxes">
                        <Checkbox
                            isCheckedState = {openAccessState.software.goverment.fromList.includes('СОНО')}
                            onChangeHandler = {()=>{govermentCheckHandler('СОНО')}}
                            label='СОНО'
                        />
                        <Checkbox
                            isCheckedState = {openAccessState.software.goverment.fromList.includes('Кабинет налогоплательщика')}
                            onChangeHandler = {()=>{govermentCheckHandler('Кабинет налогоплательщика')}}
                            label='Кабинет налогоплательщика'
                        />
                        <Checkbox
                            isCheckedState = {openAccessState.software.goverment.fromList.includes('Другой портал')}
                            onChangeHandler = {()=>{govermentCheckHandler('Другой портал')}}
                            label='Другой портал'
                        />
                    </div>
                    {
                        openAccessState.software.goverment.fromList.includes('Другой портал')?
                        <FormInput
                        value = {openAccessState.software.goverment.otherName}
                        onChangeHandler = {(e)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.software.goverment.otherName=e.target.value)
                        }}
                        label= 'Дополнительно'
                        name='anotherGoverment'
                        />:null
                    }
                </FormBlock>
                <FormBlock title="Банковские программы">
                    
                    <FormInput
                        value = {openAccessState.software.bank}
                        onChangeHandler = {(e)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.software.bank=e.target.value)
                        }}
                        label= 'Название банка'
                        name='bankName'
                    />
                </FormBlock>
                <FormBlock title="Дополнительные программы ">
                <div className="horizontal__checkboxes">
                        <IconCheckbox
                            icon={AcrobatIcon}
                            label='Adobe Acrobat Pro 11'
                            isCheckedState = {openAccessState.software.utilites.fromList.includes('Adobe Acrobat Pro 11')}
                            onChangeHandler = {()=>{moreAppsCheckHandler('Adobe Acrobat Pro 11')}}
                        />
                        <IconCheckbox
                            icon={ChromeIcon}
                            label='Chrome'
                            isCheckedState = {openAccessState.software.utilites.fromList.includes('Chrome')}
                            onChangeHandler = {()=>{moreAppsCheckHandler('Chrome')}}
                        />
                        <IconCheckbox
                            icon={SkypeIcon}
                            label='Skype'
                            isCheckedState = {openAccessState.software.utilites.fromList.includes('Skype')}
                            onChangeHandler = {()=>{moreAppsCheckHandler('Skype')}}
                        />
                        <IconCheckbox
                            icon={WinrarIcon}
                            label='WinRAR'
                            isCheckedState = {openAccessState.software.utilites.fromList.includes('WinRAR')}
                            onChangeHandler = {()=>{moreAppsCheckHandler('WinRAR')}}
                        />
                    </div>
                    <FormInput 
                        label = "Другое" 
                        name="moreApps" 
                        value = {openAccessState.software.utilites.otherName}
                        onChangeHandler = {(e)=>{
                            setOpenAccessState({...openAccessState}, openAccessState.software.utilites.otherName=e.target.value)
                        }}
                    />
                </FormBlock>
                <FormBlock title="Дополнительный комментарий">
                    <div className="ResultBlock">
                    <FormCommentInput
                        placeholder="Дополните заявку, например: сотрудник выходит через неделю, нужно, чтобы к этому моменту всё было готово"
                        name = 'comment1'
                        value = {openAccessState.comment}
                        onChangeHandler = {e => {setOpenAccessState({...openAccessState, comment: e.target.value})}}
                    />
                    <BlueButton
                        name='Отправить заявку'
                    />
                    </div>
                </FormBlock>
            </OpenAccessForm>
            {
                    hrModal?
                    <HrModalWindow 
                        bgOnClick = {()=>{setHrModal(false)}}
                        modalStatus = {hrModal}
                        id={id}
                    />
                    :null
                }
        </>
    )
    

    const left = (
        <>
            {leftSide}
        </>
    )

    return (
        <div>
            <LogoSidebar/>
            <LayoutApplicationPage createNewApplication={()=>{dispatch(push(`/prelogin/${id}`))}} left={left} center={center}> </LayoutApplicationPage>
        </div>
    )
}

export default WithLoader(OpenAccessPage, axios);