import React from "react";
import './ITSGContact.css';

export const ITSGContact = () => {

    const date = new Date().getFullYear();
    const goToItsupport = ()=>{
        window.location.assign('https://itsupport.kz')
    }
    return (
        <div className="about">
            <p onClick = {goToItsupport} className="website">itsupport.kz</p>
            <p className="chronology">IT Support group 2006-{date}</p>
            <div onClick = {goToItsupport} className="itsg-logo"></div>
        </div>
    )
}