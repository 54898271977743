import {LogoSidebar} from "../../../../constants/UI/LogoSidebar";
import './FAQFooterWrapper.css';

const FAQFooterWrapper = (props) => {

    const faqHideHandler = () => {
        props.hideClass('FAQ-container_inner');
        setTimeout(() => {
            props.show(false);
        }, 250);
    }

    return (
        <div className='FAQ-right_block'>
            <LogoSidebar faq = {true}/>
            <div className='faq-return' onClick={faqHideHandler}>
                <p className='prev-go-icon'>&lt;</p>
                <p className='prev-go-text'>НАЗАД</p>
            </div>
        </div>
    )
}

export default FAQFooterWrapper;