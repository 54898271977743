import React, { useState } from "react";
import "./FullApplication.css"
export default function FullApplication(props){
    const [showQuestionComment, setShowQuestionComment] = useState(false);
    const [showQuestionApplicationId, setShowQuestionApplicationId] = useState(false);
    const [showQuestionSpecialist, setShowQuestionSpecialist] = useState(false);
    const [isFileImageModal, setIsFileImageModal] = useState(false);
    const hoverShowQuestionComment = () => {
        setShowQuestionComment(true);
    }
    const hoverHideQuestionComment = () => {
        setShowQuestionComment(false);
    }
    const hoverShowQuestionApplicationId = () => {
        setShowQuestionApplicationId(true);
    }
    const hoverHideQuestionApplicationId = () => {
        setShowQuestionApplicationId(false);
    }
    const hoverShowQuestionSpecialist = () => {
        setShowQuestionSpecialist(true);
    }
    const hoverHideQuestionSpecialist = () => {
        setShowQuestionSpecialist(false);
    }
    return (
        <>
            <div className="FullApplicationInfo">
                <div className="FullApplicationInfo__header">
                    <div onClick={props.clickToClose} className="FullApplicationInfo__close" />
                    <div className="FullApplicationInfo__statusDateBlock">
                        <p className="ResultsItemWorker__text">{props.date}</p>
                        <p className={`FullApplicationInfo__status ${props.status==="Завершено"?"FullApplication__done": ""}`}>{props.status}</p>
                    </div>

                    <div className="FullApplicationInfo__ApplicationIdBlock">
                        <p className="ResultsItemWorker__text--red">Номер заявки:</p>
                        <div className="ResultsItemWorker__applicationId">
                            <p className="ResultsItemWorker__text--red  ResultsItemWorker__text--applicationId">{props.ApplicationId}</p>
                            <div className="FullApplicationInfo__question--icon" onMouseEnter={hoverShowQuestionApplicationId} onMouseLeave={hoverHideQuestionApplicationId}>
                                <div style={showQuestionApplicationId ? {"display" : "block"} : {"display" : "none"}} className="FullApplicationInfo__comment--modal">
                                    <p className="FullApplicationInfo__comment--modal-text">Регистрационный номер заявки</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="FullApplicationInfo__workerIdBlock">
                        <div className="FullApplicationInfo__workerIdBlock--imageBlock">
                            <div className="FullApplicationInfo__image" style={{background: `#E34A4E  url(data:image/jpg;base64,${props.specialistPhoto})center / cover no-repeat`}} />

                            <div className="FullApplicationInfo__workerIdAndQuestionBlock">
                                <p className="FullApplicationInfo__text">{props.specialistName}</p>
                                <div className="FullApplicationInfo__specialistIdBlock">
                                    <p className="FullApplicationInfo__id">{props.specialistId}</p>
                                    <div className="FullApplicationInfo__question--icon" onMouseEnter={hoverShowQuestionSpecialist} onMouseLeave={hoverHideQuestionSpecialist}>
                                        <div style={showQuestionSpecialist ? {"display" : "block"} : {"display" : "none"}} className="FullApplicationInfo__comment--modal">
                                            <p className="FullApplicationInfo__comment--modal-text">У каждого специалиста есть уникальный IT-номер, во-первых это красиво, а во-вторых он совпадает с внутренним номером телефона. Вы всегда сможете позвонить напрямую исполнителю заявки или написать электронное письмо.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="FullApplicationInfo__workerIdBlock">
                        <p className="FullApplicationInfo__text">{props.workerName}</p>
                        <p className="FullApplicationInfo__id">{props.workerId}</p>
                    </div>


                    <div className="FullApplicationInfo__likeAndCommentBlock">

                        <div className="FullApplicationInfo__likeAndCommentBlock--icons">
                            <div className={`FullApplicationInfo__icon--widthLikeDislike FullApplicationInfo__icon--${props.classLikeDislike}`} />
                            <div className={`${props.isComment ? "FullApplicationInfo__comment" : null} FullApplicationInfo__comment--width`} onMouseEnter={props.isComment ? hoverShowQuestionComment: null} onMouseLeave={props.isComment ? hoverHideQuestionComment : null}>
                                <div style={showQuestionComment ? {"display" : "block"} : {"display" : "none"}} className="FullApplicationInfo__comment--modal">
                                    <p className="FullApplicationInfo__comment--modal-text">{props.commentMessage}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={props.fileImage ? "FullApplicationInfo__content" : "FullApplicationInfo__content--noFile"}>
                    <div className={props.fileImage ? "FullApplicationInfo__content--left" : "FullApplicationInfo__content--left FullApplicationInfo__content--left--noFile"}>
                        <h2 className="FullApplicationInfo__content__title">Детали заявки</h2>
                        <div className="FullApplicationInfo__content__head">
                            <div className="FullApplicationInfo__content__head--sides">
                                <p className="FullApplicationInfo__content__text--title">Ответственный отдел</p>
                                <p className="FullApplicationInfo__content__text">{props.department}</p>
                            </div>
                            <div className="FullApplicationInfo__content__head--sides">
                                <p className="FullApplicationInfo__content__text--title">Тема</p>
                                <p className="FullApplicationInfo__content__text">{props.subject}</p>
                            </div>
                        </div>
                        {props.applicationInfo ? <p className="FullApplicationInfo__content__text--title">Подробности</p> : null}
                        <div>
                            {props.applicationInfo}
                        </div>


                        <p className="FullApplicationInfo__content__text--title FullApplicationInfo__content__text--title--wide">{(props.status === "завершено" || props.status === "пауза" || props.status === "пауза" || props.status === "в работе" || props.status === "запланировано") ? "Результат" : props.status === "отменено" ? "Причины отмены заявки" : ""}</p>
                        <div >
                            {props.result}
                        </div>

                    </div>

                    {props.fileImage ? 
                    <div className="FullApplicationInfo__content--right">
                        <h2 className="FullApplicationInfo__content--title">
                            Прикрепленные файлы:
                        </h2>
                        {
                            props.fileImage.map(image => {
                                return(
                                    <img src={image} alt="ApplicationImage"/>
                                )
                            })
                        }
                        
                    </div> : null}

                </div>

            </div>
            {/* {isFileImageModal ? <div onClick={toggleModalImage} className="FullApplicationInfo__fileImage--modal">
                <div className="FullApplicationInfo__fileImage--modalCanvas">
                    <img className="FullApplicationInfo__fileImage" src={currentFileImage} alt={subject}/>
                </div>
            </div> : null} */}
        </>
    )
}