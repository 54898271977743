
import {CONFIG} from "../../../../constants/backendUrl"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
const chatApiUrl = CONFIG.chatApi;
const host = CONFIG.appDomain;
const publicVapidKey = CONFIG.publicVapidKey
const workerUrl = `${CONFIG.appDomain}/worker.js`
const scope = `${CONFIG.appDomain}/`;

export const reqForNotif = async (auth, setPushWorking) => {
    localStorage.setItem("reqForNotificationsSent", "true")
    if ('serviceWorker' in navigator) {
        try {
          const registration = await navigator.serviceWorker.getRegistration();
          console.log(registration);
          if (registration) {
            console.log('Сервис-воркер зарегистрирован.');
            console.log('Статус сервис-воркера:', registration.active ? 'активен' : 'неактивен');
            
            if (Notification.permission !== 'granted') {
                Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    subscribeUserToPushWithoutModal(auth, setPushWorking);
                    console.log('Разрешение на отправку уведомлений получено.');
                } else {
                    console.log('Невозможно получить разрешение на отправку уведомлений.');
                }
              });
            } else {
              console.log('Уведомления в браузере уже разрешены');
              subscribeUserToPushWithoutModal(auth, setPushWorking);
            }
          } else {
            console.log('Сервис-воркер не зарегистрирован');
          }
        } catch (error) {
          console.error('Ошибка при получении информации о сервис-воркере:', error);
        }
      } else {
        console.log('Ваш браузер не поддерживает сервис-воркеры');
      }
}
export const subscribeUserToPushWithoutModal = async (auth, setPushWorking) => {
    try {
        if ('serviceWorker' in navigator) {
            const register = await navigator.serviceWorker.register(workerUrl, {
                scope: scope
            });
            const newSubscription = await register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
            });
            await fetch(chatApiUrl + '/webpush/subscribe', {
                method: "POST",
                body: JSON.stringify(newSubscription),
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + auth
                }
            });
            setPushWorking(true)
        } else {
            setPushWorking(false)
            console.error('Сервис-воркеры не поддерживаются в этом браузере.');
            
        }
    } catch (error) {
        console.error('Ошибка при регистрации сервис-воркера:', error);
        
    }
}
// export const subscribeWithoutModal = (auth, setPushWorking) => {
//     if (Notification.permission !== 'granted') {
//         Notification.requestPermission().then((permission) => {
//             if (permission === 'granted') {
//                 subscribeUserToPush(auth, setPushWorking);
//                 console.log('Разрешение на отправку уведомлений получено.');
//             } else {
//                 console.log('Невозможно получить разрешение на отправку уведомлений.');
//             }
//         })
//     } else {
//         console.log('Уведомления в браузере уже разрешены');
//         subscribeUserToPush(auth, setPushWorking);
//     }
// }
export const subscribe = (auth, setPushWorking) => {
    if (Notification.permission !== 'granted') {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                subscribeUserToPush(auth, setPushWorking);
                console.log('Разрешение на отправку уведомлений получено.');
            } else {
                console.log('Невозможно получить разрешение на отправку уведомлений.');
                MySwal.fire({
                    title: 'Невозможно получить разрешение на отправку уведомлений.',
                    text: 'Пожалуйста, разрешите отправку уведомлений в настройках браузера.',
                    icon: 'error',
                    confirmButtonText: 'Ок'
                })
            }
        })
    } else {
        console.log('Уведомления в браузере уже разрешены');
        subscribeUserToPush(auth, setPushWorking);
    }
}

export const subscribeUserToPush = async (auth, setPushWorking) => {
    try {
        if ('serviceWorker' in navigator) {
            const register = await navigator.serviceWorker.register(workerUrl, {
                scope: scope
            });
            const newSubscription = await register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
            });
            await fetch(chatApiUrl + '/webpush/subscribe', {
                method: "POST",
                body: JSON.stringify(newSubscription),
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + auth
                }
            });
            setPushWorking(true)
            MySwal.fire({
                title: 'Уведомления включены.',
                text: 'Теперь вы будете получать уведомления о новых сообщениях.',
                icon: 'success',
                confirmButtonText: 'Ок'
            })
            
        } else {
            setPushWorking(false)
            console.error('Сервис-воркеры не поддерживаются в этом браузере.');
            MySwal.fire({
                title: 'Сервис-воркеры не поддерживаются в этом браузере.',
                text: 'Пожалуйста, используйте другой браузер.',
                icon: 'error',
                confirmButtonText: 'Ок'
            })
        }
    } catch (error) {
        console.error('Ошибка при регистрации сервис-воркера:', error);
        MySwal.fire({
            title: 'Ошибка при регистрации сервис-воркера.',
            text: 'Пожалуйста, используйте другой браузер.',
            icon: 'error',
            confirmButtonText: 'Ок'
        })
    }
}
export const unsubscribeUserFromPush = async (auth, setPushWorking) => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration(scope).then((registration) => {
            // Получаем текущую подписку
            registration.pushManager.getSubscription().then((subscription) => {
                if (subscription) {
                    // Отменяем подписку на уведомления
                    subscription.unsubscribe().then((successful) => {
                        if (successful) {
                            console.log('Подписка на уведомления отменена.');
                            fetch(chatApiUrl + '/webpush/unsubscribe', {
                                method: "POST",
                                headers: {
                                    "content-type": "application/json",
                                    "Authorization": "Bearer " + auth
                                },
                                body: JSON.stringify(subscription)
                            });
                            MySwal.fire({
                                title: 'Подписка на уведомления отменена.',
                                text: 'Теперь вы не будете получать уведомления о новых сообщениях.',
                                icon: 'success',
                                confirmButtonText: 'Ок'
                            })
                            setPushWorking(false)
                        } else {
                            console.error('Не удалось отменить подписку на уведомления.');
                            MySwal.fire({
                                title: 'Не удалось отменить подписку на уведомления.',
                                text: 'Пожалуйста, попробуйте позже.',
                                icon: 'error',
                                confirmButtonText: 'Ок'
                            })
                        }
                    });
                } else {
                    console.log('Подписка на уведомления не найдена.');
                    MySwal.fire({
                        title: 'Подписка на уведомления не найдена.',
                        text: 'Вы можете подписаться на уведомления',
                        icon: 'error',
                        confirmButtonText: 'Ок'
                    })
                }
            });
        });
    } else {
        console.error('Сервис-воркеры не поддерживаются в этом браузере.');
        MySwal.fire({
            title: 'Сервис-воркеры не поддерживаются в этом браузере.',
            text: 'Пожалуйста, используйте другой браузер.',
            icon: 'error',
            confirmButtonText: 'Ок'
        })
    }
}
export const unsubscribe = (auth, setPushWorking) => {
    unsubscribeUserFromPush(auth, setPushWorking);
}
export const saveWorker =()=> {
    localStorage.setItem('worker', new Date().getTime())
}
export const needUpdate = () => {
    const lastUpdate = localStorage.getItem('worker')
    if (!lastUpdate) return true
    const now = new Date().getTime()
    return now - lastUpdate > 1000 * 60 * 60 * 24
}
export const urlBase64ToUint8Array=(base64String)=> {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export const checkSubscribtion = async (setPushWorking) => {
    const register = await navigator.serviceWorker.register(workerUrl, {
        scope: scope
    });
    const subscription = await register.pushManager.getSubscription();
    if (subscription) {
        setPushWorking(true)
    }else {
        setPushWorking(false)
    }
}
export const unregisterServiceWorkers = async (scope) => {
    if ('serviceWorker' in navigator) {
      try {
        const registrations = await navigator.serviceWorker.getRegistrations();
  
        const filteredRegistrations = registrations.filter((registration) => {
          return registration.scope.startsWith(scope);
        });
  
        if (filteredRegistrations.length === 0) {
          console.log('На указанной области нет активных сервис-воркеров.');
          return;
        }
  
        console.log(`Найдено ${filteredRegistrations.length} активных сервис-воркеров на области, начинающейся с ${scope}.`);
  
        for (const registration of filteredRegistrations) {
          await registration.unregister();
          console.log(`Сервис-воркер на области ${registration.scope} успешно удален.`);
        }
      } catch (error) {
        console.error('Ошибка при удалении сервис-воркеров:', error);
      }
    } else {
      console.log('Ваш браузер не поддерживает сервис-воркеры.');
    }
  };
export const notificationsOn = (auth, setPushWorking) => {
    const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window
    if (isSupported){
        subscribe(auth, setPushWorking)
    }
    else
        MySwal.fire({
            icon: 'error',
            title: 'Уведомления не поддерживаются',
            text: 'Ваш браузер не поддерживает уведомления'
        })
}
export const notificationsOff = (auth, setPushWorking) => {
    const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window
    if (isSupported){
        unsubscribe(auth, setPushWorking)
    }
        
    else
        MySwal.fire({
            icon: 'error',
            title: 'Уведомления не поддерживаются',
            text: 'Ваш браузер не поддерживает уведомления'
        })
}
