import { useDispatch } from 'react-redux';
import './ITSupport.css';
import {push} from 'connected-react-router';
export const ITSupport = (props) => {
    const dispatch = useDispatch();
    return (
        <div style={props.clientAccess?.itsupportClient ? {"backgroundColor": "#fff", "zIndex": "4", "opacity": "100%", "borderRadius": "8px"} : null}>
            <div className="ITSupport" >
                <div className="ITSupport-logo"/>
                <div className="classification">
                    <p>Оргтехника</p>
                    <p>Компьютеры</p>
                    <p>Локальная сеть</p>
                    <p>Серверы</p>
                </div>
            </div>
            <div className="fill-itsg" onClick={async ()=>{await dispatch(push(`/application/${props.supportID}/newticket`))}}>
                <div  className="invHoverLogo-itsg"/>
            </div>
        </div>
    )
}