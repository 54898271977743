import React, { useState, useEffect } from 'react';
import './BIWorker.css';
import {useDispatch, useSelector} from "react-redux";
import { setBiActiveFilters } from '../../../../Store/BIReducer/biActions';


const BIWorker = (props) => {
   const dispatch = useDispatch();
   
   const [showResults, setShowResults] = useState(true);
   const workers = useSelector(state => state.bi.workerfilters);
   const biActiveFilters = useSelector(state => state.bi.activeFilters);
   const [inputState, setInputState] = useState(biActiveFilters?.activeFilters?.employee || "");
   // введенные данные с каждым кликом отправляют запрос и получают данные из списка сотрудников компании
    const inputChange = (event) => {
        setInputState(event.target.value);
        setShowResults(true);
    }
    const hideResults = () => {
        setShowResults(false);
    };
    const getResultValue = (event, uuid) => {
        
        dispatch(setBiActiveFilters({...biActiveFilters, employee_uuid: uuid}))
        props.close();
    }

    
    const [filtered, setFiltered] = useState([]);
    let allWorkerSearchResults;
    useEffect(() => {
        setFiltered(workers?.filter(el => el.name.toLowerCase().includes(inputState.toLowerCase())));
    }, [inputState]);
    useEffect(()=>{
        
        if(biActiveFilters.employee_uuid){
            for(let i =0;i<workers.length; i++){
                if(workers[i].uuid===biActiveFilters.employee_uuid){
                   setInputState(workers[i].name) 
                }
            }
            
        }
    }, [biActiveFilters])
    if (filtered.length !== 0) {
        allWorkerSearchResults = (<div className="BIWorker__results">
            {filtered.map((el, i) => {
                return <p data-name={el.name} key={i} onClick={(event) => {getResultValue(event, el.uuid)}} className="BIWorker__resultsItem">{el.name}</p>
             })}
        </div>
        )}

    return (
        <>
        <div onClick={props.close} className="BIWorker__bg" />
            <div className="BIWorker">
                <input value={inputState} onChange={(event) => {inputChange(event)}} className="BIWorker__input" type="text" placeholder="Введите имя сотрудника" />
                <div className="BIWorker__arrowBtn" onClick={hideResults} />
                {showResults ? allWorkerSearchResults : null}
            </div>
        </>
    )
}


export default BIWorker;