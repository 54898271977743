import React, {useEffect, useState} from 'react';
import './LayoutSearchResults.css';
import {push} from 'connected-react-router';
import {useDispatch, useSelector} from "react-redux";
import ModalWindow from "../../UI/ModalWindow/ModalWindow"
import {
    changeNumber, changeRegion, clearMyInterval,
    getExcellFile,
    initFilters, inputFilterDateFrom, inputFilterDateTo, isFilterDateActive,
    setActiveFilters,
    setActivePage
} from "../../../Store/ApplicationsReducer/applicationsActions";
import {ReactComponent as TableActive} from "../../../assets/icons/table-icon-active.svg";
import {ReactComponent as AnalyticsInactive} from "../../../assets/icons/analytics-icon-inactive.svg";
import {ReactComponent as TableInactive} from "../../../assets/icons/search-icon_inactive.svg";
import {ReactComponent as AnalyticsActive} from "../../../assets/icons/statistics-icon_active.svg";
import {useLocation} from "react-router-dom";
import moment from 'moment';
import WhiteButton from '../../ApplicationPageComponents/WhiteButton/WhiteButton';


const LayoutSearchResults = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const excelAccess = useSelector(state => state.general.excellAccess)
    const biDate = useSelector(state => state.bi.date)
    const company = useSelector(state => state.company.companyData);
    const userHash = useSelector(state => state.applications.data.hash);
    const applications = useSelector(state => state.applications.applications);
    const [isBi, setIsBi] = useState(false) 
    const [modalStatus, setModalStatus] = useState(false) 
    const [active, setActive] = useState(false);
    const reqData = useSelector(state => state.applications.data);
    const dateFromState = useSelector(state => state.applications.data.filter.date);
    const number = useSelector(state => state.applications.data.filter.number);
    const goToApplications = () => {
        if (company.employee) {
            dispatch(push(`/application/${company.employee.id}/newticket`));
        }
        // else if (company.director) {
        //     dispatch(push(`/application/${company.director.id}/new`));
        // }
    }
    useEffect(() => {
        dispatch(clearMyInterval())
        
    }, [dispatch])
    const inputValue = (event) => {
        dispatch(changeNumber(event.target.value));
        dispatch(setActivePage(1));
    }
    const initFiltersHandler = async () => {
        localStorage.removeItem('employeeFilterName');
        await dispatch(initFilters());
        await dispatch(isFilterDateActive(false));
        await dispatch(inputFilterDateFrom(""));
        await dispatch(inputFilterDateTo(""));
        await dispatch(changeRegion([]));
        await dispatch(setActiveFilters([]));
        await dispatch(setActivePage(1))
        const el = document.getElementsByClassName('LayoutSearchResults__btnDate');
        for (let i = 0; i < el.length; i++) {
            el[i].style.color = '#E34A4E';
            el[i].style.background = 'white';
        }
    }

    useEffect(() => {
        if (location.pathname === '/directors-cabinet') {
            setActive(true);
            setIsBi(true);
            let today = moment().format("DD.MM.YYYY");
            let chosenDate;
            chosenDate = moment().subtract(1, 'months').format("DD.MM.YYYY");
                
            dispatch(inputFilterDateFrom(chosenDate));
            dispatch(inputFilterDateTo(today));
        }else{
            let today = moment().format("DD.MM.YYYY");
            let chosenDate;
            chosenDate = moment().subtract(1, 'months').format("DD.MM.YYYY");
            dispatch(inputFilterDateFrom(chosenDate));
            dispatch(inputFilterDateTo(today));
            setIsBi(false)
        }
    }, [location.pathname]);

    const redirectToSearch = () => {
        dispatch(push('/search'));
        setActive(false);
    }

    const redirectToStatistics = () => {
        setActive(true);
        dispatch(push('/directors-cabinet'));
    }
    const downloadExcell = () => {
        dispatch(getExcellFile({data: {...reqData, company: "itsupport"}, userHash: userHash}))
    }
    return (
        <div className="LayoutSearchResults" id="BI_layout">
            <header className="LayoutSearchResults__header">
                <div onClick={goToApplications}
                     className={`LayoutSearchResults__logo ${company.director ? "LayoutSearchResults__logo--inactive" : ""}`}/>
                <div className="LayoutSearchResults__contactPhoneBlock">
                    <h2 className="LayoutSearchResults__boldText">+7 727 390 11 12</h2>
                    <p className="LayoutSearchResults__headerHelperText LayoutSearchResults__headerHelperText--left">служба
                        поддержки пользователей</p>
                </div>
                <div className="LayoutSearchResults__idHeaderBlock">
                    <h2 className="LayoutSearchResults__boldText LayoutSearchResults__boldText--center">{props.equipmentId}</h2>
                    <p className="LayoutSearchResults__headerHelperText">ID оборудования</p>
                </div>
                {props.workerName ? <div className="LayoutSearchResults__NameBlock">
                    <h2 className="LayoutSearchResults__bigNotBoldText">{props.workerName}</h2>
                    {!company.director ?
                        <p className="LayoutSearchResults__headerHelperText">контактное лицо</p> : null}
                </div> : null}
                <div className='LayoutSearchResults__TabBlock'>
                    <div
                        className={`LayoutSearchResults__TabBlock_TableIcon ${active ? 'Inactive__Icon' : 'Active__Icon'}`}>
                        {active ? <TableInactive onClick={redirectToSearch}/> :
                            <TableActive onClick={redirectToSearch}/>}
                    </div> 
                    {
                        company.director?
                        <div
                            className={`LayoutSearchResults__TabBlock_AnalyticsIcon ${active ? 'Active__Icon' : 'Inactive__Icon'}`}>
                            {active ? 
                                <AnalyticsActive onClick={redirectToStatistics}/> :
                                <AnalyticsInactive onClick={redirectToStatistics}/>}
                        </div>
                        :null
                    }
                    
                </div>
                <div className="LayoutSearchResults__CompanyBlock">
                    <h2 className="LayoutSearchResults__bigNotBoldText">{props.companyName}</h2>
                    <p className="LayoutSearchResults__headerHelperText">наименование организации</p>
                </div>
                <div className="LayoutSearchResults__companyLogo"
                     style={{background: `url(data:image/jpg;base64,${props.companyLogo})center / contain no-repeat`}}/>
            </header>

            <div className="LayoutSearchResults__dateEngine">
                <div className="LayoutSearchResults__buttonsBlock">
                    <div onClick={e => props.dateEngineClick(e, 'today')} className="LayoutSearchResults__btnDate LayoutSearchResults__btnDate--left">Сегодня</div>
                    <div onClick={e => props.dateEngineClick(e, 'yesterday')} className="LayoutSearchResults__btnDate">Вчера</div>
                    <div onClick={e => props.dateEngineClick(e, 'week')} className="LayoutSearchResults__btnDate">Неделя</div>
                    <div onClick={e => props.dateEngineClick(e, 'month')} className={`LayoutSearchResults__btnDate ${(biDate?.type==="month" && location.pathname==="/directors-cabinet") || dateFromState.from === moment().subtract(1, 'months').format("DD.MM.YYYY")?"LayoutSearchResults__btnDate_active": ""}`}>Месяц</div>
                    <div onClick={e => props.dateEngineClick(e, 'quarter')} className="LayoutSearchResults__btnDate">Квартал</div>
                    <div onClick={e => props.dateEngineClick(e, 'half_year')} className="LayoutSearchResults__btnDate">Полугодие</div>
                    <div onClick={e => props.dateEngineClick(e, 'custom')} className="LayoutSearchResults__btnDate LayoutSearchResults__btnDate--right">Период</div>
                </div>

                <div className="LayoutSearchResults__idDateBlock">
                    {
                        !isBi?
                        <>

                            <input placeholder="ПОИСК" value={number} ref={props.searchRef} disabled={props.searchDisabled} className="LayoutSearchResults__idInput" type="text"
                                   onChange={(event) => {
                                       inputValue(event)
                            }}/>

                            <div className="LayoutSearchResults__question" onMouseEnter={props.questionShow}
                                 onMouseLeave={props.questionHide}>
                                <div style={props.showQuestionModal ? {"display": "block"} : {"display": "none"}}
                                     className="LayoutSearchResults__question--modal">
                                    <p className="LayoutSearchResults__question--modal-text">{props.questionText}</p>
                                </div>
                            </div>
                        </>: null
                    }
                    
                </div>

                <div className="LayoutSearchResults__chooseDateBlock">
                    <div className="LayoutSearchResults__inputDate">{props.dateValueStart}</div>
                    <p className="LayoutSearchResults__dash">-</p>
                    <div className="LayoutSearchResults__inputDate">{props.dateValueEnd}</div>
                </div>

            </div>
            <main className="LayoutSearchResults__main">
                
                {props.children}
            </main>
            {
                location.pathname!=="/directors-cabinet"?
                <>
                {
                    applications?.length > 0 ? <WhiteButton name="Скачать отчёт"  clicked= {()=>{
                        excelAccess? downloadExcell():setModalStatus(true)
                    }}/> :null
                }
                
                <footer className="LayoutSearchResults__footer">
                    <p className="LayoutSearchResults__footerText">Фильтры:</p>
                    {props.isFilter ?
                        <div className="LayoutSearchResults__filters">
                            {props.filters}
                            <p onClick={initFiltersHandler} className="LayoutSearchResults__resetText">Сбросить все
                                фильтры</p>
                        </div>
                        :
                        <p className="LayoutSearchResults__footerText">фильтры не включены</p>
                    }
                    {!active ?
                    (props.morePages && applications.length>0) ?
                        <div className="LayoutSearchResults__paginationBlock">
                            <div onClick={props.paginationClickLeft}
                                 className="LayoutSearchResults__arrowBtn LayoutSearchResults__arrowBtn--left"/>
                            <div onClick={props.paginationClickRight}
                                 className="LayoutSearchResults__arrowBtn LayoutSearchResults__arrowBtn--right"/>
                            <div className="LayoutSearchResults__pagesNumbersBlock">
                                {props.pagesNumbers}
                            </div>
                        </div>
                        : null : null}
                    </footer>
                </>
                
                :null
            }
            <ModalWindow 
                bgOnClick = {()=>setModalStatus(false)}
                modalStatus = {modalStatus}
                contentWidth = '80%'
            >
            <div className="Search_modal">
                <div className="Search_modal_title">Внимание!</div>
                <div className="Search_modal_alert">Файл с отчетом по данным фильтрам уже скачан, проверьте, пожалуйста, "загрузки" в вашем браузере</div>
            </div>
        </ModalWindow>
        </div>
    )
}


export default LayoutSearchResults;
