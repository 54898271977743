import React from "react";
import './OpenAccessForm.css'
const OpenAccessForm = (props) =>{
    return (
        <div className="OpenAccessForm">
            <button className="goBack" onClick={()=>{window.history.back()}}>&lt; Назад</button>
            <h1 className="OpenAccessForm_title">{props.title}</h1>
            <form onSubmit={props.onSubmit}>
               {props.children} 
            </form>
            
        </div>
    )
}

export default OpenAccessForm;