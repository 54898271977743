import axios from "../../axiosApi";
import WithLoader from "../../hoc/WithLoader/WithLoader";
import LayoutApplicationPage from "../../Components/ApplicationPageComponents/LayoutApplicationPage/LayoutApplicationPage";
import {LogoSidebar} from "../../constants/UI/LogoSidebar";
import {PPHeaderForm} from "../../Components/ConferencePageComponents/PersonalProfilePage/HeaderForm/PPHeaderForm";
import './PersonalProfilePage.css';
import {TranslationsForm} from "../../Components/ConferencePageComponents/PersonalProfilePage/TranslationsForm/TranslationsForm";
import {FooterInfo} from "../../constants/Contact/FooterInfo/FooterInfo";
import React from "react";
import {ITSGContact} from "../../constants/Contact/ITSGContact/ITSGContact";

const PersonalProfilePage = () => {

    const body = (
        <div className="PersonalProfile">
            <PPHeaderForm/>
            <TranslationsForm/>
        </div>
    )

    const left = (
        <div>
            <LogoSidebar />
        </div>
    )

    const bottom = (
        <div className="footer">
            <FooterInfo/>
            <ITSGContact/>
        </div>
    )


    return (
        <LayoutApplicationPage left = {left} center={body} bottom = {bottom} />
    )
}

export default WithLoader(PersonalProfilePage, axios);