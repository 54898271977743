import React, { useEffect, useState } from 'react';
import './SmsInput.css';

const SmsInput = (props) => {

    const sendToWhatsapp = () => {
        props.sendSmsWhatsapp();
        props.setTime(props.timeResend);
    }

    const sendToPhone = () => {
        props.sendSmsPhone();
        props.setTime(props.timeResend);
    }

    useEffect(() => {
        const intervalId = setInterval(() => props.setTime(prev => prev - 1), 1000);

        if (props.time < 1) {
            clearInterval(intervalId);
            props.allowResend();
        }
        return () => {
            clearInterval(intervalId);
        }
    }, [props.time]);

    return (
        <div className="SmsInput">
            <p className="SmsInput__title">SMS-верификация</p>
            <p className="SmsInput__text">Уважаемый пользователь,
                на ваш номер <span className="SmsInput__text--bold">{props.phone}</span>
                отправлен одноразовый пароль
            </p>
            <div className="SmsInput__inputs">
                {props.children}
            </div>
            {props.wrongPassword ? <p className="SmsInput__text--red">неверный пароль</p> : null}
            <p className="SmsInput__text--small">
                {props.alreadySended ?
                    <>запросить пароль повторно можно будет через <span className='SmsInput__text--bold'>{props.time}</span> секунд</>
                    :
                    <>Запросить код повторно на <br />
                        <span className='SmsInputFooter' onClick={sendToPhone}>SMS</span> | <span className='SmsInputFooter' onClick={sendToWhatsapp}>Whatsapp</span>
                    </>
                }
            </p>
        </div>
    )
}

export default SmsInput;