import React from 'react';
import BlueButton from '../BlueButton/BlueButton';
import axios from "../../../axiosApi";
import WithLoader from '../../../hoc/WithLoader/WithLoader';
import Hint from '../../UI/Hint/Hint';
import FormInput from '../../UI/Form/FormInput/FormInput';
import FormBlockTitle from '../../UI/FormBlock/FormBlockTitle/FormBlockTitle';
import {ReactComponent as SelectIcon} from "../../../assets/icons/bottom_arrow.svg"
import FormInputTextSecure from '../../UI/Form/FormInputTextSecure/FormInputTextSecure';
import { subjectOptions } from './SubjetOptions';
import './ApplicationForm.css';
import "../../FAQPageComponents/LayoutFAQ/LayoutFAQ.css";

const ApplicationForm = (props) => {

    return (
        <div className="ApplicationForm">
            
            
            <h2 className="ApplicationForm__title"><span
                className="ApplicationForm__title-name">{props.userName}</span>, {props.greetings}</h2>
            <form className="ApplicationForm__form" onSubmit={props.submitClicked} autoComplete="off">
                <FormBlockTitle>{props.subjectTitle}</FormBlockTitle>
                <FormInput 
                    type="text"
                    value={props.subjectValue}
                    name={props.subjectName}
                    onChangeHandler={props.subjectChange} 
                    required={props.subjectRequired}
                    placeholder={props.subjectPlaceholder}
                    searchList={subjectOptions}
                />

                <FormBlockTitle className="ApplicationForm__inputTitle">{props.departmentTitle} 
                {props.departmentFaq?<Hint hintOnClick = {props.hintOnClick} hintState = {props.departmentFaqHintState} setHintShow={props.setHintShow} setHintHidden={props.setHintHidden} hint = {props.departmentFaq}/>:null}</FormBlockTitle>
                
                <div className="ApplicationForm__input-select--block">
                    <select id={"select"} defaultValue={'DEFAULT'}
                            className="ApplicationForm__input ApplicationForm__input-select"
                            name={props.departmentName} onChange={props.departmentChange}
                            required={props.departmentRequired}>

                        <option value="DEFAULT" disabled
                                selected
                                hidden
                                >{props.departmentPlaceholder}
                        </option>
                        <option value="1">Удаленная поддержка</option>
                        <option value="2">Выездные специалисты</option>
                        <option value="3">Сервисный центр</option>
                        <option value="12">Заправка и ремонт офисной техники</option>
                        <option value="4">Обслуживание серверов</option>
                        <option value="6">Продажи</option>
                        <option value="8">Бухгалтерия</option>
                        <option value="10">Аутстаффинг</option>
                        <option value="9">Я не знаю</option>
                    </select>
                </div>
                
                <div className={`ApplicationForm__input_hidden ${props.departmentValue==="2"?"ApplicationForm__input_show":""}`}>
                    <FormInput placeholder="Адрес" onChangeHandler = {props.onChangeAdress} value={props.adressValue} />
                </div>
                <div className={`ApplicationForm__input_hidden ${props.departmentValue==="12"?"ApplicationForm__input_show":""}`}>
                    <FormInput placeholder="Адрес" onChangeHandler = {props.onChangeAdress} value={props.adressValue} />
                </div>
                <div className={`ApplicationForm__input_hidden ${props.departmentValue==="12"?"ApplicationForm__input_show":""}`}>
                    <FormInput placeholder="Модель принтера" onChangeHandler = {props.onChangePrinterModel} value={props.modelValue}/>
                </div>

                <FormBlockTitle className="ApplicationForm__inputTitle">{props.messageTitle}</FormBlockTitle>
                <textarea className="ApplicationForm__inputMessage" name={props.messageName}
                          onChange={props.messageChange} required={props.messageRequired}
                          placeholder={props.messagePlaceholder}/>

                <div className="ApplicationForm__downloadBlock">
                    <input className="ApplicationForm__inputFile" type="file" multiple onChange={props.fileClicked}
                           ref={props.fileRef} name={props.inputFileName}/>
                    <div className="ApplicationForm__fileIcon" onClick={props.iconClick}/>
                    <p className="ApplicationForm__fileName" onClick={props.iconClick}>{props.fileName}</p>
                    <Hint hintState = {props.questionHintState} setHintShow={props.questionShow} setHintHidden={props.questionHide} hint = {props.questionText}/>
                </div>

                <div className="FileListBlocks__block">
                    {props.chosenFiles}
                </div>

                <div className='ApplicationForm__choose_connect_wrapper'>
                    <div className="ApplicationForm__choose_connect">
                        <span>Данные для подключения</span>
                        <SelectIcon className='ApplicationForm__select_button' onClick={props.toggleShowConnectVars} />
                        {props.showConnectVars?
                            <div className='ApplicationForm__choose_connect_vars'>
                                <div onClick={props.setConnectionTv} className='ApplicationForm__choose_connect_option'>TeamViewer</div>
                                <div onClick={props.setConnectionAny} className='ApplicationForm__choose_connect_option'>AnyDesk</div>
                            </div>
                            :
                            null
                        }

                    </div>
                </div>
                

                <div className="ApplicationForm__teamViewer-block">
                    <div className='ApplicationForm__connection_input'>
                        {
                            props.connectionType==="tv"?
                                <FormInputTextSecure
                                    placeholder="Введите пароль TeamViewer"
                                    type="text"
                                    name="tv-password"
                                    autoComplete="off"
                                    value={props.connectionValue}
                                    onChangeHandler={props.connectionOnChange}
                                    needHideText={true}
                                />
                            :null
                        }
                        {
                            props.connectionType==="anydesk"?
                            <FormInput
                                placeholder="Введите ID AnyDesk"
                                type="text"
                                name="anydesk"
                                autoComplete="on"
                                value={props.connectionValue}
                                onChangeHandler={props.connectionOnChangeAnydesk}
                            />:null
                        }
                    </div>
                    <BlueButton
                        name={props.buttonName}
                        type="submit"
                        isDisabled={props.isDisabled}
                    />
                </div>
            </form>
        </div>
    )
}

export default WithLoader(ApplicationForm, axios);
