import React from "react";
import './FormBlock.css'
import FormBlockTitle from "./FormBlockTitle/FormBlockTitle";

const FormBlock = (props) =>{
    return (
        <div className="FormBlock">
            <FormBlockTitle 
                hint={props.hint}
                setHintShow = {props.setHintShow}
                setHintHidden={props.setHintHidden}
                hintState={props.hintState}
            >
                {props.title} </FormBlockTitle>
            {props.children}
        </div>
    )

}

export default FormBlock;