import React, { useEffect } from "react";
import "./FormInputTextSecure.css"
import { useState } from "react";
const FormInputTextSecure = (props)=>{
    const [type, setType] = useState(props.type);
    const [showPass, setShowPass] = useState(false)
    const toggleShowPass = ()=>{
        setShowPass(!showPass)
    }
    useEffect(()=>{
        if(props.type==="password"){
            !showPass?setType("password"):setType("text")
        }
        
    }, [showPass, props.type])
    return (
        <div className="FormInputTextSecure_group">
            <input 
                required={props.required}
                name={props.name} 
                id ={props.name} 
                value={props.value} 
                onChange={props.onChangeHandler} 
                className = {`FormInputTextSecure ${props.className?props.className: ""} ${!showPass?"FormInputTextSecure__hide_text": "FormInputTextSecure__show_text"}`} 
                autoComplete={props.autoComplete}
                type={type? type: 'text'} 
                placeholder={props.placeholder? props.placeholder : " "}>

            </input>
            {props.needHideText? 
                <div onClick={toggleShowPass} className={`FormInputTextSecure__password_icon ${showPass?"FormInputTextSecure__password_icon_show": "FormInputTextSecure__password_icon_hidden"}`}></div>
                : null
            }
            {props.label? <label htmlFor={props.name} className="FormInputTextSecure_label">{props.label}</label>: null}
        </div>
    )
}

export default FormInputTextSecure;