import axios from "axios";
import { SET_VKS_INVITE_ROOM_INFO, SET_VKS_LINK, SET_VKS_MESSAGE, SET_VKS_PARTS, SET_VKS_ROOM_ERROR, SET_VKS_STATUS } from "./vksActionTypes";
import { CONFIG } from "../../constants/backendUrl";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import VksReadyModal from "../../Components/VksInviteComponents/VksReadyModal/VksReadyModal";

export const setVksRoomInfo = (value) => ({type: SET_VKS_INVITE_ROOM_INFO, value})
export const setVksStatus = (value) => ({type: SET_VKS_STATUS, value})
export const setVksLink = (value) => ({type: SET_VKS_LINK, value})
export const setVksMessage = (value) => ({type: SET_VKS_MESSAGE, value})
export const setVksParts = (value) => ({type: SET_VKS_PARTS, value})
export const setVksRoomError = (value) => ({type: SET_VKS_ROOM_ERROR, value})
export const getVksAvailability = ({hash, id}) => {
    return async dispatch =>{
        try{
            const response = await axios.get(`${CONFIG.biBack}/api/vks/hasVks`, {
                headers: {
                    Authorization: `Bearer Device:${id}`
                }
            })
            dispatch(setVksStatus(response.data.data.hasVks))
            if(response.data.data.hasVks){
                dispatch(setVksLink(response.data.data.link))
                dispatch(setVksParts(response.data.data.urlData))
            }else {
                dispatch(setVksLink(""))
            }
        }catch(e){
            
            console.log(e);
        }
    }
}
export const createVks = (id) => {
    return async dispatch => {
        try{
            const MySwal = withReactContent(Swal)
            const response = await axios.post(`${CONFIG.biBack}/api/vks/createVks`, {}, {
                headers: {
                    Authorization: `Bearer Device:${id}`
                }
            })
            const currentURL = window.location.protocol + '//' + window.location.host;
            MySwal.fire({
                title: 'Видеоконференции на портале Asista',
                html: 
                    <VksReadyModal currentURL={currentURL} vksParts={response.data.data.urlData} vksLink={response.data.data.link} id={id}/>,
                showConfirmButton: false,
                icon: "success",
                showCloseButton: false
            
            })
        }catch(e){
            console.log(e);
        }
    }
}
export const getVksInviteRomInfo = (roomId) =>{
    return async dispatch => {
        try{
            const response = await axios.get(`${CONFIG.biBack}/api/vks/roomInfo/${roomId}`)
            dispatch(setVksRoomInfo(response.data.data))
            if(response.data.errors.code==="VKS_NOT_EXISTS"){
                dispatch(setVksRoomError(true))
            }else{
                dispatch(setVksRoomError(false))
            }
        }catch(e){
            console.log(e);
        }
    }
}