import { SET_VKS_INVITE_ROOM_INFO, SET_VKS_LINK, SET_VKS_MESSAGE, SET_VKS_PARTS, SET_VKS_ROOM_ERROR, SET_VKS_STATUS } from "./vksActionTypes";

const initialState = {
    hasVks: false,
    vksMessage: "",
    vksLink: "",
    vksParts: {},
    vksInviteRoomInfo: {},
    vksRoomError: false
};

const vksReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VKS_STATUS:
            return {...state, hasVks: action.value}
        case SET_VKS_MESSAGE:
            return {...state, vksMessage: action.value}
        case SET_VKS_LINK:
            return {...state, vksLink: action.value}
        case SET_VKS_PARTS:
            return {...state, vksParts: action.value}
        case SET_VKS_INVITE_ROOM_INFO:
            return {...state, vksInviteRoomInfo: action.value}
        case SET_VKS_ROOM_ERROR:
            return {...state, vksRoomError: action.value}
        default:
            return state;
    }
};

export default vksReducer;
