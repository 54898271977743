import React from 'react';
import './Logo.css';


const LogoFrameInv = (props) => {
    return (
        <div onClick={props.logoLink} className="LogoFrameInv" />
    )
}

export default LogoFrameInv;