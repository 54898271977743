export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            
            console.log('Текст успешно скопирован в буфер обмена:', text);
            // Дополнительные действия после успешного копирования
        })
        .catch((error) => {
            console.error('Ошибка при копировании текста:', error);
            // Дополнительные действия в случае ошибки
        });
    
        
}

export const selectAllOnDoubleClick = (event) => {
    const selection = window.getSelection();
    const range = document.createRange();

    range.selectNodeContents(event.target);

    selection.removeAllRanges();
    selection.addRange(range);
  };