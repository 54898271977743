import React from 'react';
import Logo from '../../ApplicationPageComponents/Logo/Logo';
import './LayoutUndefinedID.css';

const LayoutUndefinedID = (props) => {

    return (
        <div className="LayoutApplicationPage">
            <div className="LayoutApplicationPage_sidebar">
                <Logo
                    logoLink={props.createNewApplication}
                />
                
                
            </div>
            <div className="LayoutApplicationPage__main">
                <div className="LayoutApplicationPage__top">{props.top}</div>
                <div className="LayoutApplicationPage__center">{props.center}</div>
                <div className="LayoutApplicationPage__bottom">{props.bottom}</div>
            </div>
        </div>
    )
}

export default LayoutUndefinedID;
