import React from "react";
import './FunctionCard.css'
const FunctionCard = (props) => {
    return(
        <div onClick={props.onClick} className={`FunctionCard ${props.colorClass?props.colorClass: ""}`}>
            <div className="FunctionCard__info">
                <h4 className="FunctionCard__title">{props.title}</h4>
                <p className="FunctionCard__text">{props.text}</p>
            </div>
            <div className={`FunctionCard__bar`}></div>
        </div>
    )
}

export default FunctionCard;