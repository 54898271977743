import React, { useState } from "react";
import { useDispatch } from "react-redux";
import OpenIcon from '../../../../assets/icons/open-icon.svg'
import { setFaqOpen } from "../../../../Store/GeneralReducer/generalActions";
import "./DropDownText.css"

const DropDownText = (props) => {
    const [ isClose, setIsClose ] = useState(false)
    const dispatch = useDispatch();

    const openOrShowContent = () => {
        dispatch(setFaqOpen(props.value))
        setIsClose(!isClose)
    }

    return (
        <div className="DropDownText">
            <div className={"DropDownText__title"} onClick={openOrShowContent}>
                <img className={`DropDownText__open_icon ${ props.showDropDown? 'rotate_45': 'null'}`} src={OpenIcon}/>{props.title}</div>
            {
                props.showDropDown?
                <>
                    <div className="DropDownText__text" dangerouslySetInnerHTML={{ __html: props.text }}></div>
                    <div className='DropDownText__feedback'> 
                        <span className='faq_question-text'>Было полезно?</span>
                        {props.faqUseful==="" && typeof(props.faqUseful)==="string"?
                            <>
                                <button onClick={props.feedbackButtonOnClickYes} className='faq_question-btn'>да</button>
                                <button onClick={props.feedbackButtonOnClickNo} className='faq_question-btn'>нет</button>
                            </>
                        :
                        props.faqUseful && typeof(props.faqUseful)==="boolean"?
                            <button className='faq_question-btn'>да</button>
                        :
                            <button className='faq_question-btn'>нет</button>
                        }
                        
                    </div>
                </>
                
                :null
            }
            
        </div>
    )
}

export default DropDownText;