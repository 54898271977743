import './LayoutFAQ.css';
import FAQBodyWrapper from "../FAQBody/FAQBodyWrapper";
import FAQFooterWrapper from "./FAQRightWrapper/FAQFooterWrapper";

const LayoutFAQ = (props) => {

    return (
        <div className={props.class}>
            <div className='FAQ-left_block'/>
            <FAQBodyWrapper />
            <FAQFooterWrapper show={props.show} hideClass = {props.hideClass} />
        </div>
    )
}

export default LayoutFAQ;