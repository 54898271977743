import './FooterInfo.css';

export const FooterInfo = () => {
    return (
        <div className="FooterInfo">
            <p className="mobileWrapText">Единый номер</p>
            <h3 className="telephone">+7 727 390 11 12</h3>
            <p className="support">служба поддержки пользователей</p>
            <div className="socialIcons">
                <div className="social-instagram"/>
                <div className="social-meta"/>
                <div className="social-linkedin"/>
                <div className="social-youtube"/>
            </div>
        </div>
    )
}