import './Trials.css';

const Trials = (props) => {

    return (
        <div className='trials_block'>
            <div className='trials-text_block'>
                <p className='trials-text'>
                    {((props.trialInfo?.avitimTrial && props.trialInfo?.invisionTrial ) || (props.clientAccess?.avitimClient && props.trialInfo?.invisionTrial)|| (props.clientAccess?.invisionClient && props.trialInfo.avitimTrial))? 
                    "У вас закончились пробные консультации"
                    : (!props.trialInfo?.invisionTrial && !props.clientAccess?.invisionClient && ( props.clientAccess?.avitimClient || props.trialInfo?.avitimTrial))? 
                    "У вас есть бесплатная консультация по WEB"
                    : (!props.trialInfo?.avitimTrial && !props.clientAccess?.avitimClient && (props.clientAccess?.invisionClient|| props.trialInfo?.invisionTrial))? 
                    "У вас есть бесплатная консультация по 1C" 
                    : (!props.trialInfo?.avitimTrial && !props.trialInfo?.invisionTrial && !props.clientAccess?.invisionClient && !props.clientAccess?.avitimClient)?
                    "У вас есть бесплатные консультации по 1С и WEB": null} 
                    </p>
            </div>
            
        </div>
    )
}

export default Trials;