import React from 'react';
import './ErrorWindow.css';
import {useDispatch} from "react-redux";
import {goBack} from "connected-react-router";
import {ReactComponent as Instagram} from "../../../assets/icons/instagram-white.svg";
import {ReactComponent as Facebook} from "../../../assets/icons/facebook-white.svg";
import {ReactComponent as Linkedin} from "../../../assets/icons/linkedin-white.svg";
import {ReactComponent as YouTube} from "../../../assets/icons/youtube-white.svg";
import WhiteButton from '../WhiteButton/WhiteButton';
import RedButton from '../../UI/RedButton/RedButton';
const ErrorWindow = () => {
    const dispatch = useDispatch()
    // const isConnected = window.navigator.onLine
    // if (isConnected) {
    //     dispatch(goBack())
    // }



    return (
        <div className="ErrorWindow">
            <div className="ErrorWindow__container">
                <h1 className="ErrorWindow__title">Что-то пошло не так</h1>
                <p className="ErrorWindow__text">Страница не загружается. Не отчаивайтесь!</p>
                
                
                <p className="ErrorWindow__text">Вы можете оставить заявку <a href="http://wa.me/+77473901112" className="ErrorWindow__link">в чат-боте WhatsApp</a> или по телефону:</p>

                
                <div className="ErrorWindow__contacts">
                    <div className="ErrorWindow__contactSide">
                        <p className="ErrorWindow__phone">+7 727 390 11 12</p>
                        <p className="ErrorWindow__city">в Алматы</p>
                    </div>
                    <div className="ErrorWindow__contactSide">
                        <p className="ErrorWindow__phone">+7 717 264 61 12</p>
                        <p className="ErrorWindow__city">в Астане</p>
                    </div>
                    <div className="ErrorWindow__contactSide">
                        <p className="ErrorWindow__phone">+7 725 260 51 12 </p>
                        <p className="ErrorWindow__city">в Шымкенте</p>
                    </div>
                </div>
                <div className='ErrorWindow__back_button'>
                    <RedButton
                        onClickHandler={()=>window.history.back()}
                        name="Вернуться назад"
                    />
                </div>
                <div className='ErrorWindow__Footer_SocialMedias'>
                        <span className='ErrorWindow__whitetext'>Мы в социальных сетях</span>
                        <Instagram onClick={()=>{window.location.assign('https://www.instagram.com/itsupport.kz/?hl=ru')}}                                      />
                        <Facebook  onClick={()=>{window.location.assign('https://www.facebook.com/itsupport.kz/')}}                                            />
                        <Linkedin  onClick={()=>{window.location.assign('https://www.linkedin.com/company/it-support-group-kazakhstan?originalSubdomain=kz')}} />
                        <YouTube   onClick={()=>{window.location.assign('https://www.youtube.com/channel/UCsnFSIp17CHdL-h69_8mgnQ')}}                          />
                    </div>
            </div>
        </div>
    )
}


export default ErrorWindow;
