import { Helmet } from "react-helmet";
import CHAT_ICON from "../assets/icons/asista_chat.png"
import MAIN_ICON from "../assets/icons/asista_main_favicon.png"
export const ICONS = {
    asista: MAIN_ICON,
    chat: CHAT_ICON,
};
const TITLE = (props) => {
    return (
        <>
            <Helmet>
                <title>{props?.title || 'Асиста'}</title>
                <link rel="icon" href={ICONS[props?.icon || "asista"]} />
                <link rel="apple-touch-icon" href={ICONS[props?.icon || "asista"]} />
            </Helmet>
            {props?.children}
        </>
    );
};

export default TITLE;
