import { useDispatch, useSelector } from "react-redux"
import "./ChatOpenButton.css"
import { setShowChat } from "../../../../../Store/GeneralReducer/generalActions";
import ProjectIcon from "../../../../../assets/department_icons/project_managers.svg"
import OutstuffIcon from "../../../../../assets/department_icons/outstuff.svg"
import FirstLine from "../../../../../assets/department_icons/line_1.svg"
import SecondLine from "../../../../../assets/department_icons/line_2.svg"
import ThirdLine from "../../../../../assets/department_icons/line_3.svg"
import Viezdnie from "../../../../../assets/department_icons/viezdnie.svg"
import ServersSupport from "../../../../../assets/department_icons/servers_support.svg"
import Buhgalteria from "../../../../../assets/department_icons/buhgalteria.svg"
import Service from "../../../../../assets/department_icons/service.svg"
import Office from "../../../../../assets/department_icons/office.svg"
import Sells from "../../../../../assets/department_icons/sells.svg"
import Notifications from "../../../../../assets/icons/notification.png"
import NotificationsOff from "../../../../../assets/icons/notification-off.png"
import moment from "moment";
import { setNotificationsWorking, subscribeNotifications } from "../../../../../Store/ChatReducer/chatActions";
import { useParams } from "react-router/cjs/react-router.min";
import { notificationsOff, notificationsOn } from "../../WebPush/WebPush";
import { useEffect, useState } from "react";
export default function ChatOpenButton (props){
    const dispatch=useDispatch()
    const currentApplication = useSelector(state => state.applications.currentApplicationData);
    const id = props.deviceId;
    const hash = props.appHash;
    const currentApplicationTime = useSelector(state => state.chat.chatApplicationTime);
    const notificationsWorking = useSelector(state => state.chat.notificationsWorking);
    const [createdTime, setCreatedTime] = useState("")

    useEffect(()=>{
        if(currentApplicationTime){
            setCreatedTime(currentApplicationTime.createdAt)
        }
    }, [currentApplicationTime])

    const departmentImage = (department) => {
        switch(department){
            case "PRB Проблема/замечания":
            case "Комплексные задачи":    
            case "Напоминание":
            case "Обучение":
            case "Проектный менеджер":
            case "Рекламация\\Обратная связь":
            case "Системы":
            case "Улучшение работы":
                return ProjectIcon;
            case "Аутстаффинг":
                return OutstuffIcon;
            case "Бухгалтерия":
                return Buhgalteria;
            case "Выездные специалисты":
            case "Логистика":
            case "Монтаж сетей":
            case "Плановая профилактика":
                return Viezdnie;
            case "Заправка/Ремонт офисной техники":
                return Office;
            case "Обслуживание серверов":
                return ServersSupport;
            case "Сервисный центр":
                return Service;
            case "Продажи / Закуп":
                return Sells;
            case "Удаленная поддержка 1 линия":
                return FirstLine;
            case "Удаленная поддержка 2 линия":
                return SecondLine;
            case "Специалисты 3 линии поддержки":
                return ThirdLine;
            default:
                return FirstLine
        }
    }
    const setNotificationsWorkingFunc = (arg) => {
        dispatch(setNotificationsWorking(arg))
    }
    const notificationsSwitch = () => {
        if(notificationsWorking){
            notificationsOff(`Guest:${id}:${hash}`, setNotificationsWorkingFunc)
        }else{
            notificationsOn(`Guest:${id}:${hash}`, setNotificationsWorkingFunc)
        }
        
    }
    return(
        <div className="ChatButton">
            {
                !props.showChat?
                <button className="ChatOpenButton"
                    onClick={props.openChat}
                >
                    Открыть чат с онлайн-поддержкой
                </button>
                :
                <div className="ChatMainInfo">
                    <button onClick={props.closeChat} className="ChatCloseButton">
                        <span></span>
                        <span>Назад</span>
                    </button>
                    <div className="Application__infoheader">
                        <div className="Application__infoheader_text">
                            <div className="Application__infoheader_id">Заявка {currentApplication?.humanId}</div>
                            <div className="Application__infoheader_info"> {moment.utc(createdTime).format("DD.MM.YY")} | {moment.utc(createdTime).format("HH:mm")} | {currentApplication?.topic} | {currentApplication?.division}</div>
                        </div>
                        <div className="Application__infoheader_icons">
                           
                            <img onClick={notificationsSwitch} className={`Application__infoheader_notifications_icon`} 
                                src={notificationsWorking?Notifications:NotificationsOff} 
                                title={notificationsWorking?"Нажмите, чтобы выключить уведомления о сообщениях от специалиста":"Нажмите, чтобы включить уведомления о сообщениях от специалиста"}/>
                            
                            <div className="Application__infoheader_logo">
                                <img src={departmentImage(currentApplication?.division)} alt="logo"/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            }
            
        </div>
        
    )
}