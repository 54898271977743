const links = {
    "instagram": 'https://www.instagram.com/itsupport.kz/?hl=ru',
    "meta": 'https://www.facebook.com/itsupport.kz',
    "linkedin": 'https://www.linkedin.com/company/it-support-group-kazakhstan?originalSubdomain=kz',
    "youtube": 'https://www.youtube.com/channel/UCsnFSIp17CHdL-h69_8mgnQ'
}

export const openBlankByClassName = ({ target }) => {
  target.classList.forEach(classWord => {
    for (const key in links) {
      if (classWord.search(key) !== -1) {
        window.open(links[key], "_blank");
      }
    }
  });
};
