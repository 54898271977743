import { useEffect } from 'react';
import './AVITIM.css';
import { useSelector } from 'react-redux';

export const AVITIM = (props) => {
    const cookies = useSelector(state=>state.general.cookies)
    return (
        <div style={props.clientAccess?.avitimClient ? {"backgroundColor": "#fff", "borderRadius": "8px"} : {"backgroundColor": "#e0e0e0", "borderRadius": "8px"}  }>
            <div className="AVITIM">
                <div className = "AVITIM-logo"/>
                <div className="classification">
                    <p>1C</p>
                    <p>Лицензирование</p>
                    <p>Разработка</p>
                    <p>Поддержка</p>
                </div>
            </div>
            {props.clientAccess?.avitimClient?  
                <div className="fill-Avitim" onClick={()=>{window.location.assign(cookies.googtrans?`https://asista.avitim.kz/lang/${cookies.googtrans.substring(4)}?redirect=/application/${props.avitimID}/newticket`:`https://asista.avitim.kz/application/${props.avitimID}/newticket`)}}>
                    <div className="invHoverLogo-avit"/>
                </div>
                :props.trialInfo?.avitimTrial ?
                <div className="fill-Avitim" onClick={props.modalHandler}>
                    <p className='probe-text'>Консультации закончились <br/>Продолжить работу?</p>
                </div>
                :
                <div className="fill-Avitim"  onClick={()=>{window.location.assign(cookies.googtrans?`https://asista.avitim.kz/lang/${cookies.googtrans.substring(4)}?redirect=/${props.id}/trial/newticket`:`https://asista.avitim.kz/${props.id}/trial/newticket`)}}>
                    <p className='probe-text'>Получить бесплатную<br/> консультацию по 1С</p>
                </div>
                
                }
        </div>
    )
}