import React from 'react';
import { useSelector } from 'react-redux';
import './Logo.css';

const Logo = (props) => {

    const { integrator, integratorLoader } = useSelector(state => state.bi)
    if(integratorLoader) return <></>
    return (
        <div onClick={props.logoLink} className={`LogoFrame LogoFrame_${integrator.name || 'itsupport'}`} />
    )
}

export default Logo;