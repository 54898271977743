import { useSelector } from 'react-redux'
import {useEffect, useState} from 'react';
import './ChatImageModal.css'

import { useDispatch } from 'react-redux';
import { setChatSelectedPhoto } from '../../../../Store/ChatReducer/chatActions';


export function ChatImageModal (props) {
    const chatSelectedPhoto = useSelector(state=>state.chat.chatSelectedPhoto);
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false)
    useEffect(()=>{
        chatSelectedPhoto? setModalOpened(true): setModalOpened(false)
    }, [chatSelectedPhoto])
    return(
        <div className={`ChatImageModal__wrapper ${modalOpened?"ChatImageModal__wrapper_active":""}`}>
            <div className={`ChatImageModal__bg ${modalOpened?"ChatImageModal__bg_active":""}`} onClick={()=>dispatch(setChatSelectedPhoto(""))}/>
            <div className={`ChatImageModal__modal ${modalOpened?"ChatImageModal__modal_active":""}`}>
                <img className='ChatImageModal__modal_img' src={chatSelectedPhoto} alt="Image" />
            </div>
        </div>
    )
}