// export const ADD_COMMENT = 'ADD_COMMENT';

export const GET_TEN_APPLICATIONS = 'GET_TEN_APPLICATIONS';

export const GET_COUNT_AMOUNT = 'GET_COUNT_AMOUNT';

export const INPUT_FILTER_DATE_FROM = 'INPUT_FILTER_DATE_FROM';
export const INPUT_FILTER_DATE_TO = 'INPUT_FILTER_DATE_TO';

export const CHANGE_PAGINATION = 'CHANGE_PAGINATION';
export const SET_LAST_APPLICATION_BACK = 'SET_LAST_APPLICATION_BACK';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CHANGE_DEPARTMENT = 'CHANGE_DEPARTMENT';
export const CHANGE_EMPLOYEE = 'CHANGE_EMPLOYEE';
export const CHANGE_REGION = 'CHANGE_REGION';
export const CHANGE_NUMBER = 'CHANGE_NUMBER';
export const INIT_FILTERS = 'INIT_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS';

export const SAVE_HASH = 'SAVE_HASH';
export const SAVE_ID = 'SAVE_ID';


export const IS_FILTER_DATE_ACTIVE = 'IS_FILTER_DATE_ACTIVE';

export const FIRST_CALENDAR_DAY_IN_RANGE = 'FIRST_CALENDAR_DAY_IN_RANGE';
export const SECOND_CALENDAR_DAY_IN_RANGE = 'SECOND_CALENDAR_DAY_IN_RANGE';

export const GET_LAST_APPLICATION = 'GET_LAST_APPLICATION';
export const GET_LAST_APPLICATION_FULL_DATA = 'GET_LAST_APPLICATION_FULL_DATA';
export const GET_HASH_OF_THE_LAST_APPLICATION = 'GET_HASH_OF_THE_LAST_APPLICATION';

export const GET_CURRENT_APPLICATION_DATA = 'GET_CURRENT_APPLICATION_DATA';

export const SET_INTERVAL = 'SET_INTERVAL';

export const CLEAR_INTERVAL = 'CLEAR_INTERVAL';

export const GET_CLIENT_NAME = 'GET_CLIENT_NAME';

export const INIT_APPLICATION_STATE = 'INIT_APPLICATION_STATE';

export const FORGET_ME = 'FORGET_ME'

export const SET_ACCESS_CLIENT = "SET_ACCESS_CLIENT"

export const SET_CLIENT_PHONE = "SET_CLIENT_PHONE";

export const SET_TRIAL_INFO = "SET_TRIAL_INFO";
export const SET_USER_AVITIM_ID = "SET_USER_AVITIM_ID";

export const SET_CONTINUEWORK="SET_CONTINUEWORK"
export const SET_ADD_FILE_MODAL = "SET_ADD_FILE_MODAL"
export const SET_APPLICATION_HASH = "SET_APPLICATION_HASH"

export const SET_RETURNING_APPLICATION_ID = "SET_RETURNING_APPLICATION_ID"
export const SET_RETURNING_APPLICATION_DATA = "SET_RETURNING_APPLICATION_DATA"