import React from "react";
import './AddInput.css'
const AddInput = (props) =>{
    return(
        <button
            type="button"
            onClick={props.onClickHandler}
            className="AddInput">
            + добавить еще папку
        </button>
    )
}

export default AddInput;