import React, { useState, useEffect } from 'react';
import ButtonGrey from '../UI/ButtonGrey/ButtonGrey';
import './ModalStatus.css';
import {useDispatch, useSelector} from "react-redux";
import {changeStatus, getTenApplications, setActivePage} from "../../../Store/ApplicationsReducer/applicationsActions";

const ModalStatus = (props) => {
    const dispatch = useDispatch();
    const statusActiveFilters = useSelector(state => state.applications.data.filter.status);
    const allStatuses = useSelector(state=>state.company.statuses)
    const userHash = useSelector(state => state.applications.data.hash);
    const reqData = useSelector(state => state.applications.data);
    const inputChange = (uuid) => {
        const newArr = [...statusActiveFilters];
        statusActiveFilters.includes(uuid)?newArr.splice(newArr.indexOf(uuid), 1): newArr.push(uuid)
        dispatch(changeStatus(newArr));
    }
    const showStatusSearchResults = (event) => {
        event.preventDefault();
        dispatch(setActivePage(1))
        dispatch(getTenApplications({data:{...reqData, company: "itsupport"}, userHash: userHash}))
        props.close()
    }

    return (
        <>
        <div onClick={props.close} className="ModalStatus__bg" />
            <div className="ModalStatus">
                <div className="ModalStatus__header">
                    <p className="ModalStatus__header--text">Показывать только:</p>
                </div>
                <div className="ModalStatus__content">
                    <form className="ModalStatus__form" onSubmit={(event) => {showStatusSearchResults(event)}}>
                        
                        
                        {
                            allStatuses.map(status => {
                                return(
                                    <label key={status.name_rus} className="ModalStatus__label">
                                        <input checked={statusActiveFilters.includes(status.uuid)} className="ModalStatus__input" onChange={() => {inputChange(status.uuid)}} type="checkbox" />
                                        <div  className="ModalStatus__label--icon"/>
                                        <p className={
                                            `ModalStatus__status 
                                            ${status.name_rus==="Запланировано"?"ModalStatus__status--planned":
                                              status.name_rus==="В работе"?"ModalStatus__status--inProgress":
                                              status.name_rus==="Завершено"?"ModalStatus__status--completed":
                                              status.name_rus==="Отменено"?"ModalStatus__status--canceled":
                                              status.name_rus==="Пауза"?"ModalStatus__status--pause": ""
                                            }
                                            `
                                        }>{status.name_rus.toLowerCase()}</p>
                                    </label>
                                )
                            })
                        }
                        <ButtonGrey 
                            name="показать"
                        />
                    </form>
                </div>
            </div>
            </>
    )
}


export default ModalStatus;