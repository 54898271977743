import './LogoSidebar.css';
import { ReactComponent as SignOutIcon } from "../../assets/icons/signout.svg";
import { logout } from '../../Store/UsersReducer/usersActions';
import { useDispatch, useSelector } from 'react-redux';
export const LogoSidebar = (props) => {
    const dispatch = useDispatch()
    const {hash} = useSelector(state => state.applications.data)
    const localLogout = () => {
        
        dispatch(logout())
    }
    return (
        <div className={ props.faq ? "LogoSideBar-faq" : "LogoSideBar"}>
            <div className="sidebarColor"/>
            {   (hash && !props.faq) && 
                <SignOutIcon className="Sidebar__quit" onClick={localLogout} />
            }
            <div className="itsupport"/>
            <div className="logo"/>
        </div>
    )
}