import ChatName from "./ChatName/ChatName";
import ChatText from "./ChatText/ChatText";
import "./ChatMessage.css"
import { setChatSelectedPhoto } from "../../../../Store/ChatReducer/chatActions";
import { useDispatch, useSelector } from "react-redux";
import { AttachedFile } from "./AttachedFile/AttachedFile";
export default function ChatMessage(props){
    const dispatch = useDispatch()

    return(
        <div className="ChatMessage">
            <div className="ChatMessage__user">
                <img className="ChatMessage__user_img" src={props.userPhoto} alt="user"/>
                    <ChatName name={props.name} time={props.time}/>
            </div>
            {
                props.images?.map(image=>{
                    return(
                        <img key={image._id} onClick={()=>dispatch(setChatSelectedPhoto(image.url))} className="ChatMessage__img" src={image.url} alt="upload"/>
                    )
                })
            }
            {props.files?.map((file)=>{
                    return(
                        <AttachedFile
                            key={file._id}
                            name={file.originalFilename}
                            size={file.size}
                            extension={file.extension}
                            url={file.url}
                        />
                    )
            })}
            <div className="ChatMessage__content">
                <ChatText>
                    {props.message}
                </ChatText>
                {
                    props.statusSending?
                        <span className="ChatMessage__loader"></span>
                    :null
                }
            </div>
            
        </div>
    )
}