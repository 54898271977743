import React, { useState } from 'react';
import RedButton from '../../Components/UI/RedButton/RedButton';
import "./TestPage.css"
const TestPage = () =>{
    const [reason, setReason] = useState("")
    const [selectedReason, setSelectedReason] = useState("")
    const reasons = ["перестало работать", "не доделали", "сделали плохо", "это новая задача"]
    const checkForSame = () => {
        if(reasons.includes(reason)){
            return true
        }
        return false
    }
    return(
        <div className='lol'>
            <div className='ReturnApplication'>
                <div className='ReturnApplication__title'>Возврат заявки в работу:</div>
                <div className='ReturnApplication__description'>Пожалуйста укажите причину возврата для того, чтобы мы сделали наш сервис лучше, а ваши ИТ-системы еще надежнее.</div>
                {
                    !selectedReason?
                    <input className='ReturnApplication__input' 
                        disabled={checkForSame()} 
                        onChange={(e)=>setReason(e.target.value)} 
                        placeholder='Выберите одну из причин или напишите свою' 
                        value={reason}>
                    </input>:
                    <div className='ReturnApplication__input'>
                        <div className={`ReturnApplication__reason ReturnApplication__reason_selected`} onClick={()=>setSelectedReason("")}>
                            {selectedReason}
                        </div>
                    </div>
                }
                

                <div className='ReturnApplication__reasons_list'>
                    {reasons.map(element => {
                        return (
                            <div key={element} className={`ReturnApplication__reason ${selectedReason || reason? "ReturnApplication__reason_disabled": ""}`}
                                disabled={selectedReason || reason}    
                                onClick={()=>setSelectedReason(element)}
                            >{element}</div>
                        )
                    })}
                </div>
                <RedButton
                    name="Вернуть в работу"
                    onClickHandler={()=>{}}
                    disabled={!reason}
                />
            </div>
        </div>
       
                    
    )
}

export default TestPage;