import {
    SHOW_FAQ,
    OPEN_FAQ_QUESTION,
    ADD_QUESTIONS,
    ADD_FEEDBACK_STATE,
    EXCELL_ACCESS,
    SHOW_CHAT,
    COOKIES,
    SHOW_RETURN_APPLICATION_MODAL,
    SHOW_PATCH_NOTES,
    SET_READ_PATCHES

} from './generalActionTypes'
import update from 'immutability-helper';

const initialState = {
    faq: {
        show: false,
        openState: {},
        questions:[],
        feedback: {}
    },
    showPatchNotes: false,
    showChat: false,
    excellAccess: false,
    cookies: {},
    showReturnApplication: false,
    patchesRead: 0
};

const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_READ_PATCHES:
            return {...state, patchesRead: action.value}
        case SHOW_PATCH_NOTES:
            return {...state, showPatchNotes: action.value}
        case SHOW_RETURN_APPLICATION_MODAL:
            return {...state, showReturnApplication: action.value}
        case SHOW_FAQ:
            return update(state, {
                faq: {
                    show: {$set: action.value}
                }
        });
        case OPEN_FAQ_QUESTION:
            return update(state, {
                faq: {
                    openState: {$set: action.value}
                }
        });
        case ADD_QUESTIONS:
            return update(state, {
                faq: {
                    questions: {$set: action.value}
                }
            });
        case ADD_FEEDBACK_STATE:
            return update(state,{
                faq:{
                    feedback: {$set: action.value}
                }
            });
        case SHOW_CHAT: 
            return {...state, showChat: action.value}
        case EXCELL_ACCESS: 
            return {...state, excellAccess: action.value}
        case COOKIES: 
            return {...state, cookies: action.value}
        default:
            return state;
    }
};

export default generalReducer;
