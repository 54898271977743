import {
    CHANGE_DEPARTMENT,
    CHANGE_EMPLOYEE,
    CHANGE_NUMBER,
    CHANGE_PAGINATION,
    CHANGE_STATUS,
    GET_COUNT_AMOUNT,
    GET_TEN_APPLICATIONS,
    INIT_FILTERS,
    INPUT_FILTER_DATE_FROM,
    INPUT_FILTER_DATE_TO,
    SET_ACTIVE_FILTERS,
    SET_ACTIVE_PAGE,
    SET_CONTINUEWORK,
    SAVE_HASH,
    SET_USER_AVITIM_ID,
    SAVE_ID,
    SET_ACCESS_CLIENT,
    IS_FILTER_DATE_ACTIVE,
    FIRST_CALENDAR_DAY_IN_RANGE,
    SECOND_CALENDAR_DAY_IN_RANGE,
    GET_LAST_APPLICATION,
    GET_HASH_OF_THE_LAST_APPLICATION,
    GET_CURRENT_APPLICATION_DATA,
    SET_INTERVAL,
    SET_CLIENT_PHONE,
    CLEAR_INTERVAL,
    GET_CLIENT_NAME, INIT_APPLICATION_STATE, FORGET_ME, CHANGE_REGION, SET_TRIAL_INFO, SET_SEARCH_FILTERS, SET_ADD_FILE_MODAL, SET_APPLICATION_HASH, SHOW_RETURN_APPLICATION_MODAL, GET_LAST_APPLICATION_FULL_DATA, SET_LAST_APPLICATION_BACK, SET_FILTERS,
    SET_RETURNING_APPLICATION_DATA,
    SET_RETURNING_APPLICATION_ID
} from "./applicationsActionTypes";
import update from 'immutability-helper';
import moment from "moment";
const initialState = {
    interval: null,
    lastApplication: null,
    lastApplicationFullData: null,
    lastApplicationIsBack: false,
    firstCalendarRangeDay: 0,
    secondCalendarRangeDay: 0,
    newApplicationHash: null,
    currentApplicationData: null,
    applications: [],
    count: 0,
    returningApplicationId: '',
    returningApplicationData: null,
    showReturnApplication: false,
    filters: {
        regionfilters: {},
        workerfilters: {},
        departmentfilters:{},
    },
    data: {
        
        clientId: "",
        hash: "",
        filter: {
            date: {
                from: moment().format("DD.MM.YYYY"),
                to: moment().subtract(1, 'months').format("DD.MM.YYYY")
            },
            status: [],
            employee: "",
            department: [],
            region: [],
            number: ""
        },
        limit: 10,
        start: 0
    },
    activePage: 1,
    activeFilters: ['дата'],
    isFilterDateActive: true,
    clientName: "",
    clientPhone: '',
    clientAccess: {
        itsupportClient: true,
        avitimClient: false,
        invisionClient: false
    },
    trialInfo: {
        // avitimTrial: false,
        // invisionTrial: false,
        // itsupportTrial: false
    },
    continueWork:{
        // invision: false,
        // avitim: false,
        // itsupport: false
    },
    userAvitimID: "",
    forgetMe: false,
    addFileModal: false,
    currentApplicationHash: ""
};

const applicationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LAST_APPLICATION_BACK:
            return {...state, lastApplicationIsBack: action.value}
        case GET_LAST_APPLICATION_FULL_DATA: 
            return {...state, lastApplicationFullData: action.value}
        case SET_RETURNING_APPLICATION_DATA:
            return {...state, returningApplicationData: action.value}
        case SET_RETURNING_APPLICATION_ID: 
            return {...state, returningApplicationId: action.value}
        case SET_APPLICATION_HASH:
            return {...state, currentApplicationHash: action.value}
        case FORGET_ME:
            return {...state, forgetMe: true}
        case GET_CLIENT_NAME:
            return {...state, clientName: action.value}
        case CLEAR_INTERVAL:
            return {...state, interval: clearInterval(state.interval)}
        case SET_INTERVAL:
            return {...state, interval: action.value}
        case GET_CURRENT_APPLICATION_DATA:
            return {...state, currentApplicationData: action.value}
        case GET_HASH_OF_THE_LAST_APPLICATION:
            return {...state, newApplicationHash: action.value}
        case GET_LAST_APPLICATION:
            return {...state, lastApplication: action.value};
        case FIRST_CALENDAR_DAY_IN_RANGE:
            return {...state, firstCalendarRangeDay: action.value};
        case SECOND_CALENDAR_DAY_IN_RANGE:
            return {...state, secondCalendarRangeDay: action.value};
        case IS_FILTER_DATE_ACTIVE:
            return {...state, isFilterDateActive: action.value};
        case SET_ACCESS_CLIENT:
            return {...state, clientAccess: action.value};
        case SET_CLIENT_PHONE:
            return {...state, clientPhone: action.value};
        case SET_TRIAL_INFO:
            return {...state, trialInfo: action.value};
        case SET_USER_AVITIM_ID:
            return {...state, userAvitimID: action.value}
        case SET_CONTINUEWORK:
            return {...state, continueWork: action.value}
        case SAVE_ID:
            localStorage.setItem("device_id", action.value)
            return update(state, {
                data: {
                    clientId: {$set: action.value}
                }
            });
        case SAVE_HASH:
            return update(state, {
                data: {
                    hash: {$set: action.value}
                }
        });
        case GET_TEN_APPLICATIONS:
            return {...state, applications: action.value};
        case GET_COUNT_AMOUNT:
            return {...state, count: action.value};
        case INPUT_FILTER_DATE_FROM:
            return update(state, {
                data: {
                    filter: {
                        date: {
                            from: {$set: action.value}
                        }
                    }
                }
            });
        case INPUT_FILTER_DATE_TO:
            return update(state, {
                data: {
                    filter: {
                        date: {
                            to: {$set: action.value}
                        }
                    }
                }
            });
        case CHANGE_PAGINATION:
            return update(state, {
                data: {
                    start: {$set: action.value}
                }
            });
        case CHANGE_STATUS:
            return update(state, {
                data: {
                    filter: {
                        status: {$set: action.value}
                    }
                }
            });
        case CHANGE_DEPARTMENT:
            return update(state, {
                data: {
                    filter: {
                        department: {$set: action.value}
                    }
                }
            });
        case CHANGE_EMPLOYEE:
            return update(state, {
                data: {
                    filter: {
                        employee: {$set: action.value}
                    }
                }
            });
        case CHANGE_REGION:
            return update(state, {
                data: {
                    filter: {
                        region: {$set: action.value}
                    }
                }
            });
        case CHANGE_NUMBER:
            return update(state, {
                data: {
                    filter: {
                        number: {$set: action.value}
                    }
                }
            });
        case INIT_FILTERS:
            return update(state, {
                data: {
                    filter: {
                        status: {$set: []},
                        department: {$set: []},
                        region: {$set: []},
                        employee: {$set: ''},
                        number: {$set: ''}
                    }
                }
            })
        case SET_FILTERS:
            return update(state, {
                data: {
                    filter: {$set: action.value}
                }
            })
        case SET_ACTIVE_PAGE:
            return {...state, activePage: action.value};
        case SET_ACTIVE_FILTERS:
            return {...state, activeFilters: action.value}
        case INIT_APPLICATION_STATE:
            return state = initialState
        case SET_ADD_FILE_MODAL:
            return {...state, addFileModal: action.value}
        default:
            return state;
    }
};

export default applicationsReducer;
