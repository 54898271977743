export const initialPatchNotes = [
    {
        key: '8',
        title: "Только начинаете пользоваться Asista?",
        date: "11 января 2024",
        htmlContent: "<p>Посмотрите короткое видео и узнайте об основных функциях Asista <a href='https://www.youtube.com/watch?v=5PT-m-W9UuY' target='_blank'>по ссылке</a></p>",
        status: 'unread'
    },
    {
        key: '7',
        title: "Возврат заявок в работу",
        date: "18 сентября 2023",
        htmlContent: "<p>Теперь при возврате заявки в работу необходимо указать причину. Это нужно для того, чтобы мы сделали наш сервис лучше, а ваши ИТ-системы еще надежнее.</p>",
        status: 'unread'
    },
    {
        key: '6',
        title: "В Asista появилась видеоконференцсвязь!",
        date: "02 августа 2023",
        htmlContent: "<p>Вы можете бесплатно пользоваться сервисом видеоконференцсвязи в Asista для решения рабочих вопросов с коллегами или вашими клиентами. На текущий момент для одного клиента-компании мы создаем только одну комнату для проведения конференций. Вы сможете поделиться ссылкой на комнату с любым участником конференции (не важно, это существующий пользователь Asista или нет). </p>",
        status: 'unread'
    },
    {
        key: '5',
        title: "В Asista появился чат со специалистом!",
        date: "16 июня 2023",
        htmlContent: "<p>Теперь вы можете пообщаться со специалистом в чате. Чат появляется в течении нескольких минут после подачи заявки. Вы сможете отправлять фото, видео, документы и многое другое напрямую специалисту, который работает над вашим запросом, а также получать уведомления, если специалист Вам ответил в чате.</p>",
        status: 'unread'
    },
    {
        key: '4',
        title: "Asista заговорила на трёх языках!",
        date: "02 мая 2023",
        htmlContent: "<p>В Asista появился переводчик на казахский, английский и русский. Причем Asista запоминает ваше предпочтение и в следующий раз откроет сразу на нужном языке.</p>",
        status: 'unread'
    },
    {
        key: '3',
        title: "Оставлять отзывы стало проще!",
        date: "31 марта 2023",
        htmlContent: "<p>Нам очень важна обратная связь. Поэтому, мы постарались сделать так, чтобы оставлять её было как можно проще – в один клик. Узнайте, как это сделать по <a href='https://youtu.be/NC8Ac0Tp1wQ?si=GmBcFixKBqEj1vVF' target='_blank'>ссылке</a>.</p>",
        status: 'unread'
    },
    {
        key: '2',
        title: "Оптимизируем процесс приема или увольнения сотрудников",
        date: "06 марта 2023",
        htmlContent: "<p>Для этого мы создали модуль, который называется Акт открытия/закрытия доступов. Но мы его называем проще - модуль HR, т.к. он помогает HR оптимизировать процессы приёма или увольнения сотрудника. Подробнее по <a href='https://www.youtube.com/watch?v=emx23lXjP-k' target='_blank'>ссылке</a>.</p>",
        status: 'unread'
    },
    {
        key: '1',
        title: "Бизнес-аналитика в Asista",
        date: "01 марта 2023",
        htmlContent: "<p>Появился модуль BI, который предоставляет статистику по заявкам за нужный период. Модуль является системой поддержки принятия решений (англ. Decision Support System, DSS), т.е. помогает ЛПР принимать решения на основе объективного анализа. Подробнее про модуль можно посмотреть <a href='https://www.youtube.com/watch?v=u_SeD5rY_Pc' target='_blank'>тут</a>.</p>",
        status: 'unread'
    },
];