import React, {useState} from 'react';
import './PrevRedButton.css'


const PrevRedButton = (props) => {
    const [warningModal, setWarningModal] = useState(false);

    const hoverHandler = () => {
        if (props.disabled) setWarningModal(true);
    }

    const inhoverHandler = () => {
        setWarningModal(false);
    }

    return (
        <>
            <button className="RedButton" onClick={props.disabled ? null : props.clicked} onMouseOver={hoverHandler}
                    onMouseOut={inhoverHandler}>{props.name}</button>
            {warningModal ? <div className="warning-modal_text">Этой заявке больше месяца, пожалуйста, оформите новую заявку. </div> : null}
        </>
    )
}

export default PrevRedButton;