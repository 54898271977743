import React from 'react';
import { Button, Flex } from 'antd';
import { MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import './MyPhoneInput.css';

const MyPhoneInput = (props) => {
    return (
        <div className="PhoneInput">
            {props.error ? <p className="PhoneInput__title">Упс...</p> : <p className="PhoneInput__title">SMS-верификация</p>}
            {props.error ? <p className="PhoneInput__text">Судя по всему такого номера нет в нашей базе, возможно мы еще не внесли его или вы ошиблись, проверьте и попробуйте снова.</p> : <p className="PhoneInput__text">Для входа в личный кабинет введите номер мобильного телефона и если он есть в нашей базе, мы пришлём одноразовый пароль</p>}
            <div className="PhoneInput__inputs">
                {props.children}
            </div>
            <Flex gap={10}>
                <Button
                    className='PhoneInput__Footer_Btns'
                    icon={<MailOutlined />}
                    danger
                    onClick={props.sendToPhone}
                    disabled={props.disabled}
                >
                    SMS
                </Button>
                <Button
                    className='PhoneInput__Footer_Btns'
                    icon={<WhatsAppOutlined />}
                    danger
                    onClick={props.sendToWhatsapp}
                    disabled={props.disabled}
                >
                    Whatsapp
                </Button>
            </Flex>
        </div>
    )
}

export default MyPhoneInput;