import React, { useState, useEffect, useRef } from 'react';
import LayoutSearchResults from '../../Components/SearchResultsComponents/LayoutSearchResults/LayoutSearchResults';
import ResultsTableWorker from '../../Components/SearchResultsComponents/ResultsTableWorker/ResultsTableWorker';
import './SearchResultsPage.css';
import {useDispatch, useSelector} from "react-redux";
import {
    changePagination, clearMyInterval,
    initFilters,
    inputFilterDateFrom,
    inputFilterDateTo, isFilterDateActive, setActiveFilters, setActivePage, setFilters
} from "../../Store/ApplicationsReducer/applicationsActions";
import moment from 'moment';
import {getAllDepartments, getAllEmployees, getAllRegions, getCompanyData} from "../../Store/CompanyDataReducer/companyActions";
import {push} from 'connected-react-router';
import ModalPeriod from "../../Components/SearchResultsComponents/ModalPeriod/ModalPeriod";
import axios from "../../axiosApi";
import WithLoader from '../../hoc/WithLoader/WithLoader';
import {LogoSidebar} from "../../constants/UI/LogoSidebar";
import DirectorsCabinetStatistic
    from "../../Components/ApplicationPageComponents/DirectorsCabinetStatistic/DirectorsCabinetStatistic";
import {useLocation} from "react-router-dom";
import { sendYMAnalytics } from '../../utils/metrika';
import ModalDepartment from '../../Components/SearchResultsComponents/ModalDepartment/ModalDepartment';
import ModalWorker from '../../Components/SearchResultsComponents/ModalWorker/ModalWorker';
import ModalStatus from '../../Components/SearchResultsComponents/ModalStatus/ModalStatus';
import ModalRegions from '../../Components/SearchResultsComponents/ModalRegions/ModalRegions';
import { setBiDate } from '../../Store/BIReducer/biActions';
import { setExcelAccess } from '../../Store/GeneralReducer/generalActions';
import TITLE from '../../Routes/TITLE';

const SearchResultsPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [searchDisabled, setSearchDisabled] = useState(false)
    const searchRef = useRef(null)
    let filters = useSelector(state => state.applications.activeFilters);
    const dateFromState = useSelector(state => state.applications.data.filter.date);
    const filtersObj = useSelector(state => state.applications.data.filter);
    const isDateFilterActive = useSelector(state => state.applications.isFilterDateActive)
    const hash = useSelector(state => state.applications.data.hash)
    const clientId = useSelector(state => state.applications.data.clientId);
    const biDate = useSelector(state=> state.bi.date)
    const [analyticsVisible, setAnalyticsVisible] = useState(false);
    const [period, setPeriod] = useState({
        start: dateFromState.from,
        startOrigin: 'ДД/ММ/ГГГГ',
        end: dateFromState.to,
        endOrigin: 'ДД/ММ/ГГГГ'
    });
    useEffect(() => {
        dispatch(clearMyInterval())
        if (clientId && !hash) return dispatch(push(`/login/${clientId}`))
        if (!hash) return dispatch(push('/login'));
        dispatch(getCompanyData(hash));
    }, [clientId, dispatch, hash]);
    useEffect(()=>{
        dispatch(initFilters())
    }, [dispatch])
    useEffect(() => {
        
        if (isDateFilterActive===false) {
            setPeriod(prevState => {
                return {...prevState, startOrigin: dateFromState.from, endOrigin: dateFromState.to}
            });
        } else {
            setPeriod(prevState => {
                return {...prevState, startOrigin: dateFromState.from, endOrigin: dateFromState.to}
        })
        }
        
    }, [dateFromState.from, dateFromState.to, filters, isDateFilterActive]);
    let [dateStart, setDateStart]  =useState(period.startOrigin);
    let [dateEnd, setDateEnd] =useState(period.endOrigin);  
    useEffect(()=>{
        
        filters.includes('дата')? setDateStart([dateFromState.from?.slice(0, 4), "", dateFromState.from?.slice(4, 6), "", dateFromState.from?.slice(6)].join('')) : setDateStart("ДД/ММ/ГГГГ") ;
        filters.includes('дата')? setDateEnd([dateFromState.to?.slice(0, 4), "", dateFromState.to?.slice(4, 6), "", dateFromState.to?.slice(6)].join('')) :setDateEnd("ДД/ММ/ГГГГ") ;
        dispatch(setExcelAccess(true))
        
    },[dateFromState, filters, period, dispatch])
    const company = useSelector(state => state.company.companyData);
    let equipmentId;
    let workerName;
    let companyName;
    if (company) {
        equipmentId = company.director ? company.director.id : company.employee ? company.employee.id : "";
        workerName = company.employee ? company.employee.name : '';
        companyName =  company.company;
    }

    useEffect(() => {
        
        const ymName = company.director? 'cabinet-company': company.employee? 'cabinet-user': ''
        if(ymName) {
            // Отправить запрос на метрику (Сотрудник или Директор)
            sendYMAnalytics(ymName)
        }
          
    }, [company])
 
    const companyLogo = useSelector(state => state.company.companyData['logo']);
    const [showQuestion, setShowQuestion] = useState(false);
    const [calendarModal, setCalendarModal] = useState(false);

    const activePage = useSelector(state => state.applications.activePage);
    const count = useSelector(state => state.applications.count);

    let pagesNumbers = Math.ceil(count / 10); // получать количество страниц для пагинации и кидать число в цикл, чтобы получить массив, нужен для отрисовки

    let tableView;
    let analyticsCabinet;
    let allPages;
    let allFilters;
    const hoverShowQuestion = () => {
        setShowQuestion(true);
    }
    const hoverHideQuestion = () => {
        setShowQuestion(false);
    }
    const setSearchFocus = () => {
        searchRef.current?.focus()
    }
    const setSearchBlur = () => {
        searchRef.current.blur()
    }
    const [deactivateBtnDate, setDeactivateBtn] = useState({});
    const chooseDateBtnSimple = (event,period) => {

        const el = document.getElementsByClassName('LayoutSearchResults__btnDate');
        for (let i = 0; i < el.length; i++) {
            el[i].style.color = '#E34A4E';
            el[i].style.background = 'white';
        }
        const innerText = event.target.textContent;
        if (deactivateBtnDate[innerText]) {
            setDeactivateBtn({});
            deactivateDateFilter();
            return;
        }
        setDeactivateBtn({[event.target.textContent]: true});
        event.target.style.color = 'white';
        event.target.style.background = '#E34A4E';
        if (period === 'custom') {
            setCalendarModal(true);
        } else {
            let today = moment().format("DD.MM.YYYY");
            let chosenDate;
            dispatch(changePagination(0));
            dispatch(setActivePage(1));

            switch (period) {
                case 'today':
                    chosenDate = today;
                    dispatch(setBiDate({type: "today"}))
                    break;
                case 'yesterday':
                    dispatch(setBiDate({type: "yesterday"}))
                    chosenDate = moment().subtract(1, 'days').format("DD.MM.YYYY");
                    today = moment().subtract(1, 'days').format("DD.MM.YYYY");
                    break;
                case 'week':
                    dispatch(setBiDate({type: "week"}))
                    chosenDate = moment().subtract(7, 'days').format("DD.MM.YYYY");
                    break;
                case 'month':
                    dispatch(setBiDate({type: "month"}))
                    chosenDate = moment().subtract(1, 'months').format("DD.MM.YYYY");
                    break;
                case 'quarter':
                    dispatch(setBiDate({type: "quarter"}))
                    chosenDate = moment().subtract(3, 'months').format("DD.MM.YYYY");
                    break;
                case 'half_year':
                    dispatch(setBiDate({type: "half_year"}))
                    chosenDate = moment().subtract(6, 'months').format("DD.MM.YYYY");
                    break;
                default:
                    break;
            }
            dispatch(inputFilterDateFrom(chosenDate));
            dispatch(inputFilterDateTo(today));
            dispatch(isFilterDateActive(true));
            const formatStartDate = today;
            const formatEndDate = chosenDate;
            setPeriod(prevState => {
                return {...prevState, startOrigin: formatStartDate, endOrigin: formatEndDate}
            });
        }
    }
    useEffect(()=>{
        if(dateFromState.to && dateFromState.from){
            dispatch(isFilterDateActive(true))
        }
    }, [dateFromState])
    const deactivateDateFilter =  () => {
         dispatch(isFilterDateActive(false));
         dispatch(inputFilterDateFrom(""));
         dispatch(inputFilterDateTo(""));
         dispatch(setActivePage(1))
        const el = document.getElementsByClassName('LayoutSearchResults__btnDate');
        for (let i = 0; i < el.length; i++) {
            el[i].style.color = '#E34A4E';
            el[i].style.background = 'white';
        }
    }

    
    const closeModal = () => {
        setCalendarModal(false);
        setDeactivateBtn({});
            const el = document.getElementsByClassName('LayoutSearchResults__btnDate');
            for (let i = 0; i < el.length; i++) {
                el[i].style.color = '#E34A4E';
                el[i].style.background = 'white';
            }
        dispatch(isFilterDateActive(false))
        dispatch(inputFilterDateFrom(""));
        dispatch(inputFilterDateTo(""));
    }


    const countPagination = () => {
    // Отрисовка пагинации
    if (pagesNumbers) {
        if (pagesNumbers <= 8) {
            let arr = [];
            for (let i = 0; i < pagesNumbers; i++) {
                arr[i] = i + 1;
            }
            allPages = arr.map(el => {
                return (
                    <p key={el} onClick={el !== "..." ? (event) => {choosePage(event)} : null} className={`${el !== "..." ? "PaginationNumber" : "PaginationDots"} ${el === 1 ? "PaginationNumber--defaultActive" : ""}`}>
                        {el}
                    </p>
                )
            });
        } else {
            // сложная отрисовка пагинации относительно количества страниц, чтобы было видно начальные и последние страницы когда мы в центре, но количество элементов должно оставаться 8
                let arr = [];
                if (activePage < 5) {  

                for (let i = 0; i < 8; i++) {
                    // Когда кликнута страница меньше пятой, но страниц больше 8
                        if (i > 4) {
                            arr[i] = "...";
                            arr[i + 1] = pagesNumbers - 1;
                            arr[i + 2] = pagesNumbers;
                            break;
                        } else {
                            arr[i] = i + 1;
                        }
                    }
                     // Если активная страница равна 5
                } else if (activePage === 5) {
                    for (let i = 0; i < 8; i++) {
                        if (i === 0) {
                            arr[i] = "...";
                        } 
                        else if (i < 5) {
                            arr[i] = i + 1;
                        } else {
                            arr[i] = activePage + 1;
                            arr[i + 1] = "...";
                            arr[i + 2] = pagesNumbers;
                            break;
                        }
                    }
                }
                     // Если активная страница выше 5 но не выше чем максимум - 3
                else if (activePage > 5 && activePage <= pagesNumbers - 3) {
                            arr[0] = 1;
                            arr[1] = "...";
                            arr[2] = activePage - 2;
                            arr[3] = activePage - 1;
                            arr[4] = activePage;
                            arr[5] = activePage + 1;
                            arr[6] = "...";
                            arr[7] = pagesNumbers;
                }
                 // Если активная страница выше чем максимум - 3
                 else if (activePage > pagesNumbers - 3) {
                            arr[0] = 1;
                            arr[1] = 2
                            arr[2] = "...";
                            arr[3] = pagesNumbers - 4;
                            arr[4] = pagesNumbers - 3;
                            arr[5] = pagesNumbers - 2;
                            arr[6] = pagesNumbers - 1;
                            arr[7] = pagesNumbers;
        }
                allPages = arr.map((el, i) => {  
                    return (
                        <p key={el + i} onClick={el !== "..." ? (event) => {choosePage(event)} : null} className={`${el !== "..." ? "PaginationNumber" : "PaginationDots"} ${el === activePage ? "PaginationNumber--defaultActive" : null}`}>
                            {el}
                        </p> 
                    )
                });
            }
    }
}

    countPagination();

    const choosePage = (event) => {
        dispatch(setActivePage(parseInt(event.target.textContent)));
        countPagination();
    }
    const colorActivePage = () => {
        const el = document.getElementsByClassName('PaginationNumber');
        for (let i = 0; i < el.length; i++) {
            el[i].style.fontSize = '14px';
            el[i].style.textDecoration = 'none';
            el[i].style.fontWeight = 'normal';
        }
        if (pagesNumbers <= 8) {
            el[activePage - 1].style.fontSize = '18px';
            el[activePage - 1].style.fontWeight = 'bold';
            el[activePage - 1].style.textDecoration = 'underline';
        } else {
            if (activePage < 4) {
                el[activePage - 1].style.fontSize = '18px';
                el[activePage - 1].style.fontWeight = 'bold';
                el[activePage - 1].style.textDecoration = 'underline';
            } else if (activePage === pagesNumbers) {
                el[6].style.fontSize = '18px';
                el[6].style.fontWeight = 'bold';
                el[6].style.textDecoration = 'underline';
            } else if (activePage > pagesNumbers - 3) {
                el[6 - (pagesNumbers - activePage)].style.fontSize = '18px';
                el[6 - (pagesNumbers - activePage)].style.fontWeight = 'bold';
                el[6 - (pagesNumbers - activePage)].style.textDecoration = 'underline';
            } else {
                el[3].style.fontSize = '18px';
                el[3].style.fontWeight = 'bold';
                el[3].style.textDecoration = 'underline';
            }
        }
    }

    const paginationRight = () => {
        if (activePage !== pagesNumbers) {
            dispatch(setActivePage(activePage + 1));
        }
    };
    const paginationLeft = () => {
        if (activePage !== 1) {
            dispatch(setActivePage(activePage - 1));
        }
    };



    tableView = (
    <>
        <ResultsTableWorker 
            setSearchDisabled={()=>setSearchDisabled(true)}        
            setSearchActive={()=>setSearchDisabled(false)}
            setSearchFocus={setSearchFocus}
            setSearchBlur={setSearchBlur}
        />
    </>);

    analyticsCabinet = (
        <>
            <DirectorsCabinetStatistic/>
        </>
    );

    if (filters.length !== 0) {
        // Внизу страницы отображются активные фильтры, здесь они и рисуются
        allFilters = filters.map((el, i) => {
            return (
                <div className="LayoutSearchResults__filters" key={el}>
                    <div className="FilterElement">
                        {el}
                    </div>
                    {filters.length > 1 && i < filters.length - 1 ? <p className="FilterElement__plus">+</p> : null}
                </div>
            )
        })
    }

    useEffect(() => {
        if (location.pathname === '/directors-cabinet') {
            setAnalyticsVisible(true);
            if(biDate?.type==="month"){
                let today = moment().format("DD.MM.YYYY");
                let chosenDate = moment().subtract(1, 'months').format("DD.MM.YYYY");;
                const formatStartDate = [today?.slice(0, 4), "-", today?.slice(4, 6), "-", today?.slice(6)].join('');
                const formatEndDate = [chosenDate?.slice(0, 4), "-", chosenDate?.slice(4, 6), "-", chosenDate?.slice(6)].join('');
                setPeriod(prevState => {
                    return {...prevState, startOrigin: formatStartDate, endOrigin: formatEndDate}
                });
            }
        } else {
            setAnalyticsVisible(false);
        }
    }, [analyticsVisible, location])

    useEffect(() => {
        const el = document.getElementsByClassName('PaginationNumber');
        if (el[0]) {
            colorActivePage();
        }
    }, [activePage, colorActivePage]);

    useEffect(() => {
        dispatch(changePagination((activePage - 1) * 10));
    }, [activePage, dispatch]);

    const acceptDatePeriod = () => {
        dispatch(setActivePage(1));
        dispatch(inputFilterDateFrom(period.start));
        dispatch(inputFilterDateTo(period.end));
        setCalendarModal(false);
    }
    const closeModalAccepted = () => {
        dispatch(setActivePage(1));
        setCalendarModal(false);
    }
    return (
          <TITLE title="Поиск" icon="asista">
                <LogoSidebar/>
                <LayoutSearchResults
                    equipmentId={equipmentId}
                    workerName={workerName}
                    companyName={companyName}
                    companyLogo={companyLogo}
                    searchDisabled={searchDisabled}
                    searchRef={searchRef}
                    dateEngineClick={(event,period) => {chooseDateBtnSimple(event,period)}}
                    datePeriodClick={(event,period) => {chooseDateBtnSimple(event,period)}}

                    dateValueStart={dateStart}
                    dateValueEnd={dateEnd}
                    questionShow={hoverShowQuestion}
                    questionHide={hoverHideQuestion}
                    showQuestionModal={showQuestion}
                    questionText={"Ищите по теме или номеру заявки за выбранный период"}

                    isFilter={filters.length > 0}
                    filters={allFilters}
                    morePages={Math.ceil(count / 10) > 1}
                    paginationClickLeft={paginationLeft}
                    paginationClickRight={paginationRight}
                    pagesNumbers={allPages}
                >
                   
                    
                    
                    {calendarModal ?
                        <ModalPeriod
                            closeModalAccepted={closeModalAccepted}
                            closeModal={closeModal}
                            acceptDatePeriod={acceptDatePeriod}
                        />
                        : null}
                    {analyticsVisible ? analyticsCabinet : tableView}
                    
                    </LayoutSearchResults>
        </TITLE >
                 
                        )
                    }

export default WithLoader(SearchResultsPage, axios);