import { useEffect, useMemo, useState } from "react"
import AsistaLogo from "../../assets/logos/Asista_main_logo.svg"
import {ReactComponent as LinkIcon} from "../../assets/icons/copy_link.svg"
import FormInput from "../UI/Form/FormInput/FormInput"
import "./VksInviteModule.css"
import { CONFIG } from "../../constants/backendUrl"
import { useLocation } from "react-router"
import { copyToClipboard } from "../../utils/functions/textFunctions"
import { useDispatch, useSelector } from "react-redux"
import { getVksInviteRomInfo } from "../../Store/VksReducer/vksActions"
import { push } from "connected-react-router"
import { sendYMAnalytics } from "../../utils/metrika"
export default function VksInviteModule (){
    const [userName, setUserName] = useState("")
    const [notifShow, setNotifShow]= useState(false)
    const { vksInviteRoomInfo, vksRoomError } = useSelector(state=>state.vks)
    const { clientName, data } = useSelector(state => state.applications)
    const dispatch = useDispatch()
    const [company, setCompany] = useState("")
    const deviceId = data?.clientId;
    function useQuery() {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery()

    const connectToConference = (e) => {
        e.preventDefault()
        const room = query.get("room");
        sendYMAnalytics("vks-connect-link")
        window.location.assign(`${CONFIG.biBack}/api/vks/join/${room}?user_name=${userName}`)
    }
    const localCopy = () => {
        setNotifShow(true)
        copyToClipboard(window.location.href)
        setTimeout(()=>{
            setNotifShow(false)
        }, 1000)
            
    }
    useEffect(()=>{
        const room = query.get("room");
        dispatch(getVksInviteRomInfo(room))
    }, [])
    useEffect(()=>{
        if(clientName?.name){
            setUserName(clientName?.name)
        }else {
            setUserName("")
        }
    }, [])
    useEffect(()=>{
        setCompany(vksInviteRoomInfo?.companyName)
    }, [vksInviteRoomInfo])

    

    return(
        <div className="VksInviteModule">
            <div className="VksInviteModule__asista_logo">
                <img src={AsistaLogo} />
            </div>
            {
                vksRoomError?
                <div className="VksInviteModule__form VksInviteModule__form_error">
                    <div className="VksInviteModule__error_title">Упс</div>
                    <p className="VksInviteModule__error_text">
                        <br/>Такой конференции нет.
                        <br/>Проверьте правильность ссылки или
                        <br/>попросите у организатора актуальную ссылку на конференцию
                    </p>
                    <div>
                        {
                            data?.clientId && 
                            <button type="button" onClick={()=>dispatch(push(`/prelogin/${deviceId}`))} className="VksInviteModule__blue_button" >
                                На главную
                            </button>
                        }
                        
                    </div>
                    
                </div>
                :
                <form className="VksInviteModule__form" onSubmit={connectToConference}>
                    <div className="VksInviteModule__form_hint">Вы были приглашены для участия в видеоконференции</div>
                    <div className="VksInviteModule__form_title">{company}</div>
                    <FormInput 
                        placeholder="Введите ваше имя"
                        value={userName}
                        onChangeHandler={(e)=>setUserName(e.target.value)}
                        name="conference_name"
                    />
                    <div className="VksInviteModule__form_buttons">
                        <button type="button" onClick={localCopy} className="VksInviteModule__blue_button" >
                            <LinkIcon/>
                            Поделиться ссылкой
                            <div  className={`notification ${notifShow?"":"notif_hidden"}`}>Ссылка скопирована</div>
                        </button>
                        <button type="submit" disabled={!userName}  className="VksInviteModule__form_submit">
                            Присоедениться
                        </button>   


                    </div>
                </form>
            }
        </div>
    )
}