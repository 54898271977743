import React from "react";
import { ReactComponent as Like } from '../../../../assets/icons/like-active.svg';
import { ReactComponent as Dislike } from '../../../../assets/icons/icon-dislike__active.svg';
import moment from "moment";
import { ReactComponent as Comment } from '../../../../assets/icons/ic-comment.svg';
import { Flex } from "antd";
import "./LongestRequest.css"

const LongestRequest = (props) => {
    return(
        <div className='DirectorsCabinet__LongestRequest' onClick={props.onClick}>
			<Flex justify="center" align="center" className="DirectorsCabinet__LongestRequest_preview">Просмотреть заявку</Flex>
			  	  	<p className='LongestRequest__Label'>
			  				Самая трудозатратная заявка за период - {props.most_labor_costs || 0} часов(-a) трудозатрат
			  	  	</p>
			  	  	<div className='LongestRequest__Information__Container'>
			  			<div className='LongestRequest__Date__Container'>
			  			  	<p className='LongestRequest__Date'>{props.date || moment().format('DD/MM/YYYY, HH:ss')}</p>
			  			  	<span className='LongestRequest__Status'>{props.reqStatus || 'Завершено'}</span>
			  			</div>
			  			<div className='LongestRequest__User__Container'>
			  			  	<p className='LongestRequest__User__Name'>{props.employeeName || ''}</p>
			  			  	<p className='LongestRequest__User__ID'></p>
			  			</div>
                        <div className="LongestRequest__subject">
                            {props.reqSubject}
                        </div>
			  			<p className='LongestRequest__ServiceType'>{props.departamentName || ''}</p>
			  			<div className='LongestRequest__Worker__Container'>
			  			  	<p className='LongestRequest__Worker__Name'>{props.workerName || ''}</p>
			  			  	<p className='LongestRequest__Worker__ID'>{props.workerIt || ''}</p>
			  			</div>
						<div className='LongestRequest__Comment__block'>
							<span className='LongestRequest__Comment'>
			  			  		{props.outcome || ''}
			  				</span>
						</div>
										
			  			<div className='LongestRequest__Feedback'>
			  			  	{props.rating_value===-1?<Dislike className='LongestRequest__Dislike' />:props.rating_value===1?<Like className="LongestRequest__Dislike"/>: null}
			  			  	{props.reqComment?<Comment className='LongestRequest__Comment' />:null}
			  			</div>
			  	  	</div>
			  	</div>
    )
}

export default LongestRequest;