import React from 'react';
import './TurquoiseButton.css';

const TurquoiseButton = (props) => {
    return (
        <>
            <button className="TurquoiseButton" onClick={props.clicked}>{props.name}</button>
        </>
    )
}

export default TurquoiseButton;