import React, { useState, useEffect } from 'react';
import ModalWorker from '../ModalWorker/ModalWorker';
import ModalDepartment from '../ModalDepartment/ModalDepartment';
import ModalStatus from '../ModalStatus/ModalStatus';
import NoResults from '../NoResults/NoResults';
import ResultsItemWorker from './ResultsItemWorker/ResultsItemWorker';
import './ResultsTableWorker.css';
import {useDispatch, useSelector} from "react-redux";
import FullApplicationInfo from "../FullApplicationInfo/FullApplicationInfo";
import {
    changePagination,
    getTenApplications,
    setActiveFilters, setActivePage
} from "../../../Store/ApplicationsReducer/applicationsActions";
import {getAllDepartments, getAllEmployees, getAllRegions, getSearchFilters} from "../../../Store/CompanyDataReducer/companyActions";
import WithLoader from '../../../hoc/WithLoader/WithLoader';
import axios from 'axios';
import debounce from 'lodash.debounce'
// import ModalRegions from "../ModalRegions/ModalRegions";

const ResultsTableWorker = (props) => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.applications.data);
    const date = useSelector(state=>state.applications.data.filter.date)
    const userHash = useSelector(state => state.applications.data.hash);


    const handleDebouncedEffect = async () => {
        let reqBody = { ...data, company: 'itsupport' };
        await props.setSearchDisabled()
        await props.setSearchBlur()
        await dispatch(getTenApplications({ data: reqBody, userHash: userHash }));
        await props.setSearchActive()
        await props.setSearchFocus()
    };
    useEffect(() => {
        
        const debouncedEffect = debounce(() => {
            handleDebouncedEffect();
        }, 500);

        debouncedEffect();

        return () => {
            debouncedEffect.cancel();
        };
  }, [data.filter, dispatch, data.start, userHash]);
    useEffect(() => {
        let reqBody = {...data, company: "itsupport"}
        dispatch(getSearchFilters({data:reqBody, userHash: userHash}))
    }, [date]);
    
    const [indexForModal, setIndexForModal] = useState();
    let allApplications;
    const company = useSelector(state => state.company.companyData);

   
    // В редаксе будут хранится фильтры, по отдельности статус и отдел, все что будет выбрано в модальном окне попдает в стэйт и иконка закрашивается в черный
    const isFilterStatus = useSelector(state => state.applications.data['filter'].status.length > 0);
    const isFilterDepartment = useSelector(state => state.applications.data['filter'].department?.length > 0);
    const isFilterWorker = useSelector(state => state.applications.data['filter'].employee !== '');
    // const isFilterRegions = useSelector(state => state.applications.data['filter'].region.length > 0);
    const filters = useSelector(state => state.applications.data['filter']);
    const isFilterDate = useSelector(state => state.applications.isFilterDateActive)

    let filtersCheck = {
        isFilterStatus: useSelector(state => state.applications.data['filter'].status.length > 0) ? "статус" : null,
        isFilterDepartment: useSelector(state => state.applications.data['filter'].department?.length > 0) ? "отдел" : null,
        isFilterRegion: useSelector(state => state.applications.data['filter'].region.length > 0) ? "регион" : null,
        isFilterWorker: useSelector(state => state.applications.data['filter'].employee !== '') ? "сотрудник" : null,
        isFilterNumber: useSelector(state => state.applications.data['filter'].number !== '') ? "поиск" : null,
        isFilterDate: useSelector(state => state.applications.isFilterDateActive) ? "дата" : null
    }

    const showFilters = () => {
        let arr = [];
        Object.keys(filtersCheck).forEach(el => {
            if (filtersCheck[el]) {
                arr.push(filtersCheck[el]);
            }
        })
        dispatch(setActiveFilters(arr));

    };
    useEffect(() => {
        showFilters();
    }, [filters, isFilterDate]);


    const [isStatusModal, setIsStatusModal] = useState(false);
    const [isDepartmentModal, setIsDepartmentModal] = useState(false);
    const [isWorkerModal, setIsWorkerModal] = useState(false);
    const [isRegionModal, setIsRegionModal] = useState(false);
    const [isFullInfoModal, setIsFullInfoModal] = useState(false);

    const toggleStatusModal = () => {
        setIsStatusModal(!isStatusModal);
        setIsDepartmentModal(false);
        setIsWorkerModal(false);
        setIsRegionModal(false);
    }
    const toggleDepartmentModal = () => {
        setIsDepartmentModal(!isDepartmentModal);
        setIsStatusModal(false);
        setIsWorkerModal(false);
        setIsRegionModal(false);
    }
    const toggleWorkerModal = () => {
        setIsWorkerModal(!isWorkerModal);
        setIsStatusModal(false);
        setIsDepartmentModal(false);
        setIsRegionModal(false);
    }
    const toggleRegionsModal = () => {
        setIsRegionModal(!isRegionModal);
        setIsStatusModal(false);
        setIsDepartmentModal(false);
        setIsWorkerModal(false);
    }
    const seeFullApplicationInfo = (index) => {
        setIndexForModal(index)
        setIsFullInfoModal(!isFullInfoModal);
        setIsRegionModal(false);
        setIsStatusModal(false);
        setIsDepartmentModal(false);
        setIsWorkerModal(false);
    }

    const applications = useSelector(state => state.applications.applications);

    if (applications ? applications.length : null) {
        allApplications = applications?.map((el, i) => {
            const status = el?.status?.name_rus;
            const solution = el?.outcome? el?.outcome?.substring(0, 50) + "...": "";
            return <ResultsItemWorker
                key={el._id}
                isLastFrame={applications.length - 1 === i}
                statusColor={status === 'Запланировано' ? "#E82024" : status === 'В работе' ? "#F3BB1C" : status === 'Завершено' ? "#3CC13B" : status === 'Отменено' ? '#828282' : status === 'Пауза' ? '#00ABCD' : null}
                date={new Date(el.created_at).toLocaleDateString() + ", "+ new Date(el.created_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                status={status?.toLowerCase()}
                subject={el.subject}
                region={el.region}
                department={el.department?.name_rus}
                specialist={el.implementer?.name_rus}
                specialistId={el.implementer?.it}
                contentShort={solution}
                index={i}
                classLikeDislike={el.rating_value === 1 ? "like" : el.rating_value === -1 ? "dislike" : ''}
                isComment={el.rating_comment?true: false}
                openSeeDetails={() => {seeFullApplicationInfo(i)}}
                isDirector={!!company.director}
                workerId={el.employee_device_id}
                worker={el.employee?.name}
                commentMessage={el.rating_comment}
            />
        });
    }else {
        allApplications = (
            <NoResults />
        )
    }

    const currentPage = useSelector(state => state.applications.data['start']);
    useEffect(() => {
        dispatch(setActivePage((currentPage + 10) / 10));
        if (indexForModal >=9) {
            setIndexForModal(0);
        }
        if (indexForModal <=0) {
            setIndexForModal(9);
        }
    }, [currentPage]);

    const goLeft = async () => {

        if (indexForModal <= 0 && currentPage === 0) return;

        if (indexForModal <=0) {
            await dispatch(changePagination(currentPage - 10));
            return
        }
        const index = indexForModal - 1;
        setIndexForModal(index);
    };
    const activePage = useSelector(state => state.applications.activePage)
    const count = useSelector(state => state.applications.count);
    const goRight = async () => {
        if (indexForModal >= applications.length - 1 && activePage >= Math.ceil(count / 10)) return;

        if (indexForModal >=9) {
            await dispatch(changePagination(currentPage + 10));
            return
        }
        const index = indexForModal + 1;
        setIndexForModal(index);
    };

    return (
        <div className="ResultsTableWorker">
            <div className="ResultsTableWorker__filtersBlock">

                <div className="ResultsTableWorker__filterWithIcon ResultsTableWorker__filter--status">
                    <p className="ResultsTableWorker__filterText">Дата/статус</p>
                    {isStatusModal ? <ModalStatus close={toggleStatusModal} /> : null}
                    <div onClick={toggleStatusModal} className={`ResultsTableWorker__filterIcon ${isFilterStatus ? "ResultsTableWorker__filterIcon--active" : null}`} />
                </div>

                {company.director ? <div className="ResultsTableWorker__filterWithIcon ResultsTableWorker__filter--worker">
                    <p className="ResultsTableWorker__filterText">Сотрудник</p>
                    {isWorkerModal ? <ModalWorker close={toggleWorkerModal} /> : null}
                    <div onClick={toggleWorkerModal} className={`ResultsTableWorker__filterIcon ${isFilterWorker ? "ResultsTableWorker__filterIcon--active" : null}`} />
                </div> : null}

                <p className="ResultsTableWorker__filterText ResultsTableWorker__filter--subject">Тема</p>

                <div className="ResultsTableWorker__filterWithIcon ResultsTableWorker__filter--department">
                    <p className="ResultsTableWorker__filterText">Отдел</p>
                    {isDepartmentModal ? <ModalDepartment close={toggleDepartmentModal} /> : null}
                    <div onClick={toggleDepartmentModal} className={`ResultsTableWorker__filterIcon ${isFilterDepartment ? "ResultsTableWorker__filterIcon--active" : null}`} />
                </div>

                <p className="ResultsTableWorker__filterText ResultsTableWorker__filter--specialist">ИТ-специалист</p>
                <p className="ResultsTableWorker__filterText ResultsTableWorker__filter--results">Результат</p>
            </div>
            {isFullInfoModal ?
                <>
                    <div className="FullInfoModalBg" onClick={() => {seeFullApplicationInfo(indexForModal)}} />
                    <FullApplicationInfo
                        clickToClose={() => {seeFullApplicationInfo(indexForModal)}}
                        application={applications[indexForModal]}
                        goLeft={goLeft}
                        goRight={goRight}
                        first={indexForModal <= 0 && currentPage === 0}
                        last={indexForModal >= applications.length - 1 && activePage >= Math.ceil(count / 10)}
                        commentMessage={applications[indexForModal] ? applications[indexForModal].rating_comment : ""}
                    />
                </>:
                null}
            {allApplications}
        </div>
    )
}



export default WithLoader (ResultsTableWorker, axios);