import { Redirect, Route, Switch } from 'react-router';
import ErrorWindow from './Components/ApplicationPageComponents/ErrorWindow/ErrorWindow';
import { Helmet } from "react-helmet";
import ApplicationPage from './Containers/ApplicationPage/ApplicationPage';
import SearchResultsPage from './Containers/SearchResultsPage/SearchResultsPage';
import UserLoginPage from './Containers/UserLoginPage/UserLoginPage';
import { useDispatch, useSelector } from 'react-redux';
import OpenAccessPage from './Containers/OpenAccessPage/OpenAccessPage';
import PersonalProfilePage from './Containers/PersonalProfilePage/PersonalProfilePage.js';
import TrialForm from './Components/TrialTicketComponents/TrialForming/TrialForm';
import MainPage from './Containers/MainPage/MainPage.js';
import UndefinedIDPage from './Containers/UndefinedIDPage/UndefinedIDPage';
import {BellOutlined} from "@ant-design/icons"
import InvisionTrialForm from './Components/TrialTicketComponents/InvisionTrialForming/InvisionTrialForm';
import RestoreLastSession from './Components/ApplicationPageComponents/RestoreLastSession/RestoreLastSession';
import CloseAccessPage from './Containers/CloseAccessPage/CloseAccessPage';
import ChangeAccessPage from './Containers/ChangeAccessPage/ChangeAccessPage';
import TestPage from './Containers/TestPage/TestPage';
import Container from './Components/UI/Container/Container';
import { Translate } from './Components/UI/Translate/Translate';
import VksInvitePage from './Containers/VksInvitePage/VksInvitePage';
import { ICONS } from './Routes/TITLE';
import ModalWindow from './Components/UI/ModalWindow/ModalWindow.js';
import PatchNotes from './Components/PatchNotes/PatchNotes.js';
import { setShowPatchNotes } from './Store/GeneralReducer/generalActions.js';
import { Badge } from 'antd';
import { useEffect, useState } from 'react';
import { initialPatchNotes } from './Components/PatchNotes/patchNotes_items.js';
import { saveHash } from './Store/ApplicationsReducer/applicationsActions.js';
function App() {
	const forgetMe = useSelector((state) => state.applications.forgetMe);
	const dispatch = useDispatch();
	const {showPatchNotes, patchesRead} = useSelector(state => state.general)
	const [unreadPatches, setUnredPatches] = useState(initialPatchNotes.length - (localStorage.getItem('patchNotesReadCount') || 0))
	// Это событие на кнопке "Запомнить Меня"
	if (forgetMe) {
		window.addEventListener('beforeunload', function (e) {
			dispatch(saveHash(""))
			return undefined;
		});
	}
	useEffect(()=>{
		setUnredPatches(initialPatchNotes.length - localStorage.getItem('patchNotesReadCount') || 0)
	}, [patchesRead])

	return (
		<div className='app'>
			{/* <div className='app_bg'></div> */}
			<Helmet>
				<link rel="icon" href={ICONS.chat} />
                <title>Заявка в Asista</title>
            </Helmet>
			<Container>
				<div className='App__utils'>
					<Badge count={unreadPatches} className='App__notifications' onClick={()=>dispatch(setShowPatchNotes(true))}>
						<BellOutlined style={{fontSize: 20}} />
					</Badge>
					<Translate/>
				</div>
				
			</Container>
			<Switch>
				<Route path='/' exact component={SearchResultsPage} />
				<Route path='/search' exact component={SearchResultsPage} />
				<Route path='/login' exact component={UserLoginPage} />
				<Route path='/login/:id' exact component={UserLoginPage} />
				<Redirect from="/application/:id/new" to="/prelogin/:id" />
				<Route path='/application/:id/:hash' component={ApplicationPage} />
				<Route path='/error' component={ErrorWindow} />
				<Route path='/testpage' component={TestPage}/>
				<Route path='/:id/access/open' component={OpenAccessPage} />
				<Route path='/:id/access/close' component={CloseAccessPage} />
				<Route path='/:id/access/change' component={ChangeAccessPage} />
				<Route path='/prelogin/:id' component={MainPage} />
				<Route path='/profile' component={PersonalProfilePage} />
				{/* <Route path='/conference' component={ConferencePage} /> */}
				<Route path='/directors-cabinet' component={SearchResultsPage} />
				<Route path='/undefined' component={UndefinedIDPage}/>
				<Route path='/:id/trial/:hash' component={TrialForm}/>
				<Route path='/:id/trialinvision/:hash' component={InvisionTrialForm}/>
				<Route path='/restore-session' component={RestoreLastSession}/>
				<Route path='/conference/invite' component={VksInvitePage}/>
				<Route path='/test' component={TestPage}/>
			</Switch>
			<ModalWindow
                modalStatus = {showPatchNotes}
                bgOnClick= {()=>dispatch(setShowPatchNotes(false))}
                contentWidth="100%"
            >
				<div style={{width: '60vw'}}>я
					<PatchNotes/>
				</div>
                
            </ModalWindow>
		</div>
	);
}

export default App;
