import { useDispatch, useSelector } from "react-redux"
import Hint from "../../../UI/Hint/Hint";
import "./ChatAddFiles.css"
import { setAddFileModal } from "../../../../Store/ApplicationsReducer/applicationsActions";
import { useRef, useState } from "react";
import BlueButton from "../../BlueButton/BlueButton";
import FileBlock from "./FileBlock/FileBlock";
import {ReactComponent as FilesUpload} from "../../../../assets/icons/add_files.svg"
import { setChatFiles } from "../../../../Store/ChatReducer/chatActions";
export default function ChatAddFiles(props){
    const addFilesModal = useSelector(state=>state.applications.addFileModal)
    const dispatch = useDispatch();
    const files = useSelector(state=>state.chat.chatAttachedFiles)
    const fileInputRef = useRef(null);
    const [filesHint, setFilesHint] = useState(false)
    const handleAddFilesClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };
  
    const handleFilesSelected = (event) => {
      const files = event.target.files;
      const filesArray = Array.from(files);
      dispatch(setChatFiles(filesArray))
    };
    return(
        <div className={`ChatAddFiles__wrapper ${addFilesModal?"ChatAddFiles__wrapper_active":""}`}>
            <div className={`ChatAddFiles__bg ${addFilesModal?"ChatAddFiles__bg_active":""}`} onClick={()=>dispatch(setAddFileModal(false))}/>
            <div className={`ChatAddFiles__modal ${addFilesModal?"ChatAddFiles__modal_active":""}`}>
                <div className="ChatAddFiles__modal_title" onClick={handleAddFilesClick}>
                    <FilesUpload className="ChatAddFiles__modal_title_icon"/>
                    <span className="ChatAddFiles__modal_title_text">Добавить файлы</span>
                    <Hint hintState = {filesHint} setHintShow={()=>setFilesHint(true)} setHintHidden={()=>setFilesHint(false)} hint = {"Прикрепите необходимые по вашему мнению файлы"}/>
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFilesSelected}
                    multiple
                />
                <div className="ChatAddFiles__files">
                    {
                        files?.map((file, index)=>{
                            return(
                                <FileBlock
                                    key={"file"+index}
                                    onClickDeleteHandler={()=>files.splice(index, 1)}
                                    filename = {file.name}
                                    size= {`${file.size}kb`}
                                />
                            )
                        })
                    }
                </div>
                <span className="ChatAddFiles__hint">Отправьте файлы, не превышающие общий объем 50 мб</span>
                <BlueButton 
                    isDisabled={files.length>0?false:true}
                    name="Отправить"
                    clicked={props.onClickHandler}
                />
            </div>
        </div>
    )
}