import React, { useEffect } from "react";
import { useState } from "react";
import "./MiniTable.css"

const MiniTable = (props) => {

    const [showFullTable, setShowFullTable] = useState(false)
    const [data, setData] = useState(props.dataArr)

    const handleShowList = () => setShowFullTable(!showFullTable)

    useEffect(()=>{
        if(showFullTable){
            setData(props.dataArr)
        }else{
            setData(props.dataArr?.slice(0,3))
        }
    }, [showFullTable, props.dataArr])

    return (
        <div className="MiniTable">
            <div className="MiniTable__title">{props.tableTitle}</div>
            <div className="MiniTable__row MiniTable__row_subtitles">
                <div className="MiniTable__block block_name">
                    <div className="MiniTable__subtitle">{props.subtitle_1}</div>
                </div>
                <div className="MiniTable__block statistic_data">
                    <div className="MiniTable__subtitle">{props.subtitle_2}</div>
                </div>
                <div className="MiniTable__block statistic_data">
                    <div className="MiniTable__subtitle">{props.subtitle_3}</div>
                </div>
                <div className="MiniTable__block statistic_data">
                    <div className="MiniTable__subtitle">{props.subtitle_4}</div>
                </div>
                <div className="MiniTable__block statistic_data">
                    <div className="MiniTable__subtitle">{props.subtitle_5}</div>
                </div>
                <div className="MiniTable__block statistic_data">
                    <div className="MiniTable__subtitle">{props.subtitle_6}</div>
                </div>

            </div>

            <div className="MiniTable__list">
                <div className="MiniTable__list_block" >
                        <div className="MiniTable__list_content">
                            {
                                data?.map(object => <MiniTableRow key={object?.employee?.uuid} object={object} />)
                            }
                        </div>
                </div>
            </div>
            <div className="MiniTable__showFullTable" onClick={handleShowList}>
                {!showFullTable ? "...показать все" : "...скрыть"}
            </div>
        </div>
    )
}

function MiniTableRow({ object }) {
    return <div className="MiniTable__row">
        <div className="MiniTable__block block_name">
            <div className="MiniTable__data">{object.employee?.name}</div>
        </div>
        <div className="MiniTable__block statistic_data notranslate">
            <div className="MiniTable__data">{object.applications}</div>
        </div>
        <div className="MiniTable__block statistic_data notranslate">
            <div className="MiniTable__data">{object.likes}</div>
        </div>
        <div className="MiniTable__block statistic_data notranslate">
            <div className="MiniTable__data">{object.dislikes}</div>
        </div>
        <div className="MiniTable__block statistic_data notranslate">
            <div className="MiniTable__data">{object.comments}</div>
        </div>
        <div className="MiniTable__block statistic_data notranslate">
            <div className="MiniTable__data">{object.labor_hours}</div>
        </div>
    </div>
}

export default MiniTable;