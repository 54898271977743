import React, { useState, useEffect } from 'react';
import ButtonGrey from '../UI/ButtonGrey/ButtonGrey';
import './ModalDepartment.css';
import {changeDepartment, getTenApplications, setActivePage} from "../../../Store/ApplicationsReducer/applicationsActions";
import {useDispatch, useSelector} from "react-redux";


const ModalDepartment = (props) => {
    const dispatch = useDispatch();

    const departments = useSelector(state => state.company.departments);
    const reqData = useSelector(state => state.applications.data);
    const userHash = useSelector(state => state.applications.data.hash);
    const departmentActiveFilters = useSelector(state => state.applications.data.filter.department);
    useEffect(() => {

    }, []);
    const showDepartmentSearchResults = (event) => {
        event.preventDefault();
        dispatch(getTenApplications({data:{...reqData, company: "itsupport"}, userHash: userHash}))
        props.close();
        };

    const inputChange = (event, uuid) => {
        const name = event.target.name;
        const newArr = [...departmentActiveFilters]
        departmentActiveFilters.includes(uuid)?newArr.splice(newArr.indexOf(uuid), 1):newArr.push(uuid)
        dispatch(changeDepartment(newArr))
    }
    let allDepartments = null;
    if (departments.length) {
        allDepartments = departments.map((el, i) => {
           return   <label key={i} className="ModalDepartment__label">
                       <input checked={departmentActiveFilters.includes(el.uuid)}  name={el.name_rus} className="ModalDepartment__input" onChange={(event) => {inputChange(event, el.uuid)}} type="checkbox" />
                       <div  className="ModalDepartment__label--icon"/>
                       <p className="ModalDepartment__department">{el.name_rus}</p>
                   </label>
        });
    }

    return (
        <>
        <div onClick={props.close} className="ModalDepartment__bg" />
            <div className="ModalDepartment">
                <div className="ModalDepartment__header">
                    <p className="ModalDepartment__header--text">Показывать только:</p>
                </div>
                <div className="ModalDepartment__content">
                    <form className="ModalDepartment__form" onSubmit={(event) => {showDepartmentSearchResults(event)}}>
                        {allDepartments}
                        <ButtonGrey 
                            name="показать"
                        />
                    </form>
                </div>
            </div>
            </>
    )
}


export default ModalDepartment;