import axios from "../../axiosApi";
import WithLoader from "../../hoc/WithLoader/WithLoader";
import './CloseAccessPage.css';
import {useDispatch, useSelector} from "react-redux";
import { useEffect, useState } from "react";
import LayoutApplicationPage from "../../Components/ApplicationPageComponents/LayoutApplicationPage/LayoutApplicationPage";
import {LogoSidebar} from "../../constants/UI/LogoSidebar";
import {push} from "connected-react-router";
import PreviousApplicationMenu from "../../Components/ApplicationPageComponents/PreviousApplicationMenu/PreviousApplicationMenu";
import {closeAccessTask, setApplicationBackInProgress} from "../../Store/ApplicationsReducer/applicationsActions";
import FunctionBlock from "../../Components/UI/FunctionBlock/FunctionBlock";
import OpenAccessForm from "../../Components/OpenAccessPageComponent/OpenAccessForm/OpenAccessForm";
import FormBlock from "../../Components/UI/FormBlock/FormBlock";
import FormInput from "../../Components/UI/Form/FormInput/FormInput";
import FormSelect from "../../Components/UI/Form/FormSelect/FormSelect";
import RadioInput from "../../Components/UI/Form/RadioInput/RadioInput";
import FormBlockTitle from "../../Components/UI/FormBlock/FormBlockTitle/FormBlockTitle";
import Checkbox from "../../Components/UI/Form/Checkbox/Checkbox";
import HrModalWindow from "../../Components/OpenAccessPageComponent/HRModalWindow/HrModlaWindow";
import FormCommentInput from "../../Components/UI/Form/FormCommentInput/FormCommentInput";
import AddInput from "../../Components/OpenAccessPageComponent/AddInput/AddInput";
import ModalPeriod from "../../Components/SearchResultsComponents/ModalPeriod/ModalPeriod";
import Hint from "../../Components/UI/Hint/Hint";
import BlueButton from '../../Components/ApplicationPageComponents/BlueButton/BlueButton'
import { getClientName, saveId, getLastApplication} from "../../Store/ApplicationsReducer/applicationsActions";
import { supportAsista } from "../../constants/clientRoute";
import RedButton from "../../Components/UI/RedButton/RedButton";
import WhiteButton from "../../Components/ApplicationPageComponents/WhiteButton/WhiteButton";

const CloseAccessPage = (props) => {
    const lastApplication = useSelector(state => state.applications.lastApplication);
    let leftSide;
    const id = props.match.params.id;
    const [hrModal, setHrModal] = useState(false);
    const dispatch = useDispatch();
    const [hintState, setHintState] = useState({
        emailGroup: false,
        blockAccount: false
    })
    const [isExpiredDate, setIsExpiredDate] = useState(false);
    const dateExpirationHandler = (date) => {
        let currMonth = new Date().getMonth() + 1;
        let currDay = new Date().getUTCDate();
        let applicationDateMonth = Number(date.slice(3, 5));
        let applicationDateDay = Number(date.slice(0, 3));
        let diff =
            (currMonth - applicationDateMonth) * 31 + currDay - applicationDateDay;
        if (Math.abs(diff) >= 31) {
            setIsExpiredDate(true);
        }
    };

    const [closeAccessState, setCloseAccessState] = useState(
        {
            applicationType: "closeAccess",
            user: {
              fullname: ""
            },
            device: {
              id: ""
            },
            email: {
              value: "",
              task: ""
            },
            access: {
              server: {
                blockAccount: false,
                blockRemoteAccess: false
              },
              corpPhone: {
                block: "",
                number: ""
              }
            },
            revokeLicenses: "",
            comment: "",
            senderId: id
          }
    )

    useEffect(() => {
        dispatch(getClientName(id));
        dispatch(getLastApplication(id));
        dispatch(saveId(id));
        if (lastApplication?.date !== null && lastApplication?.date)
            dateExpirationHandler(lastApplication.date);

    }, [dispatch, id]);

    const clientName = useSelector(state=>state.applications.clientName)
    const [disabled, setDisabled] = useState(true);
    const [inputState, setInputState] = useState();
    const submitHandler = async (event) => {
        event.preventDefault();
        dispatch(closeAccessTask(closeAccessState, id))
    }
    
    
    const isBackInProgressHandler = () => {
        dispatch(setApplicationBackInProgress(lastApplication.ref));
    }

    if (lastApplication && !!lastApplication.result) {
        leftSide = (
            <>
                <PreviousApplicationMenu
                    title= "Предыдущая заявка"
                    name={lastApplication.topic}
                    date={lastApplication.date}
                    description=""
                    buttonName="Вернуть в работу"
                    clicked={isBackInProgressHandler}
                />
                {
                clientName.access.includes('employers')?
                <WhiteButton
                    name="Прием или увольнение сотрудника"
                    clicked={()=>{setHrModal(true)}}
                />:null
                }
            </>
        )
    }
    

    const center = (
        <>
            <OpenAccessForm
                onSubmit={submitHandler}
                title = "Запрос на закрытие доступов"
            >
                <FormBlock title = "Данные пользователя">
                    <FormInput
                        name = "fullname"
                        label = "ФИО пользователя *"
                        required
                        value = {closeAccessState.user.fullname}
                        onChangeHandler = {(e)=>{setCloseAccessState({...closeAccessState}, closeAccessState.user.fullname = e.target.value)}}
                    />
                </FormBlock>
                <FormBlock title = "Данные оборудования">
                    <FormInput
                        name = "device_id"
                        label = "ID оборудования"
                        value = {closeAccessState.device.id}
                        onChangeHandler = {(e)=>{setCloseAccessState({...closeAccessState}, closeAccessState.device.id = e.target.value)}}
                    />
                </FormBlock>
                <FormBlock title = "Учетная запись почты">
                    <FormInput
                        name = "emailValue"
                        placeholder = "i.familiya@domain.com"
                        value = {closeAccessState.email.value}
                        onChangeHandler = {(e)=>{setCloseAccessState({...closeAccessState}, closeAccessState.email.value = e.target.value)}}
                    />
                    {
                        closeAccessState.email.value?
                        <div className="horizontal_radio">
                            <RadioInput
                                required={closeAccessState.email.value?true:false}
                                isCheckedState={closeAccessState.email.task==="Заблокировать"}
                                onChangeHandler = {
                                    ()=>{
                                        setCloseAccessState({...closeAccessState}, closeAccessState.email.task="Заблокировать")
                                    }
                                }
                                label='Заблокировать'
                                radioGroup = 'blockEmail'
                            />
                            <RadioInput
                                required={closeAccessState.email.value?true:false}
                                isCheckedState={closeAccessState.email.task==="Сменить пароль"}
                                onChangeHandler = {
                                    ()=>{
                                        setCloseAccessState({...closeAccessState}, closeAccessState.email.task="Сменить пароль")
                                    }
                                }
                                label='Сменить пароль'
                                radioGroup = 'blockEmail'
                            />
                        </div>:null
                    }
                    
                </FormBlock>
                <FormBlock title = "Доступ к серверу">
                    <div className="horizontal__flex">
                        <Checkbox 
                            isCheckedState = {closeAccessState.access.server.blockAccount}
                            onChangeHandler = {
                                ()=>{
                                    setCloseAccessState({...closeAccessState}, closeAccessState.access.server.blockAccount=!closeAccessState.access.server.blockAccount)
                                }
                            }
                            label = 'Заблокировать учетную запись на сервере'
                        />
                        <Hint
                            hint='Поставьте галочку, если необходимо закрыть доступ к удаленному рабочему столу на сервере (RDP/RemoteApp)'
                            setHintShow = {()=>{setHintState({...hintState, blockAccount: true})}}
                            setHintHidden={()=>{setHintState({...hintState, blockAccount: false})}}
                            hintState={hintState.blockAccount}
                        />
                    </div>
                    
                    <Checkbox
                        isCheckedState = {closeAccessState.access.server.blockRemoteAccess}
                        onChangeHandler = {
                            ()=>{
                                setCloseAccessState({...closeAccessState}, closeAccessState.access.server.blockRemoteAccess=!closeAccessState.access.server.blockRemoteAccess)
                            }
                        }
                        label = 'Закрыть внешний доступ к серверу'
                    />   
                    
                </FormBlock>
                <FormBlock title="Доступ к корпоративной телефонии ">
                    <div className="horizontal_radio">
                        <RadioInput
                            isCheckedState={closeAccessState.access.corpPhone.block && typeof(closeAccessState.access.corpPhone.block)==="boolean"}
                            onChangeHandler = {
                                ()=>{
                                    setCloseAccessState({...closeAccessState}, closeAccessState.access.corpPhone.block = true)
                                }
                            }
                            label='Заблокировать'
                            radioGroup = 'blockCorpPhone'
                        />
                        <RadioInput
                            isCheckedState={!closeAccessState.access.corpPhone.block && typeof(closeAccessState.access.corpPhone.block)==="boolean"}
                            onChangeHandler = {
                                ()=>{
                                    setCloseAccessState({...closeAccessState}, closeAccessState.access.corpPhone.block = false)
                                }
                            }
                            label='Нет необходимости'
                            radioGroup = 'blockCorpPhone'
                        />
                    </div>
                    {closeAccessState.access.corpPhone.block?
                        <>
                            <FormInput
                                value = {closeAccessState.access.corpPhone.number}
                                onChangeHandler = {(e)=>{setCloseAccessState({...closeAccessState}, closeAccessState.access.corpPhone.number=e.target.value)}}
                                name = 'inlinePhone'
                                placeholder = "123"
                            /> 
                        </>: null
                    }
                                        
                </FormBlock>
                <FormBlock title="Отозвать лицензии">
                    <FormInput 
                        label = "Название программного обеспечения" 
                        name="revokeLicences" 
                        value = {closeAccessState.revokeLicenses}
                        onChangeHandler = {(e)=>{
                            setCloseAccessState({...closeAccessState, revokeLicenses: e.target.value})
                        }}
                    />
                </FormBlock>
                <FormBlock title="Дополнительный комментарий">
                    <div className="ResultBlock">
                        <FormCommentInput
                            placeholder="Дополнительный комментарий"
                            name = 'comment1'
                            value = {closeAccessState.comment}
                            onChangeHandler = {e => {setCloseAccessState({...closeAccessState, comment: e.target.value})}}
                        />
                        <BlueButton
                            type="submit"
                            name='Отправить заявку'
                        />
                    </div>
                </FormBlock>
                
            </OpenAccessForm>
            {
                    hrModal?
                    <HrModalWindow 
                        bgOnClick = {()=>{setHrModal(false)}}
                        modalStatus = {hrModal}
                        id={id}
                    />
                    :null
                }
        </>
    )

    const left = (
        <>
            {leftSide}
        </>
    )

    return (
        <div>
            <LogoSidebar/>
            <LayoutApplicationPage createNewApplication={()=>{dispatch(push(`/prelogin/${id}`))}} left={left} center={center}> </LayoutApplicationPage>
        </div>
    )
}

export default WithLoader(CloseAccessPage, axios);