import React, {useState} from 'react';
import './ReviewPopUp.css';

import BlueButton from '../BlueButton/BlueButton';

import {ReactComponent as LikeBtn} from '../../../assets/icons/icon-like.svg';
import {ReactComponent as DislikeBtn} from '../../../assets/icons/icon-dislike.svg';
import {ReactComponent as LikeActiveBtn} from "../../../assets/icons/like-active.svg";
import {ReactComponent as DislikeActiveBtn} from "../../../assets/icons/icon-dislike.svg";

const ReviewPopUp = (props) => {
    const [textAreaNotEmpty, toggleTextArea] = useState(false);

    // Если sendRequest = false компонент рендерит готовый отзыв
    // Если sendRequest = true компонент рендерит форму для отправки отзыва
    const generatePopUpFooter = () => {
        return !props.commentButtonVissibility ? (
            <form className='ReviewPopUp__Form'>
                <textarea
                    className='ReviewPopUp__Form__Textarea'
                    value={props.commentValue}
                    placeholder="Пожалуйста, напишите несколько слов о том что понравилось в нашей работе, а что можно сделать лучше. Это поможет улучшить наш сервис."
                    onChange={(e) => {
                        e.target.value.length > 0
                            ? toggleTextArea(true)
                            : toggleTextArea(false);
                        props.onChangeComment(e.target.value)
                    }}/>
                {/* <span
                    className={`ReviewPopUp__Form__Textarea__Label No__Select ${
                        textAreaNotEmpty && 'Display__None'
                    }`}>
                      Пожалуйста, напишите несколько слов о том что понравилось в нашей
                      работе, а что можно сделать лучше. Это поможет улучшить наш сервис.
                </span> */}

                <BlueButton clicked = {props.clicked} type='button' name='Оставить отзыв'/>

            </form>
        ) : (
            <div className='ReviewPopUp__Review'>
                <p className='ReviewPopUp__Review__Label'>Отзыв клиента</p>
                <span>
                    {props.currentApplication?.commentstr}
                </span>
                <div className='ReviewPopUp__Review__Footer'>
                    <div className='ReviewPopUp__Review__Credits'>
                        <p>{props.clientName}</p>
                        <span>ID устройства {props.id}</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='ReviewPopUp__Container'>
            <div className='ReviewPopUp__PhotoMeta__Rating'>
                <div className='ReviewPopUp__Photo__Meta'>
                    <div className='ReviewPopUp__Photo No__Select'>
                        <img
                            className='ReviewPopUp__Avatar'
                            src={props.photo}
                            alt={props.name}
                        />
                    </div>
                    <div className='ReviewPopUp__Meta No__Select'>
                        <p className='ReviewPopUp__Name'>{props.name}</p>
                        <div className='ReviewPopUp__Department_Block'>
                            <p className='ReviewPopUp__Department'>IT {props.currentApplication.phonenumber}</p>
                            <p className='ReviewPopUp__Phone'>+7 727 390 1112</p>
                        </div>
                        <p className='ReviewPopUp__RequestNumber'>
                            Заявка № {props.currentApplication.humanId}
                        </p>
                    </div>
                </div>
                <div className='options'>
                    { <button
                        className={`reviewLikeBtn ${props.like ? "reviewLikeBtn-active": ""}`}
                        onClick={props.isLikeHandler}
                        disabled={props.like||props.dislike? true: false}
                        />}
                    {<button
                        className={`reviewDislikeBtn ${props.disLike ?"reviewDislikeBtn-active": ""}`}
                        onClick={props.isDisLikeHandler}
                        disabled={props.like||props.dislike ? true: false}
                        />
                    }
                
                </div>
            </div>
            {generatePopUpFooter()}
        </div>
    );
};

export default ReviewPopUp;
