import React from "react";
import './ModalWindow.css'
const ModalWindow = (props) =>{
    
    return(
        <>
            <>
                {props.modalStatus && (
                    <div className={"modal_wrapper"}>
                        <div className="modal_bg" onClick={props.bgOnClick} ></div>
                        <div className="modal" >
                            <div className="ModalBase_content Calendar_Scrollbar">
                                
                                {props.children}
                            </div>
                        </div>
                    </div>
                )}
            </>
        </>
        
    )
}

/*
    props.bgOnClick - событие по клику на фон
    props.modalStatus - статус модального окна (закрытое / открытое)
    props.children - элемент для того, чтобы внутрь этого контейнера можно было вставлять другие html - элементы
*/


export default ModalWindow;