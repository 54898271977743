import React, { useState, useEffect } from 'react';
import ButtonGrey from '../../../SearchResultsComponents/UI/ButtonGrey/ButtonGrey';
import './BIDepartment.css';
import {changeDepartment, setActivePage} from "../../../../Store/ApplicationsReducer/applicationsActions";
import {useDispatch, useSelector} from "react-redux";
import { setBiActiveFilters } from '../../../../Store/BIReducer/biActions';


const BIDepartment = (props) => {
    const dispatch = useDispatch();

    const departments = useSelector(state => state.bi.departmentfilters);
    const biActiveFilters = useSelector(state=>state.bi.activeFilters)
    const [inputState, setInputState] = useState({});

    useEffect(() => {
        let obj = {}
        
        for(let i = 0; i < departments?.length; i++){
            for(let j = 0; j < biActiveFilters?.department_uuid?.length; j++){
                if(biActiveFilters?.department_uuid[j]===departments[i].uuid){
                    obj[departments[i].name_rus]=departments[i].uuid
                }
            }
        }
        setInputState(obj);
    }, [departments, biActiveFilters]);

    const showDepartmentSearchResults = (event) => {
        event.preventDefault();
        let arr = [];
        Object.keys(inputState).forEach(el => {
            if (inputState[el]) {
                arr.push(inputState[el]);
            }
            dispatch(setBiActiveFilters({...biActiveFilters, department_uuid: arr}))
            props.close();
        });
    }

    const inputChange = (event, uuid) => {
        const name = event.target.name;
        if (!event.target.checked) {
            setInputState(prevState => {
                return {...prevState, [name]: ""}
            });
        } else {
            setInputState(prevState => {
                return {...prevState, [name]: uuid}
            });
        }
    }
    let allDepartments = null;
    if (departments.length) {
        allDepartments = departments.map((el, i) => {
           return   <label key={i} className="BIDepartment__label">
                       <input checked={inputState[el.name_rus]}  name={el.name_rus} className="BIDepartment__input" onChange={(event) => {inputChange(event, el.uuid)}} type="checkbox" />
                       <div  className="BIDepartment__label--icon"/>
                       <p className="BIDepartment__department">{el.name_rus}</p>
                   </label>
        });
    }

    return (
        <>
        <div onClick={props.close} className="BIDepartment__bg" />
            <div className="BIDepartment">
                <div className="BIDepartment__header">
                    <p className="BIDepartment__header--text">Показывать только:</p>
                </div>
                <div className="BIDepartment__content">
                    <form className="BIDepartment__form" onSubmit={(event) => {showDepartmentSearchResults(event)}}>
                        {allDepartments}
                        <ButtonGrey 
                            name="показать"
                        />
                    </form>
                </div>
            </div>
            </>
    )
}


export default BIDepartment;