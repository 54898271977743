import './FAQHeader.css';

const FAQHeader = () => {
    return (
        <div className='Faq-header_container'>
            <h2 className='faq-header-title-text'>Типовые ИТ вопросы, которые вы можете решить самостоятельно!</h2>
            <p className='faq-header-info-text'>Вы можете не ждать ответа наших ИТ специалистов, а попробовать разобраться самостоятельно, мы собрали самые простые и частые вопросы, решение которых займет у вас от одной до пяти минут. Если ничего не сработало, пожалуйста обратитесь к специалистам IT Support Group.</p>
        </div>
    )
}

export default FAQHeader;