import React from "react";

const TextLogo = (props) =>{
    return(
        <div className="TextLogo">
            <img className="TextLogo__img" src={props.textLogo}/>
        </div>
    )
}

export default TextLogo;