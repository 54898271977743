import axios from "../../axiosApi";
import { CONFIG } from "../../constants/backendUrl";
import { SET_BI_ACTIVE_FILTERS, SET_BI_DATA, SET_BI_DATA_ERROR, SET_BI_DATE, SET_BI_DEPARTMENT_FILTERS, SET_BI_LOADER, SET_BI_REGION_FILTERS, SET_BI_WORKER_FILTERS, SET_INTEGRATOR, SET_INTEGRATOR_LOADER } from "./biActionTypes";
import axiosOriginal from "axios";
export const setBiData = (value) => ({type: SET_BI_DATA, value})
export const setBiDate = (value) => ({type: SET_BI_DATE, value})
export const setBiRegionFilters = (value) => ({type: SET_BI_REGION_FILTERS, value})
export const setBiWorkerFilters = (value) => ({type: SET_BI_WORKER_FILTERS, value})
export const setBiDepartmentFilters = (value) => ({type: SET_BI_DEPARTMENT_FILTERS, value})
export const setBiActiveFilters = (value) => ({type: SET_BI_ACTIVE_FILTERS, value})
export const setBiDataError = (value) => ({type: SET_BI_DATA_ERROR, value})
export const setBiLoader = (value) => ({type: SET_BI_LOADER, value})
export const setIntegrator = (value) => ({type:SET_INTEGRATOR, value})
export const setIntegratorLoader = (value) => ({type:SET_INTEGRATOR_LOADER, value})
export const getBiData = ({data, hash})=> {
    return async dispatch => {
        try {
            dispatch(setBiLoader(true))
            const response = await axios.post(`${CONFIG['biBack']}/api/dashboard/getData`, data, {
                headers: {
                    Authorization: `Bearer ${hash}`
                }
            });
            dispatch(setBiData(response.data.data));
            dispatch(setBiLoader(false))
            dispatch(setBiDataError(""))
        } catch (err) {
            dispatch(setBiDataError("Нет данных"))
            dispatch(setBiLoader(false))
            dispatch(setBiData({}));
        }
    }
}

export const getBIFilters = ({data, hash})=> {
    return async dispatch => {
        try {
            dispatch(setBiLoader(true))
            const response = await axiosOriginal.post(`${CONFIG['biBack']}/api/dashboard/getFilters`, data , {
                headers: {
                    Authorization: `Bearer ${hash}`
                }
            });
            dispatch(setBiRegionFilters(response.data.data?.regions))
            dispatch(setBiWorkerFilters(response.data.data?.employees))
            dispatch(setBiDepartmentFilters(response.data.data?.departments))
            dispatch(setBiLoader(false))
            if(!response.data.message){
                dispatch(setBiDataError(""))
            }
            
        } catch (err) {
            console.log(err);
        }
    }
}

export const getIntegrator = (company_device_id) => {
    return async dispatch => {
        dispatch(setIntegratorLoader(true))
        try{
            const response = await axiosOriginal.post(`${CONFIG['biBack']}/api/asista_integrators`, { company_device_id });
            if(response.data.result){
                dispatch(setIntegrator(response.data.data))
            }
        }
        finally{
            dispatch(setIntegratorLoader(false))
        }
    }
}
