import './Translate.css'
import {useEffect, useState} from "react"
import Container from '../Container/Container'
import FormSelect from '../Form/FormSelect/FormSelect'
import TranslateSelect from './TranslateSelect/TranslateSelect'
import { CONFIG } from '../../../constants/backendUrl'
export function Translate () {
    
	const [currentLang, setCurrentLang] = useState({value: "", label: "Русский"})
    function deleteCookie(name) {
        const date = new Date();
        date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
        document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
    }
    const setTranslate = (value) => {
        document.cookie = `googtrans=${value} domain=${CONFIG.appDomain}; path=/;`
		document.cookie = `googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.asista.kz; path=/;`;
        if(value===""){
            deleteCookie("googtrans");
        }
        window.location.reload();
    };
	useEffect(() => {
	  	const script = document.createElement('script');
	  	script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
	  	document.body.appendChild(script);
	  	window.googleTranslateElementInit = () => {
		  	new window.google.translate.TranslateElement(
				{ 
				  pageLanguage: 'ru', 
				  includedLanguages: 'kk,en',
				  autoDisplay: false,
				}, 
				'google_translate_element'
		  );
		};
		  
	}, []);
	useEffect(() => {
	    const getAllCookies = () => {
	      	const cookieObj = document.cookie.split(';')
	    		.map(cookie => cookie.split('='))
	    		.reduce((accumulator, [key, value]) => ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {});
			
			if(cookieObj.googtrans){
				if(cookieObj.googtrans.substring(4,6)==="en"){ 
					setCurrentLang({value: "en", label: "English"})
				}
				if(cookieObj.googtrans.substring(4,6)==="kk"){ 
					setCurrentLang({value: "kk", label: "Қазақ"})
				}
			}
	    };
	    getAllCookies();
	    setInterval(getAllCookies, 1000); // Check every 1 second
	}, []);	
    return (
        <div className='Translate'>
             <Container>
					<div>
						<div id="google_translate_element"></div>
						<TranslateSelect
							name="translateselect"
							placeholder="Русский"
							options={[
								{value: "/ru/kk", label: "Қазақ"},
								{value: "/ru/en", label: "English"},
								{value: "", label: "Русский"}
							]}
							isSearchable={false}
							value={currentLang}
							onChangeHandler={(e)=>setTranslate(e.value)}
						/>
					</div>
				</Container>
        </div>
    );
}